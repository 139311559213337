import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'newline',
  pure: false,
})
export class NewLinePipe implements PipeTransform {
  constructor() {}

  transform(value: any): any {
    if (value) {
      let r = value.replace(/(?:\r\n|\r|\n)/g, '<br>');

      return r;
    } else return '';
  }
}
