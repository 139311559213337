import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
})
export class RadioButtonComponent implements OnInit {
  @Input() label: string;
  @Input() checked = false;
  @Input() disabled = false;
  @Input() name: any;
  @Output() radioChecked = new EventEmitter<boolean>(false);
  constructor() {}

  ngOnInit(): void {
    if (!this.name) {
      this.name = 'radio';
    }
  }

  onChange(event) {
    this.radioChecked.emit(event.target.checked);
  }
}
