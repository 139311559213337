import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpParams,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class RestfulService {
  authHeader: any;

  baseUrl = environment.serverURI + 'api/';
  //baseUrl='http://localhost:54994/api/';

  constructor(
    private _http: HttpClient,
    private router: Router
  ) {}

  /*  // Andrea Dompè 20190711
  getAuthorizationHeader(){
    var jwt = localStorage.getItem('auth-token')
    this.authHeader = new HttpHeaders();
    if(jwt){
      this.authHeader.set('Content-Type','application/json');
      this.authHeader.set('Authorization', `Bearer `+jwt);
    }
    else this.authHeader.set('Content-Type','application/json');
    console.log(this.authHeader);
    return {headers:this.authHeader};
  }
  */

  getAuthorizationHeaderFileUpload() {
    var jwt = localStorage.getItem('auth-token');
    var auth = 'Bearer ' + jwt;
    this.authHeader = new HttpHeaders({
      Action: 'upload',
    });
    // if(jwt)this.authHeader.append('Authorization', `Bearer `+jwt);
    //    this.authHeader.set('Content-Type', 'multipart/form-data');  // Andrea Dompè 20190711
    return { headers: this.authHeader };
  }

  get(url) {
    return this._http.get(
      this.baseUrl + url /*,this.getAuthorizationHeader()*/
    ); // Andrea Dompè 20190711
    //.map(this.extractData)
    // .pipe(catchError(this.handleError));
  }

  getBlob(url) {
    const obj: any = {
      headers: new HttpHeaders({ Action: 'download' }),
      observe: 'response',
      responseType: 'blob',
    };
    return this._http.get(this.baseUrl + url, obj);
    // .pipe(catchError(this.handleError));
  }

  post(url, itemToPost) {
    return this._http.post(
      this.baseUrl + url,
      itemToPost /*,this.getAuthorizationHeader()*/
    ); // Andrea Dompè 20190711
    //.map(this.extractData)
    // .pipe(catchError(this.handleError));
  }

  put(url, itemToPut) {
    return this._http.put(
      this.baseUrl + url,
      itemToPut /*,this.getAuthorizationHeader()*/
    ); // Andrea Dompè 20190711
    //.map(this.extractData)
    // .pipe(catchError(this.handleError));
  }

  delete(url) {
    return this._http.delete(
      this.baseUrl + url /*,this.getAuthorizationHeader()*/
    ); // Andrea Dompè 20190711
    //.map(this.extractData)
    // .pipe(catchError(this.handleError));
  }

  fileUpload(url, itemToPost) {
    let headers = new HttpHeaders({ 'Content-Type': 'multipart/form-data' });

    return this._http.post(
      this.baseUrl + url,
      itemToPost,
      this.getAuthorizationHeaderFileUpload()
    );
    // .pipe(catchError(this.handleError));
  }

  fileDownload(url, itemToPost) {
    const obj: any = {
      headers: new HttpHeaders({ Action: 'download' }),
      observe: 'response',
      responseType: 'arraybuffer',
    };
    return this._http.post(this.baseUrl + url, itemToPost, obj);
    // .pipe(catchError(this.handleError));
  }

  getJSON(url) {
    return this._http.get(url);
    //.map(this.extractData)
    // .pipe(catchError(this.handleError));
  }
  getBaseUrl() {
    return this.baseUrl;
  }

  private extractData(res: any) {
    //let body = JSON.stringify(res);
    //return body||[];
    return res;
  }

  private handleError(error: any) {
    console.log(error);
    // In a real world app, we might use a remote logging infrastructure
    switch (error.status) {
      case 403:
        console.log(403);
        break;
      case 500:
        // error.clearCookie('happyhr-token');
        // this.router.navigate(['/login']);
        console.log(500);
        break;
      case 503:
        // error.clearCookie('happyhr-token');
        break;
      case 0:
        console.log(0);
        break;
      case -1:
        return JSON.stringify('');
    }
    let errMsg: string;
    if (error instanceof HttpResponse) {
      const body = JSON.stringify(error) || '';
      //const err = body.error || JSON.stringify(body);
      const err = JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return throwError(JSON.stringify(error));
  }
}
