import { Injectable } from '@angular/core';
import { RestfulService } from 'app/shared/services/restful.service';
import { of } from 'rxjs';

@Injectable()
export class AnalyticsService {
  constructor(public restfulWebService: RestfulService) {}

  getGlobalInfo(dateRange: string, employeeId: string) {
    return this.restfulWebService.get(
      `Praise/GlobalInfo?dateRange=${dateRange}&employeeId=${employeeId}`
    );
  }

  // ByBehaviorSkills
  getItemsByBehaviorSkills(body: any) {
    return this.restfulWebService.post(`Praise/ItemsByBehaviorSkills`, body);
  }
  getItemsPersonsByBehaviorSkills(itemId: string, item2Id: string, body: any) {
    return this.restfulWebService.post(
      `Praise/ItemsPersonsByBehaviorSkills?itemId=${itemId}&item2Id=${item2Id}`,
      body
    );
  }

  getDistinctPersonsBYBehaviorSkills(body: any) {
    return this.restfulWebService.post(
      `Praise/DistinctPersonsByBehaviorSkills`,
      body
    );
  }

  getEmployees(asManager, isGlobal) {
    return this.restfulWebService.get(
      `Praise/MyEmployees?isGlobal=${isGlobal}&asManager=${asManager}`
    );
  }
}
