import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CoreService } from 'app/shared/services/core.service';

@Component({
  selector: 'app-dnd-item',
  templateUrl: './dnd-item.component.html',
  styleUrls: ['./dnd-item.component.scss', '../../../common_styles.scss'],
})
export class DndItemComponent implements OnInit {
  @Input() dndItem: any;
  @Input() cycle: any;
  @Input() currentState: string;
  @Input() referral: string;
  @Input() colorState: string;
  @Output() itemChanged = new EventEmitter<any>();

  maxScale: number = 0;

  ImNotAuthor: boolean = false;

  isDeferredExtendedSource: boolean;
  isDeferredExtendedTarget: boolean;
  isDeferredExtendedSourceTarget: boolean;

  isPostponedSource: boolean;
  isPostponedTarget: boolean;
  checkExecution: boolean = false;
  checkevaluation: boolean = false;

  constructor(public coreService: CoreService) {}

  ngOnInit() {
    // console.log(this.colorState);

    // console.log("currentState",this.currentState);
    if (this.currentState == 'EXECUTION_STATE') {
      this.checkExecution = true;
    }
    if (this.currentState === 'EVALUATION_STATE') {
      this.checkevaluation = true;
    }

    let userInfo = this.coreService.getUserInfo();

    this.ImNotAuthor = this.dndItem.Author.Id !== userInfo.linkedEmployeeId;

    userInfo.EvaluationScale.Values.forEach((element: any) => {
      if (element.Value > this.maxScale) this.maxScale = element.Value;
    });

    this.isDeferredExtendedSource =
      this.dndItem.Deferred &&
      this.dndItem.DeferrType === 'Extend' &&
      !this.dndItem.DeferrSourceId;
    this.isDeferredExtendedTarget =
      !this.dndItem.Deferred &&
      this.dndItem.DeferrType === 'Extend' &&
      this.dndItem.DeferrSourceId;
    this.isDeferredExtendedSourceTarget =
      this.dndItem.Deferred &&
      this.dndItem.DeferrType === 'Extend' &&
      this.dndItem.DeferrSourceId;

    this.isPostponedSource =
      this.dndItem.Deferred &&
      this.dndItem.DeferrType === 'Postpone' &&
      !this.dndItem.DeferrSourceId;
    this.isPostponedTarget =
      !this.dndItem.Deferred &&
      this.dndItem.DeferrType === 'Postpone' &&
      this.dndItem.DeferrSourceId;
  }

  getTooltipPhase(state) {
    let tt = this.coreService.getTranslation(
      'INDIVIDUAL_OBJECTIVES.EXECUTION_STATE.COL_' + state + '.Name'
    );
    return tt;
  }

  getImage() {
    if (this.dndItem.Image) {
      return {
        'background-image': `url('${this.dndItem.Image}')`,
      };
    } else {
      return undefined;
    }
  }

  getProgress(item) {
    if (item) {
      // let done = item.CheckList.filter(x=>x.Status===2).length;
      // let all = item.CheckList.length;
      // return done + "/" + all;
      return item.ObjectiveProgressPercentage
        ? item.ObjectiveProgressPercentage
        : 0;
    } else {
      return '';
    }
  }

  follow() {
    this.coreService.follow(this.dndItem.Id).subscribe(
      (arg: any) => {
        this.dndItem.IsFollowedByMe = !this.dndItem.IsFollowedByMe;
        this.coreService.toasterMessage('success', 'MyStory', 'Ok');
        this.itemChanged.emit(this.dndItem);
      },
      (err: any) => {
        this.coreService.toasterMessage('error', 'MyStory', 'Error');
      }
    );
  }

  getTooltip(item, key) {
    let text = this.coreService.getTranslation('TOOLTIPS.' + key);
    switch (key) {
      case 'Category':
        text = item != null ? item.Name : '-';
        // text = text.replace('{{Name}}', item != null ? item.Name : '-');
        break;
      case 'Author':
        text = this.ImNotAuthor
          ? text.replace('{{Name}}', item != null ? item.Name : '-')
          : text.replace(
              '{{Name}}',
              item != null ? this.coreService.getTranslation('Me') : '-'
            ); //this.coreService.getTranslation('Me');
        break;
      case 'Visibility':
        let name = this.coreService.getTranslation(
          'COMPANY_CYCLES.CYCLE_SETTINGS.VISIBILITY_OPTIONS.' +
            item.toUpperCase()
        );
        text = text.replace('{{Name}}', name);
        break;
    }

    return text;
  }

  getAuthorTooltip(item) {
    let text = this.coreService.getTranslation('TOOLTIPS.Category');
  }

  getVisibilityooltip(item) {
    let text = this.coreService.getTranslation('TOOLTIPS.Category');
  }
}
