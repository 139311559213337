import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit, Output } from '@angular/core';
import { CoreService } from 'app/shared/services/core.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-evaluator-dialog',
  templateUrl: './evaluator-dialog.component.html',
  styleUrls: ['./evaluator-dialog.component.css'],
})
export class EvaluatorDialogComponent implements OnInit {
  public value: number = 0;
  public text: string = '';
  public lastValue: number = 0;
  public onClose: Subject<any>;
  public content: any = {};
  user_Info: any;
  evalArr: any[] = [];
  Arr = Array;
  @Output() TheValue = 0;
  public constructor(
    private _bsModalRef: BsModalRef,
    private coreService: CoreService
  ) {}

  public ngOnInit(): void {
    this.onClose = new Subject();

    this.user_Info = this.coreService.getUserInfo();
    this.user_Info.EvaluationScale.Values.forEach((element: any) => {
      let trans = element.Translations.find(
        (x) => x.LanguageCode === this.user_Info.language
      );
      let desc = element.Description;
      let label = element.Name;
      if (trans) {
        desc = trans.Description;
        label = trans.Name;
      }
      this.evalArr.push({
        rate: element.Value,
        activeRate: 0,
        label: label,
        description: desc,
      });
    });
  }

  setStar(star) {
    this.value = star.rate;
  }

  public showModal(content: any): void {
    this.content = content;
  }

  public onConfirm(): void {
    this.onClose.next(true);
    this.TheValue = this.value;
    this._bsModalRef.hide();
  }

  public onCancel(): void {
    this.onClose.next(false);
    this._bsModalRef.hide();
  }

  public hideConfirmationModal(): void {
    this.onClose.next(null);
    this._bsModalRef.hide();
  }
}
