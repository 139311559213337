import {
  Component,
  OnInit,
  ContentChildren,
  QueryList,
  Output,
  EventEmitter,
  Input,
  HostBinding,
} from '@angular/core';
import { Pp360SingleTabsComponent } from './pp360-single-tabs/pp360-single-tabs.component';

@Component({
  selector: 'pp360-tabs',
  templateUrl: './pp360-tabs.component.html',
  styleUrls: ['./pp360-tabs.component.scss'],
  host: { class: 'pp360-tabs' },
})
export class Pp360TabsComponent implements OnInit {
  @ContentChildren(Pp360SingleTabsComponent)
  tabs: QueryList<Pp360SingleTabsComponent>;

  constructor() {}

  ngOnInit(): void {}

  ngAfterContentInit() {
    // Se non trovo nessun tab inizializzato come isActive allora attivo il primo.
    if (!this.tabs.toArray().find((tab) => tab.isActive)) {
      this.tabs.toArray()[0].isActive = true;
    }
    // Mi iscrivo all'evento click di tutti i tabs
    this.tabs.toArray().forEach((tab: Pp360SingleTabsComponent) => {
      tab.click.subscribe((ev) => {
        this.manageActiveTab(tab);
      });
    });
  }

  manageActiveTab(tab: Pp360SingleTabsComponent) {
    this.tabs.toArray().forEach((p) => (p.isActive = false));
    tab.isActive = true;
  }
}
