import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  ElementRef,
} from '@angular/core';
import { PraiseService } from 'app/personal-growth/praise/praise.service';
import { CoreService } from 'app/shared/services/core.service';
import { Router } from '@angular/router';
import { Pp360SpinnerService } from 'app/pp360-components/pp360-spinner/pp360-spinner.service';
import { forkJoin } from 'rxjs';
import {
  ObjSheetPopupComponent,
  REFERRAL_ACTION,
  REFERRAL,
  VIEW_MODE,
} from 'app/company-report/objectives-individual/objective/obj-sheet-popup/obj-sheet-popup.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CycleManagerService } from 'app/components/common/cycle-manager/cycle-manager.service';
import { CompanyReportService } from 'app/company-report/company-report.service';

@Component({
  selector: 'app-table-objectives-by-category',
  templateUrl: './table-objectives-by-category.component.html',
  styleUrls: [
    './table-objectives-by-category.component.scss',
    '../tables.scss',
    '../../../../common_styles.scss',
  ],
})
export class TableObjectivesByCategoryComponent implements OnInit {
  @Input() dateRange: string;
  @Input() viewMode: string;
  @Input() Mobile: string;
  @Input() currentStatus: any;

  //-----okr-popup----//
  cycle: any = [];
  @Output() reload = new EventEmitter();
  @Input() currentSearch: string;
  filteredEmployees: any;
  currentUserInfo: any;
  iamAdmin: boolean;
  manager: any;
  private searchInput: ElementRef;
  currentCycle: any;
  currentExpandedRow: number;
  //---------//

  @Output() OnLoading = new EventEmitter();
  @Output() openComp = new EventEmitter();
  @Output() OnLoaded = new EventEmitter();

  filteredItems: any;
  items: any;
  currentFilter: any;
  userinfo: any;
  @Input() praiseArr0: any;
  @Input() radioType: any;
  openPop: boolean;

  constructor(
    private praiseService: PraiseService,
    private ref: ChangeDetectorRef,
    public coreService: CoreService,
    public route: Router,
    private modalService: BsModalService,
    private srv: CycleManagerService,
    private companyReportService: CompanyReportService,
    private pp360SpinnerService: Pp360SpinnerService
  ) {}

  ngOnInit() {
    this.userinfo = this.coreService.getUserInfo();

    // console.log(this.praiseArr0);
    this.reload1(this.viewMode, this.dateRange);
  }
  isExpands(itemId: any) {
    for (let i of this.praiseArr0) {
      if (itemId === i.id) {
        i['isExpanded'] = !i['isExpanded'];
      } else {
        i['isExpanded'] = false;
      }
    }
  }

  reload1(data, dateRange) {
    this.updateCurrentFilter();
    this.OnLoading.emit();
    this.currentFilter.viewMode = data;
    this.currentFilter.dateRange = dateRange;
    this.pp360SpinnerService.showSpinner(true);
    this.praiseService
      .getItemsByBehaviorSkills(this.currentFilter)
      .subscribe((items: any) => {
        // console.log("getItemsBySkills", items);
        if (items.length) {
          this.getobjDeatils(items);
        } else {
          this.OnLoaded.emit();
          this.pp360SpinnerService.showSpinner(false);
        }
      });
  }

  getobjDeatils(items) {
    const requests = [];
    items.forEach((item) => {
      requests.push(
        this.praiseService.getItemsPersonsByBehaviorSkills(
          item.Id,
          null,
          this.currentFilter
        )
      );
    });
    forkJoin(requests).subscribe((response) => {
      let data = [];
      response.forEach((r: any[]) => {
        data.push(...r);
      });
      this.items = data;
      this.OnLoaded.emit();
      this.filteredItems = JSON.parse(JSON.stringify(this.items));
      for (let item of this.filteredItems) {
        if (!item.Persons) {
          this.updateCurrentFilter();
          this.currentFilter.type = 'ObjectivesEmployee';
          this.OnLoading.emit();
          this.praiseService
            .getItemsPersonsByBehaviorSkills(item.Id, null, this.currentFilter)
            .subscribe((persons: any) => {
              this.OnLoaded.emit();
              item.Persons = persons;
              this.ref.detectChanges();
              for (let i of item.Persons) {
                i['sortingValue'] =
                  (i?.Pats?.Tot || 0) +
                  (i?.Feedbacks?.Avg || 0) +
                  (i?.Tokens?.Tot || 0);
              }
              item['EmpLength'] = item.Persons.length;
              // console.log(item);

              item.Persons.sort((a, b) => {
                return a.sortingValue > b.sortingValue
                  ? -1
                  : a.sortingValue < b.sortingValue
                    ? 1
                    : 0;
              });
            });
        }
        item['sortingValue'] =
          (item?.Pats?.Tot || 0) +
          item?.EmpLength +
          (item?.Feedbacks?.Avg || 0) +
          (item?.Tokens?.Tot || 0);
      }
      this.filteredItems.sort((a, b) => {
        return a.sortingValue > b.sortingValue
          ? -1
          : a.sortingValue < b.sortingValue
            ? 1
            : 0;
      });
      // console.log(this.filteredItems);
      this.pp360SpinnerService.showSpinner(false);
    });
  }

  getTooltip(t) {
    let key = 'PRAISES.' + t;
    if (t !== 'NumObjectives') key += this.viewMode;
    return this.coreService.getTranslation(key);
  }

  getNumber(n) {
    return n ? n : '';
  }

  updateCurrentFilter() {
    this.currentFilter = {
      dateRange: this.dateRange,
      viewMode: this.viewMode, // Given/Received
      type: 'Objectives',
    };
  }

  findMyGuru(itemId: string) {}

  goToProfile(id) {
    this.route.navigate(['/User/story'], {
      queryParams: { idVisitor: id },
      replaceUrl: true,
    });
  }

  expandRow(item: any) {
    if (item.ShowDetails) {
      item.ShowDetails = false;
      this.ref.detectChanges();
    } else {
      this.filteredItems.forEach((element: any) => {
        element.ShowDetails = false;
      });
      if (!item.ObjectivesList) {
        this.updateCurrentFilter();
        this.OnLoading.emit();
        this.praiseService
          .getItemsPersonsByBehaviorSkills(item.Id, null, this.currentFilter)
          .subscribe((objectives: any) => {
            this.OnLoaded.emit();
            item.ObjectivesList = objectives;
            item.ShowDetails = true;
            this.ref.detectChanges();
          });
      } else {
        item.ShowDetails = true;
        this.ref.detectChanges();
      }
    }
  }

  expandSubRow(item: any) {
    if (item.ShowSubDetails) {
      item.ShowSubDetails = false;
      this.ref.detectChanges();
      this.openPop = false;
    } else {
      this.filteredItems.forEach((element: any) => {
        element.ShowSubDetails = false;
      });
      this.openPop = true;
      if (!item.Persons) {
        this.updateCurrentFilter();
        this.currentFilter.type = 'ObjectivesEmployee';
        this.OnLoading.emit();
        this.praiseService
          .getItemsPersonsByBehaviorSkills(item.Id, null, this.currentFilter)
          .subscribe((persons: any) => {
            this.OnLoaded.emit();
            item.Persons = persons;
            item.ShowSubDetails = true;
            this.ref.detectChanges();
            for (let i of item.Persons) {
              i['sortingValue'] =
                (i?.Pats?.Tot || 0) +
                (i?.Feedbacks?.Avg || 0) +
                (i?.Tokens?.Tot || 0);
            }

            item.Persons.sort((a, b) => {
              return a.sortingValue > b.sortingValue
                ? -1
                : a.sortingValue < b.sortingValue
                  ? 1
                  : 0;
            });
          });
      } else {
        item.ShowSubDetails = true;
        this.ref.detectChanges();
      }
    }
  }
  openComponent(data) {
    this.openComp.next([data, 'skillSkill']);
  }
  sortItem(event: any, type: string, subtype: string = '') {
    let currentSort;
    for (let item of this.filteredItems) {
      item.ShowDetails = false;
    }
    if ($(event.target).hasClass('sort-asc')) {
      $(event.target).removeClass('sort-asc'),
        $(event.target).addClass('sort-desc');
      currentSort = 'desc';
    } else {
      $(event.target).removeClass('sort-desc'),
        $(event.target).addClass('sort-asc');
      currentSort = 'asc';
    }

    this.filteredItems.sort((a, b) => {
      switch (type) {
        case 'Name':
          if (!a || !a.Name || !b || !a.Name) return -1;
          if (currentSort === 'desc') {
            return a.Name.toLowerCase().trim() > b.Name.toLowerCase().trim()
              ? -1
              : a.Name.toLowerCase().trim() < b.Name.toLowerCase().trim()
                ? 1
                : 0;
          } else {
            return a.Name.toLowerCase().trim() > b.Name.toLowerCase().trim()
              ? 1
              : a.Name.toLowerCase().trim() < b.Name.toLowerCase().trim()
                ? -1
                : 0;
          }
          break;

        case 'EmpLength':
          if (!a || !a.EmpLength || !b || !a.EmpLength) return -1;
          if (currentSort === 'desc') {
            return a.EmpLength > b.EmpLength
              ? -1
              : a.EmpLength < b.EmpLength
                ? 1
                : 0;
          } else {
            return a.EmpLength > b.EmpLength
              ? 1
              : a.EmpLength < b.EmpLength
                ? -1
                : 0;
          }
          break;

        default:
          const valueA =
            !a || !a[type] || !a[type][subtype] ? 0 : a[type][subtype];
          const valueB =
            !b || !b[type] || !b[type][subtype] ? 0 : b[type][subtype];

          if (currentSort === 'desc') {
            return valueB - valueA;
          } else {
            return valueA - valueB;
          }

          break;
      }
    });
  }
  openObjectiveSheet(objective: any, referralAction: REFERRAL_ACTION = 1) {
    // console.log(this.manager);
    if (objective.Visibility !== 'Private') {
      this.srv
        .getObjectiveById(objective.Id, this.manager)
        .subscribe((objectiveResult: any) => {
          // console.log('objectiveResult', objectiveResult);
          this.showObjectiveSheet(objectiveResult, true, referralAction);
        });
    }
  }
  showObjectiveSheet(
    obj: any,
    readOnly: boolean = false,
    referralAction: REFERRAL_ACTION = 1
  ) {
    const content: any = {
      objective: obj,
      state: 'DEFINITION_STATE',
      cycle: this.currentCycle,

      ReadOnly: readOnly,
      referral: REFERRAL.CYCLES,
      referralAction: referralAction,
    };
    if (this.iamAdmin) {
      if (this.currentUserInfo.isManager && this.manager === 'AsManager') {
        content.ViewMode = VIEW_MODE.MANAGER;
      } else {
        content.ViewMode = VIEW_MODE.ADMIN;
      }
    } else {
      content.ViewMode = VIEW_MODE.MANAGER;
    }

    // console.log('showObjectiveSheet', content);

    switch (this.currentStatus) {
      case 'Progress':
        content.state = 'EXECUTION_STATE';
        break;
      case 'Evaluation':
        content.state = 'EVALUATION_STATE';
        break;
    }

    const modal = this.modalService.show(ObjSheetPopupComponent, {
      class: 'objective-sheet-popup okr-obj-popup',
      ignoreBackdropClick: false,
      initialState: {
        isHeader: true,
      },
    });

    (<ObjSheetPopupComponent>modal.content).showModal(content);
    (<ObjSheetPopupComponent>modal.content).onClose.subscribe(
      (result: boolean) => {
        if (result) {
          this.emitReloadEvent();
        }
      }
    );

    // ----- Handles in sheets objective remotions
    (<ObjSheetPopupComponent>modal.content).assignObjective.subscribe(
      (result: any) => {
        this.companyReportService
          .assignObjective(result.id, result.objective)
          .subscribe((r) => {
            this.emitReloadEvent();
          });
      }
    );
  }
  emitReloadEvent() {
    this.reload.emit({
      searchInput: this.searchInput.nativeElement.value,
      advFilters: this.currentFilter,
      detailsRow: this.currentExpandedRow,
    });
  }
}
