import {
  Component,
  OnInit,
  Input,
  Output,
  ElementRef,
  ViewChild,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CoreService } from 'app/shared/services/core.service';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import {
  ObjSheetPopupComponent,
  REFERRAL_ACTION,
  REFERRAL,
  VIEW_MODE,
} from 'app/company-report/objectives-individual/objective/obj-sheet-popup/obj-sheet-popup.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SimpleDialogComponent } from 'app/shared/simple-dialog/simple-dialog.component';

import { CycleManagerService } from 'app/components/common/cycle-manager/cycle-manager.service';
import { CyclesUtilitiesService } from 'app/shared/services/cycles-utilities.service';
import { CompanyReportService } from 'app/company-report/company-report.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: [
    './manage.component.scss',
    '../../../../common_styles.scss',
    '../../../../magic-checkbox.scss',
  ],
})
export class ManageComponent implements OnInit, OnChanges {
  @Input() cycle: any;
  @Input() selectedLang: any;
  @Input() manager: string;
  @Input() currentStatus: any;
  @Input() currentFilter: string;
  @Input() currentSearch: string;
  @Input() detailsRow: string;
  @Output() reload = new EventEmitter();
  @Input() cSearch: string;

  maxScale: number = 5;
  eval_arr: any = {};

  private searchInput: ElementRef;
  @ViewChild('searchInput', { static: false }) set _searchInput(
    content: ElementRef
  ) {
    if (content) {
      // initially setter gets called with undefined
      this.searchInput = content;
    }
  }

  filterActive: boolean = false;

  slideConfig: any = {
    slidesToShow: 6,
    slidesToScroll: 6,
    infinite: false,
  };

  currentUserInfo: any;
  filteredEmployees: any;
  originalFilteredEmployees: any;

  iamAdmin: boolean;
  iamManager: boolean;
  isOKRneed: boolean = false;
  isAwait: boolean = false;
  isUnweighted: boolean = false;
  isopenOKRS: boolean = false;
  okrW_Link: boolean = false;
  entroAgreeDate: boolean = false;

  nudgesSent: any;
  subNudgesSent: any;

  constructor(
    private srv: CycleManagerService,
    public coreService: CoreService,
    private modalService: BsModalService,
    private cyclesUtilitiesService: CyclesUtilitiesService,
    private activatedRoute: ActivatedRoute,
    private companyReportService: CompanyReportService
  ) {}

  ngOnInit() {
    this.initialize();
    this.routePramas();
    if (this.cSearch) {
      this.filteredEmployees = this.cycle.Details.EmploeesList.filter(
        (x) => x.Employee.Name === this.cSearch
      );
      this.filteredEmployees.forEach((element) => {
        element.ShowDetails = true;
      });
    }
  }

  initialize() {
    this.currentUserInfo = this.coreService.getUserInfo();

    let today = this.coreService.ClearDateGMT(new Date());

    let AgreeDate = this.cycle.ManagerAggreedInterval
      ? this.coreService.ClearDateGMT(
          new Date(this.cycle.ManagerAggreedInterval.End),
          23,
          59
        )
      : undefined;

    this.entroAgreeDate = AgreeDate ? today <= AgreeDate : false;

    this.currentUserInfo.EvaluationScale.Values.forEach((element: any) => {
      let trans = element.Translations.find(
        (x) => x.LanguageCode === this.currentUserInfo.language
      );
      let desc = element.Description;
      let label = element.Name;
      if (trans) {
        desc = trans.Description;
        label = trans.Name;
      }
      this.eval_arr[element.Value] = { label: label, description: desc };
      if (element.Value > this.maxScale) this.maxScale = element.Value;
    });

    this.iamAdmin = this.currentUserInfo.roles
      .toLowerCase()
      .includes('companyadmin');
    this.iamManager =
      this.currentUserInfo.isManager || this.currentUserInfo.isEvaluator;

    this.cycle.Details.EmploeesList.forEach((row) => {
      row.showWeightTot = this.showWeightTot(row);
    });

    this.filteredEmployees = JSON.parse(
      JSON.stringify(this.cycle.Details.EmploeesList)
    );
    this.originalFilteredEmployees = JSON.parse(
      JSON.stringify(this.cycle.Details.EmploeesList)
    );

    this.nudgesSent = [];
    this.subNudgesSent = [];
    this.filteredEmployees.forEach((element: any) => {
      this.nudgesSent.push({
        left: false,
        right: false,
      });

      if (element.Objectives && element.Objectives.List) {
        element.Objectives.List.forEach((el) => {
          this.subNudgesSent.push({
            left: false,
            right: false,
            id: el.$id,
          });
        });
      }

      element.Objectives.List.sort((a: any, b: any) => {
        return a.Name.toLowerCase() > b.Name.toLowerCase()
          ? 1
          : a.Name.toLowerCase() < b.Name.toLowerCase()
            ? -1
            : 0;
      });

      element.ShowDetails = this.detailsRow && this.detailsRow == element.$id;
    });
    // console.log('cycle=>', this.cycle);
    this.filteredEmployees.sort((a, b) =>
      a.Employee.Name.localeCompare(b.Employee.Name)
    );
  }
  routePramas() {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params && params.type === 'OWK') {
        this.isOKRneed = true;
        this.onFilterChange(this.currentFilter);
      } else if (params && params.type === 'Await') {
        this.isAwait = true;
        this.onFilterChange(this.currentFilter);
      } else if (params && params.type === 'Unweighted') {
        this.isUnweighted = true;
        this.onFilterChange(this.currentFilter);
      } else if (params && params.type === 'openOKRS') {
        this.isopenOKRS = true;
        this.onFilterChange(this.currentFilter);
      } else if (params && params.type === 'withoutLink') {
        if (this.cycle.CycleInfo.Status === 'Definition') {
          this.okrW_Link = true;
          this.onFilterChange(this.currentFilter);
        }
      }
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.cycle) {
      this.cycle = changes.cycle.currentValue;
      this.initialize();
    }
  }

  isDeferredExtendedSource(objective: any) {
    return (
      objective.IsDeferred &&
      objective.DeferrType === 'Extend' &&
      !objective.DeferrSourceId
    );
  }
  isDeferredExtendedTarget(objective: any) {
    return (
      !objective.IsDeferred &&
      objective.DeferrType === 'Extend' &&
      objective.DeferrSourceId
    );
  }
  isDeferredExtendedSourceTarget(objective: any) {
    return (
      objective.IsDeferred &&
      objective.DeferrType === 'Extend' &&
      objective.DeferrSourceId
    );
  }
  isPostponedSource(objective: any) {
    return (
      objective.IsDeferred &&
      objective.DeferrType === 'Postpone' &&
      !objective.DeferrSourceId
    );
  }
  isPostponedTarget(objective: any) {
    return (
      !objective.IsDeferred &&
      objective.DeferrType === 'Postpone' &&
      objective.DeferrSourceId
    );
  }

  ngAfterViewInit() {
    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => this.filterTable())
      )
      .subscribe();

    if (this.currentSearch) {
      this.searchInput.nativeElement.value = this.currentSearch;
      setTimeout(() => {
        this.filterTable();
      }, 250);
    }

    if (this.currentFilter) {
      setTimeout(() => {
        this.onFilterChange(this.currentFilter);
      }, 500);
    }
  }

  isMySelf(userId) {
    return userId === this.currentUserInfo.linkedEmployeeId;
  }

  nudgeAlreadySent(rowIndex: any, direction: string) {
    return this.nudgesSent.length > rowIndex
      ? this.nudgesSent[rowIndex][direction]
      : false;
  }

  subNudgeAlreadySent(id: string, direction: string) {
    const nudge = this.subNudgesSent.find((el) => el.id === id);
    if (nudge) {
      return nudge[direction];
    } else {
      return false;
    }
  }

  getVisibilityText(v) {
    const key =
      'COMPANY_CYCLES.CYCLE_SETTINGS.VISIBILITY_OPTIONS.' + v.toUpperCase();
    return this.coreService.getTranslation(key);
  }

  filterTable() {
    const searchValue = this.searchInput.nativeElement.value;
    console.log('filterTable', searchValue);
    if (searchValue) {
      this.filteredEmployees = this.cycle.Details.EmploeesList.filter(
        (item: any) => {
          return (
            (item.Employee &&
              item.Employee.Name &&
              item.Employee.Name.toLowerCase().includes(
                searchValue.toLowerCase()
              )) ||
            (item.Manager &&
              item.Manager.Name &&
              item.Manager.Name.toLowerCase().includes(
                searchValue.toLowerCase()
              ))
          );
        }
      );
      this.originalFilteredEmployees = JSON.parse(
        JSON.stringify(this.filteredEmployees)
      );
    } else {
      this.filteredEmployees = JSON.parse(
        JSON.stringify(this.cycle.Details.EmploeesList)
      );
      this.originalFilteredEmployees = JSON.parse(
        JSON.stringify(this.cycle.Details.EmploeesList)
      );
    }
  }

  sort(event: any, type: string) {
    let currentSort;

    if ($(event.target).hasClass('sort-asc')) {
      $(event.target).removeClass('sort-asc'),
        $(event.target).addClass('sort-desc');
      currentSort = 'desc';
    } else {
      $(event.target).removeClass('sort-desc'),
        $(event.target).addClass('sort-asc');
      currentSort = 'asc';
    }

    this.filteredEmployees.sort((a, b) => {
      if (type === 'TeamMember') {
        if (
          !a ||
          !a.Employee ||
          !a.Employee.Name ||
          !b ||
          !b.Employee ||
          !b.Employee.Name
        )
          return -1;

        if (currentSort === 'desc') {
          return a.Employee.Name.toLowerCase() > b.Employee.Name.toLowerCase()
            ? -1
            : a.Employee.Name.toLowerCase() < b.Employee.Name.toLowerCase()
              ? 1
              : 0;
        } else {
          console.log();
          return a.Employee.Name.toLowerCase() > b.Employee.Name.toLowerCase()
            ? 1
            : a.Employee.Name.toLowerCase() < b.Employee.Name.toLowerCase()
              ? -1
              : 0;
        }
      } else if (type === 'Manager') {
        if (
          !a ||
          !a.Manager ||
          !a.Manager.Name ||
          !b ||
          !b.Manager ||
          !b.Manager.Name
        )
          return -1;

        if (currentSort === 'desc') {
          return a.Manager.Name.toLowerCase() > b.Manager.Name.toLowerCase()
            ? -1
            : a.Manager.Name.toLowerCase() < b.Manager.Name.toLowerCase()
              ? 1
              : 0;
        } else {
          return a.Manager.Name.toLowerCase() > b.Manager.Name.toLowerCase()
            ? 1
            : a.Manager.Name.toLowerCase() < b.Manager.Name.toLowerCase()
              ? -1
              : 0;
        }
      } else if (type === 'Objectives') {
        if (
          !a ||
          !a.Objectives ||
          !a.Objectives.Number ||
          !b ||
          !b.Objectives ||
          !b.Objectives.Number
        )
          return -1;

        if (currentSort === 'desc') {
          return a.Objectives.Number.Value > b.Objectives.Number.Value
            ? -1
            : a.Objectives.Number.Value < b.Objectives.Number.Value
              ? 1
              : 0;
        } else {
          return a.Objectives.Number.Value > b.Objectives.Number.Value
            ? 1
            : a.Objectives.Number.Value < b.Objectives.Number.Value
              ? -1
              : 0;
        }
      } else {
        return 0;
      }
    });
  }

  onFilterChange(event: any) {
    if (this.isAwait) {
      event = {
        BossEvaluation: ['ALL'],
        Categories: null,
        ChallengePOST: ['ALL'],
        ChallengePRE: ['ALL'],
        InclinationPOST: ['ALL'],
        InclinationPRE: ['ALL'],
        SelfEvaluation: ['ALL'],
        Status: 'AwaitingApproval',
        Visibility: 'None',
        Weights: ['ALL'],
      };
    }
    if (this.isOKRneed) {
      event = {
        BossEvaluation: ['ALL'],
        Categories: null,
        ChallengePOST: ['ALL'],
        ChallengePRE: ['ALL'],
        InclinationPOST: ['ALL'],
        InclinationPRE: ['ALL'],
        SelfEvaluation: ['ALL'],
        Status: 'Set',
        Visibility: 'None',
        Weights: ['ALL'],
      };
    }
    if (this.isopenOKRS) {
      event = {
        BossEvaluation: ['ALL'],
        Categories: null,
        ChallengePOST: ['ALL'],
        ChallengePRE: ['ALL'],
        InclinationPOST: ['ALL'],
        InclinationPRE: ['ALL'],
        SelfEvaluation: ['ALL'],
        Status: 'Open',
        Visibility: 'None',
        Weights: ['ALL'],
      };
    }
    if (this.isUnweighted) {
      event = {
        BossEvaluation: ['ALL'],
        Categories: null,
        ChallengePOST: ['ALL'],
        ChallengePRE: ['ALL'],
        InclinationPOST: ['ALL'],
        InclinationPRE: ['ALL'],
        SelfEvaluation: ['ALL'],
        Status: 'Set',
        Visibility: 'None',
        Weights: ['MISSING'],
      };
    }
    if (this.okrW_Link) {
      event = {
        BossEvaluation: ['ALL'],
        Categories: null,
        ChallengePOST: ['ALL'],
        ChallengePRE: ['ALL'],
        InclinationPOST: ['ALL'],
        InclinationPRE: ['ALL'],
        SelfEvaluation: ['ALL'],
        Status: 'Set',
        Visibility: 'None',
        Weights: ['ALL'],
      };
    }
    console.log('onFilterChange', event);
    this.srv
      .getSingleCycle(this.cycle.Id, true, this.manager, event)
      .subscribe((result: any) => {
        console.log('55', result);
        this.currentFilter = event;
        this.cycle.Details = result.Details;

        let empMap: any = {};
        this.cycle.Details.EmploeesList.forEach((e: any) => {
          empMap[e.Employee.Id] = e;
        });
        this.filteredEmployees = JSON.parse(
          JSON.stringify(this.originalFilteredEmployees)
        );
        this.filteredEmployees.forEach((e: any) => {
          if (empMap[e.Employee.Id]) {
            //Employee exists after filter
            e.Objectives.Number.Value =
              empMap[e.Employee.Id].Objectives.Number.Value;
            e.Objectives.List.forEach((obj) => {
              if (
                !empMap[e.Employee.Id].Objectives.List.find(
                  (x) => x.Id === obj.Id
                )
              ) {
                obj.Disabled = true;
              } else {
                obj.Disabled = false;
              }
            });
          } else {
            //Employee does not exist after filter
            e.Objectiles.Number.Value = 0;
            e.Objectives.List.forEach((obj) => {
              obj.Disabled = true;
            });
          }
        });
        this.filterActive =
          this.cycle.Details.ActiveFilter.Categories ||
          this.cycle.Details.ActiveFilter.Status !== 'None' ||
          this.cycle.Details.ActiveFilter.Visibility !== 'None' ||
          this.cycle.Details.ActiveFilter.BossEvaluation.length > 1 ||
          this.cycle.Details.ActiveFilter.BossEvaluation[0] !== 'ALL' ||
          this.cycle.Details.ActiveFilter.ChallengePOST.length > 1 ||
          this.cycle.Details.ActiveFilter.ChallengePOST[0] !== 'ALL' ||
          this.cycle.Details.ActiveFilter.ChallengePRE.length > 1 ||
          this.cycle.Details.ActiveFilter.ChallengePRE[0] !== 'ALL' ||
          this.cycle.Details.ActiveFilter.InclinationPOST.length > 1 ||
          this.cycle.Details.ActiveFilter.InclinationPOST[0] !== 'ALL' ||
          this.cycle.Details.ActiveFilter.InclinationPRE.length > 1 ||
          this.cycle.Details.ActiveFilter.InclinationPRE[0] !== 'ALL' ||
          this.cycle.Details.ActiveFilter.SelfEvaluation.length > 1 ||
          this.cycle.Details.ActiveFilter.SelfEvaluation[0] !== 'ALL' ||
          this.cycle.Details.ActiveFilter.Weights.length > 1 ||
          this.cycle.Details.ActiveFilter.Weights[0] !== 'ALL';

        this.filteredEmployees.sort((a: any, b: any) => {
          //return a.Objectives.Number.Value > b.Objectives.Number.Value ? -1 : a.Objectives.Number.Value < b.Objectives.Number.Value ? 1 : 0;
          const aVal =
            a.Objectives.Number.Value * 1000 + a.Objectives.Number.Total;
          const bVal =
            b.Objectives.Number.Value * 1000 + b.Objectives.Number.Total;
          return aVal > bVal ? -1 : aVal < bVal ? 1 : 0;
        });

        if (
          this.cycle.Details.ActiveFilter.Visibility !== 'None' ||
          this.cycle.Details.ActiveFilter.Status !== 'None' ||
          this.cycle.Details.ActiveFilter.Categories
        ) {
          // Riorino per value

          /*
                    this.filteredEmployees.sort((a: any, b: any) => {
                      return (a.Objectives.Number.Value > b.Objectives.Number.Value) && (a.Objectives.Number.Total - a.Objectives.Number.Value) > (b.Objectives.Number.Total - b.Objectives.Number.Value) ? -1 : (a.Objectives.Number.Value < b.Objectives.Number.Value) && (a.Objectives.Number.Total - a.Objectives.Number.Value) < (b.Objectives.Number.Total - b.Objectives.Number.Value) ? 1 : 0;
                    });
                    */

          // Per primi gli obbiettivi con lo stato attivo nel filtro
          this.filteredEmployees.forEach((element: any) => {
            element.Objectives.List.sort((a: any, b: any) => {
              let match = false;
              // Status && Category && Visibility
              if (
                this.cycle.Details.ActiveFilter.Status !== 'None' &&
                this.cycle.Details.ActiveFilter.Categories &&
                this.cycle.Details.ActiveFilter.Categories.length > 0 &&
                this.cycle.Details.ActiveFilter.Visibility !== 'None'
              ) {
                match =
                  this.cycle.Details.ActiveFilter.Status !== 'None' &&
                  a.Status === this.cycle.Details.ActiveFilter.Status &&
                  this.cycle.Details.ActiveFilter.Categories &&
                  this.cycle.Details.ActiveFilter.Categories.length > 0 &&
                  a.Category &&
                  a.Category.Id ===
                    this.cycle.Details.ActiveFilter.Categories[0] &&
                  this.cycle.Details.ActiveFilter.Visibility !== 'None' &&
                  a.Visibility === this.cycle.Details.ActiveFilter.Visibility;
                console.log(
                  'match',
                  a,
                  match,
                  this.cycle.Details.ActiveFilter.Status,
                  this.cycle.Details.ActiveFilter.Categories[0],
                  this.cycle.Details.ActiveFilter.Visibility
                );
                console.log(a.Status, a.Category?.Id, a.Visibility);

                // Status && Category
              } else if (
                this.cycle.Details.ActiveFilter.Status !== 'None' &&
                this.cycle.Details.ActiveFilter.Categories
              ) {
                match =
                  this.cycle.Details.ActiveFilter.Status !== 'None' &&
                  a.Status === this.cycle.Details.ActiveFilter.Status &&
                  this.cycle.Details.ActiveFilter.Categories &&
                  this.cycle.Details.ActiveFilter.Categories.length > 0 &&
                  a.Category &&
                  a.Category.Id ===
                    this.cycle.Details.ActiveFilter.Categories[0];

                // Status && Visibility
              } else if (
                this.cycle.Details.ActiveFilter.Status !== 'None' &&
                this.cycle.Details.ActiveFilter.Visibility !== 'None'
              ) {
                match =
                  this.cycle.Details.ActiveFilter.Status !== 'None' &&
                  a.Status === this.cycle.Details.ActiveFilter.Status &&
                  this.cycle.Details.ActiveFilter.Visibility !== 'None' &&
                  a.Visibility === this.cycle.Details.ActiveFilter.Visibility;

                // Category && Visibility
              } else if (
                this.cycle.Details.ActiveFilter.Categories &&
                this.cycle.Details.ActiveFilter.Categories.length > 0 &&
                this.cycle.Details.ActiveFilter.Visibility !== 'None'
              ) {
                match =
                  this.cycle.Details.ActiveFilter.Categories &&
                  this.cycle.Details.ActiveFilter.Categories.length > 0 &&
                  a.Category &&
                  a.Category.Id ===
                    this.cycle.Details.ActiveFilter.Categories[0] &&
                  this.cycle.Details.ActiveFilter.Visibility !== 'None' &&
                  a.Visibility === this.cycle.Details.ActiveFilter.Visibility;

                // Status
              } else if (this.cycle.Details.ActiveFilter.Status !== 'None') {
                match =
                  this.cycle.Details.ActiveFilter.Status !== 'None' &&
                  a.Status === this.cycle.Details.ActiveFilter.Status;

                // Category
              } else if (
                this.cycle.Details.ActiveFilter.Categories &&
                this.cycle.Details.ActiveFilter.Categories.length > 0
              ) {
                match =
                  this.cycle.Details.ActiveFilter.Categories &&
                  this.cycle.Details.ActiveFilter.Categories.length > 0 &&
                  a.Category &&
                  a.Category.Id ===
                    this.cycle.Details.ActiveFilter.Categories[0];

                // Visibility
              } else if (
                this.cycle.Details.ActiveFilter.Visibility !== 'None'
              ) {
                match =
                  this.cycle.Details.ActiveFilter.Visibility !== 'None' &&
                  a.Visibility === this.cycle.Details.ActiveFilter.Visibility;
              }

              return match ? -1 : 0;
            });
          });
        } else {
          // Se non c'è il filtro per stato riordino per nome crescente
          this.filteredEmployees.forEach((element: any) => {
            element.Objectives.List.sort((a: any, b: any) => {
              return a.Name.toLowerCase() > b.Name.toLowerCase()
                ? 1
                : a.Name.toLowerCase() < b.Name.toLowerCase()
                  ? -1
                  : 0;
            });
          });
        }
      });
    this.isAwait = false;
    this.isOKRneed = false;
    this.isopenOKRS = false;
    this.isUnweighted = false;
    this.okrW_Link = false;
  }

  canAssign(row: any) {
    // Se sono MANAGER e sono entro la fase di definizione OPPURE sono ADMIN
    return (
      (this.iamManager &&
        this.cyclesUtilitiesService.inDefinitionPhase(this.cycle, 'MANAGER') &&
        !this.cyclesUtilitiesService.afterEvaluationPhase(
          this.cycle,
          'MANAGER'
        )) ||
      (this.iamAdmin &&
        !this.cyclesUtilitiesService.afterEvaluationPhase(this.cycle, 'ADMIN'))
    );
  }

  isApprovedDefinition(cycle, objective) {
    if (
      (cycle &&
        cycle.CycleInfo &&
        cycle.CycleInfo.ActivePhases.includes('Definition')) ||
      (objective && objective.ApprovalStatus === 'Approved')
    ) {
      return true;
    }
    return false;
  }

  getBorderBottomColorByStatus(obj: any, status: string) {
    if (obj?.Visibility === 'Private') {
      return { 'border-bottom-color': 'rgb(201, 201, 201)' };
    }
    switch (status) {
      case 'Open':
      case 'ToDo':
      case 'NotEvaluated':
        return { 'border-bottom-color': '#C9C9C9' };
        break;
      case 'AwaitingApproval':
      case 'NoBoss':
        return { 'border-bottom-color': '#FFDB45' };
        break;
      case 'Set':
      case 'Completed':
      case 'Evaluated':
        return { 'border-bottom-color': '#74BD32' };
        break;
      case 'InProgress':
      case 'WorkingOn':
      case 'NoSelf':
        return { 'border-bottom-color': '#004D7F' };
        break;
      case 'SuspendedDeferred':
      case 'NotApproved':
        return { 'border-bottom-color': '#7D7E7E' };
        break;
      default:
        return { 'border-bottom-color': '#FFFFFF' };
        break;
    }
  }

  getCategoryImg(cat: any) {
    if (
      cat.Visibility === 'Private' &&
      (!cat.Author || cat.Author.Id !== this.currentUserInfo.linkedEmployeeId)
    ) {
      return 'assets/images/category.gif';
    } else {
      return this.coreService.getImageURL(
        cat.Icon,
        'assets/images/category.gif'
      );
    }
  }

  currentExpandedRow: number;
  expandRow(row: any) {
    console.log('expandRow', row);
    const oldState = row.ShowDetails;
    this.filteredEmployees.forEach((element) => {
      element.ShowDetails = false;
    });
    row.ShowDetails = oldState ? false : true;
    this.currentExpandedRow = row.ShowDetails ? row.$id : undefined;
    // console.log(this.currentExpandedRow);
  }

  newObjectiveSheet(row: any) {
    const objective: any = {
      Author: {
        Id: this.currentUserInfo.linkedEmployeeId,
        Picture: this.currentUserInfo.imageUrl,
        Name: this.currentUserInfo.fullName,
      },
      Name: '',
      Description: '',
      Image: undefined,
      Category: undefined,
      DueDate: this.cycle.CycleInfo.Period.End,
      Visibility: this.cycle.WhoCanEdit,
      LimitedViewer: [],
      CheckList: [],
      Comments: [],
      Weight: 0,
      Employees: [],
      ApprovalStatus: 'None',
      SelfEvaluationValue: 0,
      ChallengeValue: -1,
      InclinationValue: -1,
      PostInclinationValue: -1,
      PostChallengeValue: -1,
      Evaluation: 0,
      DefinitionState: 1, //this.cyclesUtilitiesService.afterDefinitionPhase(this.cycle, this.iamAdmin ? 'ADMIN' : 'MANAGER') ? 2 : 0
    };
    objective.Employees.push({
      Id: row.Employee.Id,
      Name: row.Employee.Name,
      Picture: row.Employee.Picture,
    });

    this.showObjectiveSheet(objective, false, REFERRAL_ACTION.ASSIGN);
  }

  openObjectiveSheet(objective: any, referralAction: REFERRAL_ACTION = 1) {
    if (objective.Visibility !== 'Private') {
      this.srv
        .getObjectiveById(objective.Id, this.manager)
        .subscribe((objectiveResult: any) => {
          console.log('objectiveResult', objectiveResult);
          this.showObjectiveSheet(objectiveResult, true, referralAction);
        });
    }
  }

  showObjectiveSheet(
    obj: any,
    readOnly: boolean = false,
    referralAction: REFERRAL_ACTION = 1
  ) {
    const content: any = {
      objective: obj,
      state: 'DEFINITION_STATE',
      cycle: this.cycle,

      ReadOnly: readOnly,
      referral: REFERRAL.CYCLES,
      referralAction: referralAction,
    };
    if (this.iamAdmin) {
      if (this.currentUserInfo.isManager && this.manager === 'AsManager') {
        content.ViewMode = VIEW_MODE.MANAGER;
      } else {
        content.ViewMode = VIEW_MODE.ADMIN;
      }
    } else {
      content.ViewMode = VIEW_MODE.MANAGER;
    }

    console.log('showObjectiveSheet', content);

    switch (this.currentStatus) {
      case 'Progress':
        content.state = 'EXECUTION_STATE';
        break;
      case 'Evaluation':
        content.state = 'EVALUATION_STATE';
        break;
    }

    const modal = this.modalService.show(ObjSheetPopupComponent, {
      class: 'objective-sheet-popup',
    });

    (<ObjSheetPopupComponent>modal.content).showModal(content);
    (<ObjSheetPopupComponent>modal.content).onClose.subscribe(
      (result: boolean) => {
        if (result) {
          this.emitReloadEvent();
        }
      }
    );

    // ----- Handles in sheets objective remotions
    (<ObjSheetPopupComponent>modal.content).assignObjective.subscribe(
      (result: any) => {
        this.companyReportService
          .assignObjective(result.id, result.objective)
          .subscribe((r) => {
            this.emitReloadEvent();
          });
      }
    );
  }

  onNudge(
    rowIndex: number,
    row: any,
    direction: string,
    objective: any = undefined
  ) {
    // check per vedere se si può fare il nudge

    if (!objective) {
      if (this.nudgesSent[rowIndex][direction]) return;
    } else {
      if (this.subNudgeAlreadySent(objective.$id, direction)) return;
      console.log(objective.$id);
    }

    let toUser = true;
    if (direction === 'right') {
      toUser = false;
    }

    const content: any = {
      YesCaption: this.coreService.getTranslation('CYCLE_MANAGER.NudgeOk'),
      NoCaption: this.coreService.getTranslation('CYCLE_MANAGER.NudgeCancel'),
      type: 'YesNo',
      title: this.coreService.getTranslation('CYCLE_MANAGER.NudgeTitle'),
      subtitle: this.coreService.getTranslation('CYCLE_MANAGER.NudgeMeggage1'),
    };
    if (objective) {
      content.subtitle = this.coreService.getTranslation(
        'CYCLE_MANAGER.NudgeMeggage2'
      );
    }

    if (toUser) {
      content.subtitle = content.subtitle.replace(
        '{{Name}}',
        row.Employee.Name
      );
    } else {
      content.subtitle = content.subtitle.replace('{{Name}}', row.Manager.Name);
    }

    const modal = this.modalService.show(SimpleDialogComponent, {
      class: 'self-evaluation',
    });
    (<SimpleDialogComponent>modal.content).showModal(content);
    (<SimpleDialogComponent>modal.content).onClose.subscribe(
      (result: boolean) => {
        if (result) {
          this.srv
            .nudge(
              row.Employee.Id,
              row.Manager.Id,
              toUser,
              this.cycle.Id,
              objective ? objective.Id : undefined
            )
            .subscribe((r) => {
              this.coreService.toasterMessage('success', 'Nudge', 'Ok');

              if (objective) {
                this.subNudgesSent[rowIndex][direction] = true;
                this.subNudgesSent.forEach((element: any) => {
                  if (element.id === objective.$id) {
                    element[direction] = true;
                  }
                });

                console.log(this.subNudgesSent);
              } else {
                this.nudgesSent[rowIndex][direction] = true;
              }
            });
        }
      }
    );
  }

  getEvalArr() {
    let arr = [];

    for (var i = 1; i <= this.maxScale; i++) {
      arr.push(i);
    }

    return arr;
  }

  setEvaluationValue(objective: any, type: string, value: number) {
    if (objective.BossValue.Value === value) {
      objective.BossValue.Value = 0;
    } else {
      objective.BossValue.Value = value;
    }
    if (type === 'BossValue') {
      if (objective.BossValue.Value > 0 && objective.SelfValue.Value > 0) {
        objective.Status = 'Evaluated';
      } else if (
        objective.BossValue.Value === 0 &&
        objective.SelfValue.Value > 0
      ) {
        objective.Status = 'NoBoss';
      } else if (
        objective.BossValue.Value > 0 &&
        objective.SelfValue.Value === 0
      ) {
        objective.Status = 'NoSelf';
      } else {
        objective.Status = 'NotEvaluated';
      }

      this.srv
        .bossEvaluate(objective)
        .toPromise()
        .then((r) => {
          //this.emitReloadEvent();
          //TODO
          //RICARICARE LA PAGINA CI METTE TROPPO
          //DOVREMMO MODIFICARE IL MODELLO DATI IN MEMORIA
        });
    }
  }

  saveWeight(row, objective: any) {
    console.log(row);
    console.log(objective);
    this.srv.setObjectiveWeight(objective).subscribe((r) => {
      //Change weighh in original Bound object
      let emp = this.filteredEmployees.find(
        (x) => x.Employee.Id == row.Employee.Id
      );
      if (emp) {
        let obj = emp.Objectives.List.find((x) => x.Id == objective.Id);
        if (obj) {
          obj.Weight = objective.Weight;
        }
        emp.showWeightTot = this.showWeightTot(emp);
      }

      emp = this.originalFilteredEmployees.find(
        (x) => x.Employee.Id == row.Employee.Id
      );
      if (emp) {
        let obj = emp.Objectives.List.find((x) => x.Id == objective.Id);
        if (obj) {
          obj.Weight = objective.Weight;
        }
        emp.showWeightTot = this.showWeightTot(emp);
      }
    });
  }

  approveObjective(objective: any) {
    this.srv.approveObjective(objective).subscribe((r) => {
      this.emitReloadEvent();
      this.coreService.toasterMessage('success', 'Objectives', 'Ok');
    });
  }

  emitReloadEvent() {
    this.reload.emit({
      searchInput: this.searchInput.nativeElement.value,
      advFilters: this.currentFilter,
      detailsRow: this.currentExpandedRow,
    });
  }

  getStatusSummary(row) {
    let obj: any;
    switch (this.currentStatus) {
      case 'Definition':
        obj = row.Objectives.DefinitionStatusSummary;
        break;
      case 'Progress':
        obj = row.Objectives.ExecutionStatusSummary;
        break;
      case 'Evaluation':
        obj = row.Objectives.EvaluationStatusSummary;
        break;
    }

    return obj;
  }

  getColorByStatus(obj) {
    let status = this.getObjectiveStatus(obj);
    return this.getColorByStatusDetail(status);
  }

  getObjectiveStatus(obj) {
    let status: any;
    switch (this.currentStatus) {
      case 'Definition':
        status = obj.DefinitionStatus;
        break;
      case 'Progress':
        status = obj.ExecutionStatus;
        break;
      case 'Evaluation':
        status = obj.EvaluationStatus;
        break;
      default:
        //console.log('STATUS UNMANAGED => ', this.currentStatus);
        status = obj.EvaluationStatus;
        break;
    }
    return status;
  }

  getColorByStatusDetail(status: string) {
    switch (status) {
      case 'Open':
      case 'ToDo':
      case 'NotEvaluated':
        return { color: '#C9C9C9' };
        break;
      case 'AwaitingApproval':
      case 'NoBoss':
        return { color: '#FFDB45' };
        break;
      case 'Set':
      case 'Completed':
      case 'Evaluated':
        return { color: '#74BD32' };
        break;
      case 'WorkingOn':
      case 'InProgress':
      case 'NoSelf':
        return { color: '#004D7F' };
        break;
      case 'SuspendedDeferred':
        return { color: '#7D7E7E' };
        break;
      case 'NotApproved':
        return { color: '#bc4749' };
      default:
        return { color: '#FFFFFF' };
        break;
    }
  }

  setBgColor(status: string) {
    return {
      'background-color': this.getColorByStatusDetail(status).color,
    };
  }

  getPhaseNameBuStatus(obj) {
    let status = this.getObjectiveStatus(obj);
    switch (status) {
      case 'Open':
      case 'AwaitingApproval':
      case 'Set':
      case 'NotApproved':
        return 'DEFINITION_STATE';
        break;

      case 'ToDo':
      case 'WorkingOn':
      case 'InProgress':
      case 'Completed':
        return 'EXECUTION_STATE';
        break;

      case 'NotEvaluated':
      case 'NoBoss':
      case 'Evaluated':
      case 'NoSelf':
      case 'SuspendedDeferred':
        return 'EVALUATION_STATE';
        break;

      default:
        return 'EVALUATION_STATE';
        break;
    }
  }

  getStatusLabel(obj: any) {
    let label = '';
    let status = this.getObjectiveStatus(obj);
    if (obj.IsDeferred && !obj.DeferrSourceId) {
      //IS DEFERRED SOURCE
      if (obj.DeferrType === 'Extend') {
        label = this.getOnlyStatusText(status); // + '|' + this.coreService.getTranslation('OBJECTIVE_SHEET_POPUP.Extended');
      } else {
        label = this.coreService.getTranslation(
          'OBJECTIVE_SHEET_POPUP.Postponed'
        );
      }
    } else if (obj.IsSuspended) {
      label = this.coreService.getTranslation(
        'INDIVIDUAL_OBJECTIVES.EVALUATION_STATE.Suspended'
      );
    } else {
      label = this.getOnlyStatusText(status);
    }
    return label;
  }

  getOnlyStatusText(status) {
    let label = '';
    switch (status) {
      case 'Open':
        label = this.coreService.getTranslation(
          'INDIVIDUAL_OBJECTIVES.DEFINITION_STATE.COL_0.Name'
        );
        break;
      case 'AwaitingApproval':
        label = this.coreService.getTranslation(
          'INDIVIDUAL_OBJECTIVES.DEFINITION_STATE.COL_1.Name'
        );
        break;
      case 'Set':
        label = this.coreService.getTranslation(
          'INDIVIDUAL_OBJECTIVES.DEFINITION_STATE.COL_2.Name'
        );
        break;

      case 'ToDo':
        label = this.coreService.getTranslation(
          'INDIVIDUAL_OBJECTIVES.EXECUTION_STATE.COL_0.Name'
        );
        break;
      case 'WorkingOn':
        label = this.coreService.getTranslation(
          'INDIVIDUAL_OBJECTIVES.EXECUTION_STATE.COL_1.Name'
        );
        break;
      case 'InProgress':
        label = this.coreService.getTranslation(
          'INDIVIDUAL_OBJECTIVES.EXECUTION_STATE.COL_1.Name'
        );
        break;
      case 'Completed':
        label = this.coreService.getTranslation(
          'INDIVIDUAL_OBJECTIVES.EXECUTION_STATE.COL_2.Name'
        );
        break;
      case 'NotApproved':
        label = this.coreService.getTranslation(
          'INDIVIDUAL_OBJECTIVES.EXECUTION_STATE.COL_3.Name'
        );
        break;

      case 'NotEvaluated':
        label = this.coreService.getTranslation(
          'INDIVIDUAL_OBJECTIVES.EVALUATION_STATE.COL_0.Name'
        );
        break;
      case 'NoBoss':
        label = this.coreService.getTranslation(
          'INDIVIDUAL_OBJECTIVES.EVALUATION_STATE.NOBOSS.Name'
        );
        break;
      case 'Evaluated':
        label = this.coreService.getTranslation(
          'INDIVIDUAL_OBJECTIVES.EVALUATION_STATE.COL_1.Name'
        );
        break;
      case 'NoSelf':
        label = this.coreService.getTranslation(
          'INDIVIDUAL_OBJECTIVES.EVALUATION_STATE.NOSELF.Name'
        );
        break;
      case 'SuspendedDeferred':
        label = this.coreService.getTranslation(
          'INDIVIDUAL_OBJECTIVES.EVALUATION_STATE.COL_2.Name'
        );
        break;
    }

    return label;
  }

  showWeightTot(row: any) {
    let weightTot;
    if (this.cycle.EnableWheigthing) {
      let itemWeights = row.Objectives.List.filter(
        (item: any) => item.Visibility !== 'Private' && item.Weight
      );
      weightTot = 0;
      itemWeights.forEach((w: any) => (weightTot += Number(w.Weight)));
    }
    return weightTot;
  }

  advFilterMatching(objective: any) {
    //console.log('advFilterMatching ==>', objective.Name);

    if (
      !this.currentFilter ||
      !this.currentFilter['Weights'] ||
      !this.currentFilter['ChallengePRE'] ||
      !this.currentFilter['ChallengePOST'] ||
      !this.currentFilter['InclinationPRE'] ||
      !this.currentFilter['InclinationPOST'] ||
      !this.currentFilter['BossEvaluation'] ||
      !this.currentFilter['SelfEvaluation'] ||
      (this.currentFilter['Weights'][0] === 'ALL' &&
        this.currentFilter['ChallengePRE'][0] === 'ALL' &&
        this.currentFilter['ChallengePOST'][0] === 'ALL' &&
        this.currentFilter['InclinationPRE'][0] === 'ALL' &&
        this.currentFilter['InclinationPOST'][0] === 'ALL' &&
        this.currentFilter['BossEvaluation'][0] === 'ALL' &&
        this.currentFilter['SelfEvaluation'][0] === 'ALL')
    )
      return true;

    let matching: boolean = false,
      weightMatch: boolean = false,
      challengePreMatch: boolean = false,
      challengePostMatch: boolean = false,
      inclinationPreMatch: boolean = false,
      inclinationPostMatch: boolean = false,
      bossValueMatch: boolean = false,
      selfValueMatch: boolean = false;

    if (
      objective.Weight &&
      this.currentFilter['Weights'] &&
      this.currentFilter['Weights'][0] !== 'ALL' &&
      this.currentFilter['Weights'][0] !== 'MISSING'
    ) {
      this.currentFilter['Weights'].forEach((element: any) => {
        const range = element.split('-');
        if (
          objective.Weight >= Number(range[0]) &&
          objective.Weight <= Number(range[1])
        )
          weightMatch = true;
      });
    } else if (
      objective.Weight &&
      this.currentFilter['Weights'][0] === 'MISSING'
    ) {
      weightMatch = !objective.Weight;
    }

    if (
      objective.ChallengeValue &&
      this.currentFilter['ChallengePRE'] &&
      this.currentFilter['ChallengePRE'][0] !== 'ALL' &&
      this.currentFilter['ChallengePRE'][0] !== 'MISSING'
    ) {
      this.currentFilter['ChallengePRE'].forEach((element: any) => {
        if (
          objective.ChallengeValue.Value ===
          Number(this.currentFilter['ChallengePRE'])
        )
          challengePreMatch = true;
      });
    } else if (
      objective.ChallengeValue &&
      this.currentFilter['ChallengePRE'][0] === 'MISSING'
    ) {
      challengePreMatch = !objective.ChallengeValue.Value;
    }

    if (
      objective.PostChallengeValue &&
      this.currentFilter['ChallengePOST'] &&
      this.currentFilter['ChallengePOST'][0] !== 'ALL' &&
      this.currentFilter['ChallengePOST'][0] !== 'MISSING'
    ) {
      this.currentFilter['ChallengePOST'].forEach((element: any) => {
        if (
          objective.PostChallengeValue.Value ===
          Number(this.currentFilter['ChallengePOST'])
        )
          challengePostMatch = true;
      });
    } else if (
      objective.PostChallengeValue &&
      this.currentFilter['ChallengePOST'][0] === 'MISSING'
    ) {
      challengePostMatch = !objective.PostChallengeValue.Value;
    }

    if (
      objective.InclinationValue &&
      this.currentFilter['InclinationPRE'] &&
      this.currentFilter['InclinationPRE'][0] !== 'ALL' &&
      this.currentFilter['InclinationPRE'][0] !== 'MISSING'
    ) {
      this.currentFilter['InclinationPRE'].forEach((element: any) => {
        if (
          objective.InclinationValue.Value ===
          Number(this.currentFilter['InclinationPRE'])
        )
          inclinationPreMatch = true;
      });
    } else if (
      objective.InclinationValue &&
      this.currentFilter['InclinationPRE'][0] === 'MISSING'
    ) {
      inclinationPreMatch = !objective.InclinationValue.Value;
    }

    if (
      objective.PostInclinationValue &&
      this.currentFilter['InclinationPOST'] &&
      this.currentFilter['InclinationPOST'][0] !== 'ALL' &&
      this.currentFilter['InclinationPOST'][0] !== 'MISSING'
    ) {
      this.currentFilter['InclinationPOST'].forEach((element: any) => {
        if (
          objective.PostInclinationValue.Value ===
          Number(this.currentFilter['InclinationPOST'])
        )
          inclinationPostMatch = true;
      });
    } else if (
      objective.PostInclinationValue &&
      this.currentFilter['InclinationPOST'][0] === 'MISSING'
    ) {
      inclinationPostMatch = !objective.PostInclinationValue.Value;
    }

    if (
      objective.BossValue &&
      this.currentFilter['BossEvaluation'] &&
      this.currentFilter['BossEvaluation'][0] !== 'ALL' &&
      this.currentFilter['BossEvaluation'][0] !== 'MISSING'
    ) {
      this.currentFilter['BossEvaluation'].forEach((element: any) => {
        if (
          objective.BossValue.Value ===
          Number(this.currentFilter['BossEvaluation'])
        )
          bossValueMatch = true;
      });
    } else if (
      objective.BossValue &&
      this.currentFilter['BossEvaluation'][0] === 'MISSING'
    ) {
      bossValueMatch = !objective.BossValue.Value;
    }

    if (
      objective.SelfValue &&
      this.currentFilter['SelfEvaluation'] &&
      this.currentFilter['SelfEvaluation'][0] !== 'ALL' &&
      this.currentFilter['SelfEvaluation'][0] !== 'MISSING'
    ) {
      this.currentFilter['SelfEvaluation'].forEach((element: any) => {
        if (
          objective.SelfValue.Value ===
          Number(this.currentFilter['SelfEvaluation'])
        )
          selfValueMatch = true;
      });
    } else if (
      objective.SelfValue &&
      this.currentFilter['SelfEvaluation'][0] === 'MISSING'
    ) {
      selfValueMatch = !objective.SelfValue.Value;
    }

    matching =
      (weightMatch || this.currentFilter['Weights'][0] == 'ALL') &&
      (challengePreMatch || this.currentFilter['ChallengePRE'][0] == 'ALL') &&
      (challengePostMatch || this.currentFilter['ChallengePOST'][0] == 'ALL') &&
      (inclinationPreMatch ||
        this.currentFilter['InclinationPRE'][0] == 'ALL') &&
      (inclinationPostMatch ||
        this.currentFilter['InclinationPOST'][0] == 'ALL') &&
      (bossValueMatch || this.currentFilter['BossEvaluation'][0] == 'ALL') &&
      (selfValueMatch || this.currentFilter['SelfEvaluation'][0] == 'ALL');

    console.log(
      objective.Name,
      '==>',
      'matching',
      matching,
      'weightMatch',
      (weightMatch || this.currentFilter['Weights'][0] == 'ALL') &&
        'challengePreMatch',
      (challengePreMatch || this.currentFilter['ChallengePRE'][0] == 'ALL') &&
        'challengePostMatch',
      (challengePostMatch || this.currentFilter['ChallengePOST'][0] == 'ALL') &&
        'inclinationPreMatch',
      (inclinationPreMatch ||
        this.currentFilter['InclinationPRE'][0] == 'ALL') &&
        'inclinationPostMatch',
      (inclinationPostMatch ||
        this.currentFilter['InclinationPOST'][0] == 'ALL') &&
        'bossValueMatch',
      (bossValueMatch || this.currentFilter['BossEvaluation'][0] == 'ALL') &&
        'selfValueMatch',
      selfValueMatch || this.currentFilter['SelfEvaluation'][0] == 'ALL'
    );

    return matching;
  }
}
