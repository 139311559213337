import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Pp360SpinnerService } from 'app/pp360-components/pp360-spinner/pp360-spinner.service';
import { WallService } from 'app/employee/wall/wall.service';
import { CoreService } from 'app/shared/services/core.service';

interface CommentItem {
  // image: any;
  image: string;
  comment: string;
}

@Component({
  selector: 'post-comment',
  templateUrl: './post-comment.component.html',
  styleUrls: ['./post-comment.component.scss'],
})
export class PostCommentComponent implements OnInit, OnDestroy, OnChanges {
  @Output() postComment = new EventEmitter<CommentItem>();
  @Output() updateComment = new EventEmitter<any>();
  @Output() replyComment = new EventEmitter<CommentItem>();
  @Output() cancelEdit = new EventEmitter<any>();
  @Input() sendingComment;
  @Input() isReply: boolean;
  @Input() defaultComment;
  @Input() isNotAnswer: boolean;
  @Input() filled: boolean;
  commentBeforeEdit;
  commentModel = '';
  userInfo: any;
  url: any;
  curserPosition: number = 0;
  msg = '';
  count: number = 2;
  showMore = true;
  imageFile: File;
  imageInputId = Math.random();
  i18n: any = {
    search: this.coreService.getTranslation('Emoji.Search'),
    categories: {
      recent: this.coreService.getTranslation('Emoji.Frequently_Used'),
      people: this.coreService.getTranslation('Emoji.Smileys_People'),
      nature: this.coreService.getTranslation('Emoji.Animals_Nature'),
      foods: this.coreService.getTranslation('Emoji.Food_Drink'),
      activity: this.coreService.getTranslation('Emoji.Activity'),
      places: this.coreService.getTranslation('Emoji.Travel_Places'),
      objects: this.coreService.getTranslation('Emoji.Objects'),
      symbols: this.coreService.getTranslation('Emoji.Symbols'),
      flags: this.coreService.getTranslation('Emoji.Flags'),
    },
  };
  // imgarr: any = [];

  constructor(
    public coreService: CoreService,
    public wallService: WallService,
    private pp360SpinnerService: Pp360SpinnerService
  ) {
    this.userInfo = this.coreService.getUserInfo();
  }

  ngOnInit(): void {
    if (this.defaultComment) {
      this.commentBeforeEdit = JSON.parse(JSON.stringify(this.defaultComment));
      this.commentModel = this.defaultComment.Text;
      if (this.defaultComment.Picture) {
        this.url = this.coreService.getBaseURL() + this.defaultComment.Picture;
      }
    }
  }
  ngAfterViewInit() {
    // this.defaultComment = this.defaultComment?.reverse();
  }

  ngOnDestroy(): void {
    this.commentModel = '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sendingComment) {
      this.sendingComment = changes.sendingComment.currentValue;
      if (!this.sendingComment) {
        this.commentModel = '';
        this.removeImage();
      }
    }
  }

  getCaretPos(evt) {
    if (evt.selectionStart || evt.selectionStart == '0') {
      this.curserPosition = evt.selectionStart;
    }
  }

  addEmoji(evt) {
    this.commentModel = [
      this.commentModel.slice(0, this.curserPosition),
      evt.emoji.native,
      this.commentModel.slice(this.curserPosition),
    ].join('');
  }
  showHideTranslated(comment, property) {
    if (comment.ShowTranslated) {
      comment.ShowTranslated = !comment.ShowTranslated;
    } else {
      let selectedLang = this.userInfo.language;
      const text = comment[property];
      this.coreService
        .translateText(text, null, selectedLang)
        .subscribe((result: any) => {
          comment['TranslatedText'] = result.TranslatedText;
          comment.ShowTranslated = true;
        });
    }
  }
  // urlimagedata = [];
  loadFile(event) {
    if (event.target.files && event.target.files.length) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      this.imageFile = event.target.files[0];
      reader.onload = (_event) => {
        this.msg = '';
        this.url = reader.result;
      };
    }
  }
  removeImage() {
    this.url = '';
    this.imageFile = null;
  }
  async sendMsg() {
    const commentItem: CommentItem = {
      image: '',
      comment: this.commentModel.trim(),
    };
    if (!commentItem.comment && !this.url) {
      this.coreService.toasterMessage(
        'error',
        'wall',
        'Please Enter Comment Text or Picture'
      );
      return;
    }
    if (this.imageFile) {
      this.pp360SpinnerService.showSpinner(true);
      let formData: FormData = new FormData();
      formData.append(
        'uploadCommentImage',
        this.imageFile,
        this.imageFile.name
      );
      const image: any = await this.wallService
        .uploadCommentImage(formData)
        .toPromise();
      commentItem.image = image.ImageUrl;
    }
    if (this.isReply) {
      this.replyComment.emit(commentItem);
    } else {
      this.postComment.emit(commentItem);
    }
  }

  uploadImage() {
    this.wallService.uploadCommentImage(null).subscribe((data: any) => {});
  }

  disableSave() {
    const pic = this.commentBeforeEdit.Picture
      ? this.coreService.getBaseURL() + this.commentBeforeEdit.Picture
      : '';
    return (
      this.commentBeforeEdit.Text === this.commentModel && pic === this.url
    );
  }

  changePlaceholder() {
    if (this.isNotAnswer || this.filled) {
      return '';
    } else {
      if (this.isReply) {
        return this.coreService.getTranslation('Generic.TypeYourReply');
      } else {
        return this.coreService.getTranslation('Generic.TypeYourComment');
      }
    }
  }

  async editComment() {
    let reqBody = {};
    this.defaultComment.Text = this.commentModel.trim();
    if (!this.defaultComment.Text && !this.url) {
      this.coreService.toasterMessage(
        'error',
        'wall',
        'Please Enter atleast Comment Text or Picture'
      );
      return;
    }
    this.pp360SpinnerService.showSpinner(true);
    if (this.imageFile) {
      let formData: FormData = new FormData();
      formData.append(
        'uploadCommentImage',
        this.imageFile,
        this.imageFile.name
      );
      const image: any = await this.wallService
        .uploadCommentImage(formData)
        .toPromise();
      this.defaultComment.Picture = image.ImageUrl;
    } else if (!this.url) {
      this.defaultComment.Picture = '';
    }
    reqBody = {
      Text: this.defaultComment.Text ? this.defaultComment.Text : '',
      Picture: this.defaultComment.Picture,
      Id: this.defaultComment.Id,
    };
    this.wallService.updateComment(this.defaultComment.Id, reqBody).subscribe(
      (data: any) => {
        this.updateComment.emit(data);
        this.pp360SpinnerService.showSpinner(false);
      },
      (err: any) => {
        this.pp360SpinnerService.showSpinner(false);
        this.coreService.toasterMessage('error', 'wall', err.Message);
      }
    );
    this.cancel();
  }
  cancel() {
    this.defaultComment = this.commentBeforeEdit;
    this.cancelEdit.emit();
  }
}
