import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { initScreen } from '../../app.helpers';
import { AppConfigService } from 'app/app-config.service';
import { smoothlyMenu } from '../../app.helpers';
import { CoreService } from '../../shared/services/core.service';
import { ScreenSizeService } from '../../shared/services/screen-size.service';
import { navigationService } from 'app/shared/services/navigation.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PartecipantLTIService } from '../partecipant-lti.service';
//-------------------------------------------------------------------------------
// DESKTOP
//-------------------------------------------------------------------------------
import { DocumentsComponent } from './documents/documents.component';
import { TargetBonusComponent } from './target-bonus/target-bonus.component';
import { ObjectivesComponent } from './objectives/objectives.component';
import { BonusSimulationComponent } from './bonus-simulation/bonus-simulation.component';
import { ResultsComponent } from './results/results.component';
//-------------------------------------------------------------------------------
// MOBILE
//-------------------------------------------------------------------------------
import { MobileMenuComponent } from './mobile/mobile-menu/mobile-menu.component';
import { BonusAccruedComponent } from './mobile/bonus-accrued/bonus-accrued.component';
import { BonusBankComponent } from './mobile/bonus-bank/bonus-bank.component';
import { BonusPayableComponent } from './mobile/bonus-payable/bonus-payable.component';
import { MyLTIFormComponent } from './mobile/my-ltiform/my-ltiform.component';
import { TermsConditionsComponent } from './mobile/terms-conditions/terms-conditions.component';
import { VexatiousClausesComponent } from './mobile/vexatious-clauses/vexatious-clauses.component';
import { ObjectivesMComponent } from './mobile/objectives/objectives.component';
import { PayoutStructureComponent } from './mobile/payout-structure/payout-structure.component';
import { GlossaryComponent } from './mobile/glossary/glossary.component';
import { calculateLTIPlanParametes } from '../../admin-lti/functions';
import { AdminLtiService } from '../../admin-lti/admin-lti.service';
import { combineLatest } from 'rxjs';

import { Pp360SpinnerService } from 'app/pp360-components/pp360-spinner/pp360-spinner.service';

@Component({
  selector: 'app-recipient',
  templateUrl: './recipient.component.html',
  styleUrls: [
    './recipient.component.scss',
    './recipient.component-mobile.scss',
  ],
})
export class RecipientComponent implements OnInit {
  currentPlan: any;
  userInfo: any;
  userId: any;
  currentView: string;
  loading = false;
  isMobile: boolean = false;
  formats: any;
  measures: any;
  scales: any;
  goals: any;

  private formatsObsrv$;
  private scalesObsrv$;
  private measuresObsrv$;
  private goalsObsrv$;

  constructor(
    private coreService: CoreService,
    private partecipantLTIService: PartecipantLTIService,
    private screenSizeService: ScreenSizeService,
    private navigationService: navigationService,
    private router: Router,
    private ref: ChangeDetectorRef,
    public activatedRoute: ActivatedRoute,
    private adminLtiService: AdminLtiService,
    private appConfigService: AppConfigService,
    private pp360SpinnerService: Pp360SpinnerService
  ) {
    this.screenSizeService.ScreenSize.subscribe((res: number) => {
      this.isMobile = res <= 991;
      if (this.isMobile) {
        this.navigationService.hideToolbar.next(true);
        //$("body").removeClass("mini-navbar");
      }
    });
  }

  public ngOnDestroy(): any {
    this.navigationService.hideToolbar.next(false);
  }

  toggleNavigation(): void {
    //$("body").toggleClass("mini-navbar");
    smoothlyMenu();
  }

  ngAfterViewInit() {
    setTimeout(() => initScreen(), 100);

    this.appConfigService.replaceHeader$.next({
      showIcons: false,
      showTitle: true,
      pageIcon: 'fa-handshake-o',
      pageTitle: this.coreService.getTranslation('LTI'),
    });
  }

  ngOnInit() {
    this.toggleNavigation();

    let planId = this.activatedRoute.snapshot.params['id'];
    this.userInfo = this.coreService.getUserInfo();
    this.userId = this.userInfo.linkedEmployeeId;

    this.formatsObsrv$ = this.adminLtiService.getFormats();
    this.scalesObsrv$ = this.adminLtiService.getScales();
    this.measuresObsrv$ = this.adminLtiService.getAllMeasures();
    this.goalsObsrv$ = this.adminLtiService.getGoals();

    this.pp360SpinnerService.showSpinner();
    combineLatest(
      this.formatsObsrv$,
      this.scalesObsrv$,
      this.measuresObsrv$,
      this.goalsObsrv$,
      (formatsObsrv, scalesObsrv, measuresObsrv, goalsObsrv) => ({
        formatsObsrv,
        scalesObsrv,
        measuresObsrv,
        goalsObsrv,
      })
    ).subscribe(
      (pair: any) => {
        console.log(pair.measuresObsrv);

        this.formats = pair.formatsObsrv;
        this.scales = pair.scalesObsrv;
        this.measures = pair.measuresObsrv;
        this.goals = pair.goalsObsrv;
        this.partecipantLTIService.getPlanByEmployee(planId).subscribe(
          (plan: any) => {
            this.currentPlan = plan;
            this.connectMeasures(this.currentPlan.Gateways);
            this.connectMeasures(this.currentPlan.Objectives);
            this.connectMeasures(this.currentPlan.Correctives);

            calculateLTIPlanParametes(plan, true);

            this.currentView = this.isMobile ? 'rootMobile' : 'targetbonus';

            this.pp360SpinnerService.showSpinner(false);
          },
          () => {
            this.pp360SpinnerService.showSpinner(false);
          }
        );
      },
      () => {
        this.pp360SpinnerService.showSpinner(false);
      }
    );
  }

  connectMeasures(list) {
    list.forEach((g) => {
      if (g.GoalId) {
        let gl = this.goals.find((y) => y.Id == g.GoalId);
        if (gl) g.Goal = gl;
      }

      let m = this.measures.find((y) => y.Id == g.MeasureId);
      if (m) g.Measure = m;

      if (g.ScaleId) {
        let s = this.scales.find((y) => y.Id == g.ScaleId);
        if (s) g.Scale = s;
      }

      if (g.FormatId) {
        let f = this.formats.find((y) => y.Id == g.FormatId);
        if (f) g.Format = f;
      }
      g.YearlyValues.forEach((yv) => {
        if (yv.TargetValue == -100000) yv.TargetValue = undefined;
      });
    });
  }

  setCurrentView(view: string) {
    this.currentView = view;
  }

  accept() {
    this.currentView = '';
    this.partecipantLTIService
      .acceptPlan(this.userInfo.belongingCompanyId)
      .subscribe((plan: any) => {
        this.currentPlan = plan;
        this.currentView = 'documents';
      });
  }

  // Parte Mobile

  checkHideMenu() {
    switch (this.currentView) {
      case 'rootMobile':
        this.hideMenu = false;
        break;
      case 'planReviewMobile':
        this.hideMenu = false;
        break;
      case 'bonusSimulationMobile':
        this.hideMenu = true;
        break;
      case 'resultsMobile':
        this.hideMenu = false;
        break;
      case 'myLtiFormMobile':
        this.hideMenu = true;
        break;
      case 'terms&ConditionsMobile':
        this.hideMenu = true;
        break;
      case 'vexatiousClausesMobile':
        this.hideMenu = true;
        break;
      case 'objectivesMobile':
        this.hideMenu = true;
        break;
      case 'gatewaysMobile':
        this.hideMenu = true;
        break;
      case 'bonusBankCorrectiveMobile':
        this.hideMenu = true;
        break;
      case 'targetBonusMobile':
        this.hideMenu = true;
        break;
      case 'payoutStructureMobile':
        this.hideMenu = true;
        break;
      case 'glossaryMobile':
        this.hideMenu = true;
        break;
      case 'bonusAccruedWidgetMobile':
        this.hideMenu = true;
        break;
      case 'bonusBankWidgetMobile':
        this.hideMenu = true;
        break;
      case 'bonusPayableWidgetMobile':
        this.hideMenu = true;
        break;
    }
  }

  headCaption: string = 'LTI';
  headCaptionList: string[] = [];
  prevView: string[] = ['myWallet'];
  hideMenu: boolean = false;
  getInfoView(infoView: any) {
    console.log('getInfoView', infoView);
    this.currentView = infoView.currentView;
    this.prevView.push(infoView.prevView);
    this.headCaptionList.push(this.headCaption);
    this.headCaption = infoView.headCaption;
    this.checkHideMenu();
    this.ref.detectChanges();
  }

  goBack() {
    if (!this.prevView || this.prevView.length == 0) {
      this.router.navigate(['/User/wall/feed']);
      return;
    }
    const prevView = this.prevView.pop();
    if (prevView == 'myWallet') {
      this.navigationService.hideToolbar.next(false);
      this.router.navigate(['/User/wall/feed']);
      return;
    } else {
      this.currentView = prevView;
      this.headCaption = this.headCaptionList.pop();
    }
    this.checkHideMenu();
  }
}
