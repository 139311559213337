import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { CoreService } from '../../shared/services/core.service';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from 'app/shared/theme/theme.service';
import * as moment from 'moment';
import {
  MsalService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  InteractionType,
  PopupRequest,
  RedirectRequest,
} from '@azure/msal-browser';
import { Pp360SpinnerService } from 'app/pp360-components/pp360-spinner/pp360-spinner.service';
import { navigationService } from 'app/shared/services/navigation.service';
@Component({
  selector: 'app-first-login',
  templateUrl: './first-login.component.html',
  styleUrls: [
    '../login.common.scss',
    './first-login.component.scss',
    '../../common_styles.scss',
  ],
})
export class FirstLoginComponent implements OnInit {
  uId = '';
  model = {
    Id: '',
    Email: '',
    NewPassword: '',
    ConfirmPassword: '',
  };

  lang: any;
  isProcessing = false;
  show1 = false;
  show2 = false;
  coopyright: any;
  companyLogo: string;
  afterLanguage;
  userdata: any;
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    infinite: false,
    draggable: false,
    // prevArrow: false,
    // nextArrow: false,
  };
  isRememberMe: boolean = true;
  successLogin: boolean = false;
  forgetLogin: boolean = false;

  constructor(
    public activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    public coreService: CoreService,
    public service: AuthService,
    public themeSrv: ThemeService,
    public route: ActivatedRoute,
    public router: Router,
    private renderer: Renderer2,
    private pp360SpinnerService: Pp360SpinnerService,
    public navService: navigationService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService
  ) {
    this.model.Id = this.activatedRoute.snapshot.queryParams['uid'];
    this.lang = this.activatedRoute.snapshot.queryParams['lang'] || 'en';
    this.translate.addLangs([
      'en',
      'it',
      'pl',
      'es',
      'pt',
      'nl',
      'de',
      'zh',
      'fr',
      'ru',
    ]);
    this.translate.use(this.lang);
    this.afterLanguage = true;

    const d = new Date();
    const y = d.getFullYear();

    this.coopyright = y + '-' + (y - 2000 + 1);
  }

  ngOnInit() {
    this.model.Email = this.activatedRoute.snapshot.queryParams['mail'];
    this.service.getData(this.lang).subscribe((response: any[]) => {
      let ids: any = localStorage.getItem('newFeaturedIds');
      if (ids) {
        ids = JSON.parse(ids);
        response.forEach((d) => {
          if (d.IsNewFeature) {
            d.IsNewFeature = true;
          }
        });
      }
      this.userdata = response;
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.cid) {
        this.service.getCompanyLogo(params.cid).subscribe((image: any) => {
          this.companyLogo = image
            ? image
            : 'assets/images/logos/whitelogo.svg';
        });

        this.service.getCompanyStyle(params.cid).subscribe((style: any) => {
          if (style) {
            let theme = JSON.parse(style);
            this.themeSrv.setExternalTheme(theme);
          } else {
            this.themeSrv.setDefaultTheme();
          }
        });
      }
    });
    this.route.queryParams.subscribe((params) => {
      if (params.isForgotPassword) {
        this.forgetLogin = true;
      }
    });
  }

  setColorOfButtons(e) {
    const slickDotList = e.slick.$dots;
    if (this.userdata && slickDotList[0]?.children) {
      let dataArray = slickDotList[0].children;
      for (let i = 0; i < dataArray.length; i++) {
        if (this.userdata[i].IsNewFeature) {
          dataArray[i].classList.add('testingClass');
        }
      }
    }
  }

  slickInit(e) {
    this.setColorOfButtons(e);
  }

  beforeChange(e) {
    let slickDotLists = e.slick.$dots;
    if (this.userdata && slickDotLists[0]?.children) {
      let dataArray = slickDotLists[0].children;
      for (let i = 0; i < dataArray.length; i++) {
        if (i === e.currentSlide && this.userdata[i].IsNewFeature) {
          dataArray[i].classList.remove('testingClass');
        }
      }
    }
  }

  setNewFeature(e) {
    const ind = e.currentSlide;
    const itemId = this.userdata[ind].$id;
    if (this.userdata[ind] && this.userdata[ind].IsNewFeature) {
      let ids: any = localStorage.getItem('newFeaturedIds');
      if (ids) {
        ids = JSON.parse(ids);
        if (!ids.includes(itemId)) {
          ids.push(itemId);
        }
      } else {
        ids = [itemId];
      }
      localStorage.setItem('newFeaturedIds', JSON.stringify(ids));
    }
  }

  isPwdValid() {
    var pwd = this.model.NewPassword;
    var hasLower = /[a-z]/.test(pwd);
    var hasUpper = /[A-Z]/.test(pwd);
    var hasNumber = /[0-9]/.test(pwd);
    return hasLower && hasUpper && hasNumber;
  }

  get isUppercase() {
    var pwd = this.model.NewPassword;
    var hasUpper = /[A-Z]/.test(pwd);
    return hasUpper;
  }

  get isLowerCase() {
    var pwd = this.model.NewPassword;
    var hasLower = /[a-z]/.test(pwd);
    return hasLower;
  }

  get isNumber() {
    var pwd = this.model.NewPassword;
    var hasNumber = /[0-9]/.test(pwd);
    return hasNumber;
  }

  onSubmit(isvalid) {
    if (isvalid) {
      this.isProcessing = true;
      this.service.firtLogin(this.model).subscribe(
        (data: any) => {
          // this.coreService.toasterMessage(
          //   "success",
          //   "auth",
          //   this.coreService.getTranslation("Messages.AccountActivated")
          //   );
          if (this.forgetLogin) {
            this.successLogin = true;
          } else {
            this.router.navigate(['/login']);
          }
        },
        (error) => {
          this.isProcessing = false;
          this.coreService.toasterMessage('error', 'auth', error.Message);
          // if (error.code == 503) this.router.navigate(["/extra/maintenance"]);
        },
        () => (this.isProcessing = false)
      );
    }
  }

  successFeed() {
    this.router.navigate(['/login']);
  }

  isDisableConfirm(Email) {
    return (
      !this.model.Email ||
      !this.model.NewPassword ||
      Email.errors?.pattern ||
      !this.isPwdValid()
    );
  }
  onRememberMe() {
    if (!this.isRememberMe) {
      this.isRememberMe = true;
    } else if (this.isRememberMe) {
      this.isRememberMe = false;
    }
  }
  myFocusNewPass() {
    this.renderer.selectRootElement('#NewPassword').focus();
  }
  myFocusEmail() {
    this.renderer.selectRootElement('#Email').focus();
  }
  myFocusPass() {
    this.renderer.selectRootElement('#ConfirmPassword').focus();
  }

  msLogin() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService
          .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.getPatPatToken();
            this.setActiveAccount(response.account);
          });
      } else {
        this.authService
          .loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
        } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  }

  getPatPatToken(): void {
    this.pp360SpinnerService.showSpinner(true);
    this.service.getToken().subscribe(
      (data: any) => {
        this.pp360SpinnerService.showSpinner(false);
        this.postLogin(data);
      },
      (error) => {
        this.pp360SpinnerService.showSpinner(false);
      }
    );
  }

  setActiveAccount(account: any) {
    this.authService.instance.setActiveAccount(account);
  }

  async postLogin(data) {
    await this.service.setUserToken(data);
    if (data.roles != 'User') this.getLanguages();
    const lang = data.language;
    let x = lang.match(/en|it|pl|nl|es|pt|de|ru|zh|fr/) ? lang : 'en';
    moment.locale(x);
    if (this.translate.currentLang != x) {
      await this.translate.use(x).toPromise<void>();
    }
    this.service.redirectUser(data.PreferredRole);
    this.navService.setUpdateSideMenu(true);
  }

  getLanguages() {
    this.isProcessing = true;
    this.service.getLanguages().subscribe(
      (data: any) => {
        this.service.setLanguages(data);
      },
      (error) => {},
      () => {}
    );
  }
  openSite(siteUrl) {
    siteUrl = 'https://patpat360.com/pulse/';
    window.open(siteUrl, '_blank');
  }
}
