import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ChangeDetectorRef,
  ElementRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
// import { ChartOptions, ChartType } from 'chart.js';
import { CoreService } from 'app/shared/services/core.service';
import * as d3 from 'd3';
import { color } from 'd3';
// import { BaseChartDirective } from 'ng2-charts';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

//import * as BackgroundColorTheif from 'assets/js/bkcolortheif.js';
//declare var BackgroundColorTheif: any;

// Charts
import { Chart } from 'chart.js/dist/Chart.min.js';

import * as Vibrant from 'node-vibrant/dist/vibrant.js';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss', '../../../../../common_styles.scss'],
})
export class FiltersComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() cycle: any;
  @Input() currentFilter: any;
  @Input() currentStatus: any;
  @Output() FilterChange = new EventEmitter();

  readySubject = new BehaviorSubject<boolean>(false);
  readyPie$ = this.readySubject.asObservable();

  doughnutChartLabels: any[] = [];
  //doughnutChartData: MultiDataSet = [];
  doughnutChartData: any = [];
  doughnutChartColors: any[] = [];
  doughnutChartOptions: any = {};
  isUnweighted: boolean = false;

  userInfo: any;

  // @ViewChild(BaseChartDirective) baseChart: BaseChartDirective;

  // Chart
  private chart: ElementRef;
  @ViewChild('chart') set _chart(content: ElementRef) {
    if (content) {
      this.chart = content;
    }
  }
  myChart: any;

  color: any;

  iamManager: boolean;
  iamAdmin: boolean;

  //AdvFilters
  showAdvFilter: boolean = false;
  advFilterWeights: any[] = [];
  advFilterChallengePRE: any[] = [];
  advFilterInclinationPRE: any[] = [];
  advFilterChallengePOST: any[] = [];
  advFilterInclinationPOST: any[] = [];
  advFilterBossEvaluation: any[] = [];
  advFilterSelfEvaluation: any[] = [];

  advFilterSettings: any = {
    singleSelection: false,
    enableSearchFilter: false,
    classes: 'multiselectDropdown',
    showCheckbox: true,
    enableCheckAll: false,
    autoPosition: false,
    badgeShowLimit: 1,
  };

  weightOptions = {
    Values: [
      { Id: 'MISSING', Name: 'Missing' },
      { Id: '1-10', Name: '1-10%' },
      { Id: '11-20', Name: '11-20%' },
      { Id: '21-30', Name: '21-30%' },
      { Id: '31-40', Name: '31-40%' },
      { Id: '41-50', Name: '41-50%' },
      { Id: '51-60', Name: '51-60%' },
      { Id: '61-70', Name: '61-70%' },
      { Id: '71-80', Name: '71-80%' },
      { Id: '81-90', Name: '81-90%' },
      { Id: '91-100', Name: '91-100%' },
    ],
    Id: 'weights',
  };

  challengePRE;

  inclinationPRE;

  challengePOST;

  inclinationPOST;

  bossEvaluation;

  selfEvaluation;

  defaultWeightFilterVal;
  defaultChallengePREFilterVal;
  defaultChallengePostFilterVal;
  defaultInclinationPREFilterVal;
  defaultInclinationPOSTFilterVal;
  defaultBossEvaluationFilterVal;
  defaultSelfEvaluationFilterVal;

  constructor(
    public coreService: CoreService,
    private ref: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService
  ) {
    this.color = d3.scaleOrdinal(d3.schemeCategory10);
    this.setOptionsAll('defaultWeightFilterVal', 'weightOptions');
  }

  setOptionsAll(filterName: string, optionName: string) {
    this[filterName] = {
      search: {
        value: this[optionName].Values.map((x) => x.Id).join(';'),
      },
    };
  }

  hexToRgb(hex: string, opacity: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    var rgba = result
      ? `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(
          result[3],
          16
        )},${opacity})`
      : null;
    return rgba;
  }

  ngOnInit() {
    this.userInfo = this.coreService.getUserInfo();
    // console.log('userInfo', this.userInfo);
    this.cycle.Details.PerCategory = this.cycle.Details.PerCategory.filter(
      (x) => x.Name !== ''
    );
    this.cycle.Details.PerCategory.sort((a, b) => a.Name.localeCompare(b.Name));
    // console.log('cycle', this.cycle);

    this.iamAdmin =
      this.userInfo.roles.toLowerCase().indexOf('companyadmin') >= 0;
    this.iamManager =
      this.userInfo.isManager === true || this.userInfo.isEvaluator === true;
    const options = {
      Values: [{ Id: 'MISSING', Name: 'Missing' }],
      Id: 'challangePre',
    };
    // Imposto gli advFilters
    if (this.userInfo.EvaluationScale) {
      for (
        let index = this.userInfo.EvaluationScale.Min;
        index <= this.userInfo.EvaluationScale.Max;
        index++
      ) {
        options.Values.push({ Id: index.toString(), Name: index.toString() });
      }
    }
    this.challengePRE = JSON.parse(JSON.stringify(options));
    this.challengePOST = JSON.parse(JSON.stringify(options));
    this.inclinationPRE = JSON.parse(JSON.stringify(options));
    this.inclinationPOST = JSON.parse(JSON.stringify(options));
    this.bossEvaluation = JSON.parse(JSON.stringify(options));
    this.selfEvaluation = JSON.parse(JSON.stringify(options));
    this.setOptionsAll('defaultChallengePREFilterVal', 'challengePRE');
    this.setOptionsAll('defaultChallengePostFilterVal', 'challengePOST');
    this.setOptionsAll('defaultInclinationPREFilterVal', 'inclinationPRE');
    this.setOptionsAll('defaultInclinationPOSTFilterVal', 'inclinationPOST');
    this.setOptionsAll('defaultBossEvaluationFilterVal', 'bossEvaluation');
    this.setOptionsAll('defaultSelfEvaluationFilterVal', 'selfEvaluation');

    this.clearAllAdvFilter();
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params && params.type === 'Unweighted') {
        this.showAdvFilter = true;
        this.defaultWeightFilterVal = { search: { value: 'MISSING' } };
      }
    });
    if (this.currentFilter) {
      // console.log(this.currentFilter);
      this.showAdvFilter = true;
      // Weights
      if (this.currentFilter.Weights && this.currentFilter.Weights.length > 0) {
        this.advFilterWeights = [];
        this.currentFilter.Weights.forEach((element) => {
          this.advFilterWeights.push({
            Id: element,
            Name: this.weightOptions.Values.find((w: any) => w.id === element)
              .Name,
          });
        });
      }

      // ChallengePRE
      if (
        this.currentFilter.ChallengePRE &&
        this.currentFilter.ChallengePRE.length > 0
      ) {
        this.advFilterChallengePRE = [];
        this.currentFilter.ChallengePRE.forEach((element) => {
          this.advFilterChallengePRE.push({
            id: element,
            itemName: this.challengePRE.Values.find(
              (w: any) => w.Id === element
            ).Name,
          });
        });
      }

      // challengePOST
      if (
        this.currentFilter.ChallengePOST &&
        this.currentFilter.ChallengePOST.length > 0
      ) {
        this.advFilterChallengePOST = [];
        this.currentFilter.ChallengePOST.forEach((element) => {
          this.advFilterChallengePOST.push({
            id: element,
            itemName: this.challengePOST.find((w: any) => w.id === element)
              .itemName,
          });
        });
      }

      // InclinationPRE
      if (
        this.currentFilter.InclinationPRE &&
        this.currentFilter.InclinationPRE.length > 0
      ) {
        this.advFilterInclinationPRE = [];
        this.currentFilter.InclinationPRE.forEach((element) => {
          this.advFilterInclinationPRE.push({
            id: element,
            itemName: this.inclinationPRE.find((w: any) => w.id === element)
              .itemName,
          });
        });
      }

      // InclinationPRE
      if (
        this.currentFilter.InclinationPOST &&
        this.currentFilter.InclinationPOST.length > 0
      ) {
        this.advFilterInclinationPOST = [];
        this.currentFilter.InclinationPOST.forEach((element) => {
          this.advFilterInclinationPOST.push({
            id: element,
            itemName: this.inclinationPOST.find((w: any) => w.id === element)
              .itemName,
          });
        });
      }

      // InclinationPRE
      if (
        this.currentFilter.SelfEvaluation &&
        this.currentFilter.SelfEvaluation.length > 0
      ) {
        this.advFilterSelfEvaluation = [];
        this.currentFilter.SelfEvaluation.forEach((element) => {
          this.advFilterSelfEvaluation.push({
            id: element,
            itemName: this.selfEvaluation.find((w: any) => w.id === element)
              .itemName,
          });
        });
      }

      // InclinationPRE
      if (
        this.currentFilter.BossEvaluation &&
        this.currentFilter.BossEvaluation.length > 0
      ) {
        this.advFilterBossEvaluation = [];
        this.currentFilter.BossEvaluation.forEach((element) => {
          this.advFilterBossEvaluation.push({
            id: element,
            itemName: this.bossEvaluation.find((w: any) => w.id === element)
              .itemName,
          });
        });
      }
    }

    // this.doughnutChartOptions = {
    //   responsive: true,
    //   maintainAspectRatio: false,
    //   animation: false,
    //   padding: {
    //     left: 0,
    //     right: 0,
    //     top: 0,
    //     bottom: 0
    //   },
    //   legend: {
    //     display: false
    //   },
    //   hover: {
    //     onHover: (event, active) => {
    //       //console.log('onHover', event, active);
    //     }
    //   }
    // }
    this.updateChartColor();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.cycle) {
      this.cycle = changes.cycle.currentValue;
    }
  }

  async updateChartColor(idx: number = -1) {
    if (this.myChart) {
      this.myChart.destroy();
    }

    this.doughnutChartColors = [
      {
        backgroundColor: [],
        borderColor: [],
        borderWidth: [],
        hoverBackgroundColor: [],
        hoverBorderColor: [],
        hoverBorderWidth: [],
      },
    ];

    if (idx < 0) {
      this.doughnutChartData = [];
      this.doughnutChartLabels = [];
    }

    // console.log(this.cycle.Details.PerCategory)

    for (
      let index = 0;
      index < this.cycle.Details.PerCategory.length;
      index++
    ) {
      const element = this.cycle.Details.PerCategory[index];
      let imageURL = this.getCategoryImg(element);
      let palette = await Vibrant.from(imageURL).getPalette();

      let hex =
        palette && palette.Vibrant ? palette.Vibrant.getHex() : '000000';
      if (!element.Icon) {
        hex =
          palette && palette.LightMuted
            ? palette.LightMuted.getHex()
            : '000000';
      }

      if (idx < 0) {
        this.doughnutChartData.push(element.Value.Total);
        this.doughnutChartLabels.push(
          element.Name
            ? element.Name
            : this.coreService.getTranslation('CYCLE_MANAGER.NoCategory')
        );
      }

      // Faccio un controllo su idx 1000 come escamotage per resettare il grafico alla condizione iniziale
      // senza re-inizializzarlo nuovamente.
      if (idx < 0 || idx === 1000 || (index >= 0 && index === idx)) {
        this.doughnutChartColors[0].backgroundColor.push(hex);
        this.doughnutChartColors[0].borderColor.push('#FFFFFF');
        this.doughnutChartColors[0].borderWidth.push(1);

        this.doughnutChartColors[0].hoverBackgroundColor.push(hex);
        this.doughnutChartColors[0].hoverBorderColor.push(hex);
        this.doughnutChartColors[0].hoverBorderWidth.push(5);
      } else {
        this.doughnutChartColors[0].backgroundColor.push(
          this.hexToRgb(hex, '.5')
        );
        this.doughnutChartColors[0].borderColor.push('#FFFFFF');
        this.doughnutChartColors[0].borderWidth.push(1);

        this.doughnutChartColors[0].hoverBackgroundColor.push(hex);
        this.doughnutChartColors[0].hoverBorderColor.push(hex);
        this.doughnutChartColors[0].hoverBorderWidth.push(5);
      }
    }

    // console.log(this.doughnutChartColors);

    // setTimeout(() => {

    //   // console.log(this.baseChart);
    //   if (!this.baseChart.chart) return;

    //   this.baseChart.chart.config.data.datasets[0].data = this.doughnutChartData;
    //   this.baseChart.chart.config.data.labels = this.doughnutChartLabels;
    //   this.baseChart.chart.config.data.datasets[0].backgroundColor = this.doughnutChartColors[0].backgroundColor;
    //   this.baseChart.chart.config.data.datasets[0].borderColor = this.doughnutChartColors[0].borderColor;
    //   this.baseChart.chart.config.data.datasets[0].borderWidth = this.doughnutChartColors[0].borderWidth;
    //   this.baseChart.chart.config.data.datasets[0].hoverBackgroundColor = this.doughnutChartColors[0].hoverBackgroundColor;
    //   this.baseChart.chart.config.data.datasets[0].hoverBorderColor = this.doughnutChartColors[0].hoverBorderColor;
    //   this.baseChart.chart.config.data.datasets[0].hoverBorderWidth = this.doughnutChartColors[0].hoverBorderWidth;
    //   this.baseChart.chart.update();

    //   //console.log(this.baseChart.chart.config.data);
    // }, 1500);

    let chartData = {
      labels: this.doughnutChartLabels,
      datasets: [
        {
          data: this.doughnutChartData,
          backgroundColor: this.doughnutChartColors[0].backgroundColor,
          borderColor: this.doughnutChartColors[0].borderColor,
          borderWidth: this.doughnutChartColors[0].borderWidth,
          hoverBackgroundColor:
            this.doughnutChartColors[0].hoverBackgroundColor,
          hoverBorderColor: this.doughnutChartColors[0].hoverBorderColor,
          hoverBorderWidth: this.doughnutChartColors[0].hoverBorderWidth,
        },
      ],
    };

    if (this.chart) {
      this.myChart = new Chart(this.chart.nativeElement, {
        type: 'doughnut',
        data: chartData,
        options: {
          onClick: (e, i) => {
            console.log(e);

            const index = i[0]['index'];

            // Se c'è già un filtro categoria attivo lo elimino
            if (
              this.cycle.Details.ActiveFilter['Categories'] &&
              this.cycle.Details.ActiveFilter['Categories'][0] ===
                this.cycle.Details.PerCategory[index].Id
            ) {
              this.cycle.Details.ActiveFilter.Categories = undefined;
              this.updateChartColor(-1);
            } else {
              this.triggerHoverEvent(index);
              this.cycle.Details.ActiveFilter['Categories'] = [
                this.cycle.Details.PerCategory[index].Id,
              ];
            }

            this.performFilter();
          },
          indexAxis: 'y',
          // scales: {
          // 	x: {
          // 		position: 'top',
          // 		grid: {
          // 			color: (context) => {
          // 				return context.tick.value == 0 ? 'rgba(255, 221, 69, 1)' : Chart.defaults.borderColor;
          // 			}
          // 		},
          // 		ticks: {
          // 			callback: (label, index, labels) => {
          // 				const today = moment();
          // 				const nextDate = today.add(label, 'days');

          // 				return nextDate.format('MMM YYYY');
          // 			}
          // 		}
          // 	},
          // 	y: {
          // 		ticks: {
          // 			padding: 30
          // 		}
          // 	}
          // },
          responsive: true,
          maintainAspectRatio: false,
          // interaction: {
          // 	mode: 'point',
          // 	intersect: false,
          // },
          plugins: {
            title: {
              display: false,
            },
            legend: {
              display: false,
            },
            // tooltip: {
            // 	events: ['click', 'mousemove'],
            // 	enabled: false,
            // 	padding: 10,
            // 	external: externalTooltipHandler
            // },
          },
          layout: {
            padding: {
              top: 10,
              bottom: 10,
              left: 10,
              right: 10,
            },
          },
        },
        // plugins: [{
        // 	afterDraw: (chart: any) => {
        // 		var ctx = chart.ctx;
        // 		var xAxis = chart.scales['x'];
        // 		var yAxis = chart.scales['y'];
        // 		yAxis.ticks.forEach((value, index) => {
        // 			if (chart.config.data.datasets[0].ModulesPictures[index]) {
        // 				var y = yAxis.getPixelForTick(index) - 10;
        // 				var x = xAxis.getPixelForTick(0) - 30; //0;
        // 				var radius = 10;
        // 				var width = 20;
        // 				var height = 20;
        // 				var image = new Image();
        // 				image.src = chart.config.data.datasets[0].ModulesPictures[index];

        // 				ctx.save();
        // 				ctx.beginPath();
        // 				ctx.moveTo(x + radius, y);
        // 				ctx.lineTo(x + width - radius, y);
        // 				ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
        // 				ctx.lineTo(x + width, y + height - radius);
        // 				ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
        // 				ctx.lineTo(x + radius, y + height);
        // 				ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
        // 				ctx.lineTo(x, y + radius);
        // 				ctx.quadraticCurveTo(x, y, x + radius, y);
        // 				ctx.closePath();
        // 				ctx.clip();

        // 				ctx.drawImage(image, x, y, width, height);
        // 				ctx.restore();
        // 			}
        // 		});

        // 	}
        // }]
      });
    }
  }

  ngAfterViewInit() {
    $('.statusBar').mouseenter((ev: any) => {
      $(ev.target)
        .parent()
        .children()
        .each((index, child) => {
          $(child).addClass('opacity_half');
        });

      $(ev.target).removeClass('opacity_half');
    });

    $('.statusBar').mouseleave((ev: any) => {
      $(ev.target)
        .parent()
        .children()
        .each((index, child) => {
          // Se non c'è un filtro attivo uguale rimuovo la classe
          if (
            this.cycle.Details.ActiveFilter.Status === 'None' ||
            this.cycle.Details.ActiveFilter.Status === $(child).data('status')
          ) {
            $(child).removeClass('opacity_half');
          }
        });
    });
  }

  getProportionalWidth(obj, label, status?) {
    let total = 0;
    if ((status = 'Evaluation')) {
      delete obj['NotApproved'];
    }
    for (let key of Object.keys(obj)) {
      total += obj[key] && obj[key].Total ? obj[key].Total : 0;
    }

    let width =
      obj[label] && obj[label].Total ? (obj[label].Total * 100) / total : 0;
    return width + '%';
  }

  onStatusBarClick(a, b) {}

  chartHovered(event: any): void {
    //console.log(event);
  }

  filterByVisibility(visibility: string) {
    this.cycle.Details.ActiveFilter['Visibility'] = visibility;
    this.performFilter();
  }

  filterByStatus(statusObject: any, statusField: string) {
    //console.log('filterByStatus', statusObject, statusField);
    this.cycle.Details.ActiveFilter['Status'] = statusField;
    this.performFilter();
  }

  // filterByCategory({ event, active }: { event: MouseEvent, active: {}[] }) {
  //   // console.log('filterByCategory', event, active);
  //   if (active && active.length > 0) {
  //     const index = active[0]['_index'];

  //     // Se c'è già un filtro categoria attivo lo elimino
  //     if (this.cycle.Details.ActiveFilter['Categories'] &&
  //       (this.cycle.Details.ActiveFilter['Categories'][0] === this.cycle.Details.PerCategory[index].Id)
  //     ) {
  //       this.cycle.Details.ActiveFilter.Categories = undefined;
  //       this.updateChartColor(-1);
  //     } else {
  //       this.triggerHoverEvent(index);
  //       this.cycle.Details.ActiveFilter['Categories'] = [this.cycle.Details.PerCategory[index].Id];
  //     }

  //     this.performFilter();
  //   }
  // }

  performFilter() {
    this.FilterChange.emit(this.cycle.Details.ActiveFilter);
  }

  getFilterColor() {
    let color: any;
    switch (this.cycle.Details.ActiveFilter.Status) {
      case 'Open':
      case 'ToDo':
      case 'NotEvaluated':
        color = { color: '#C9C9C9' };
        break;
      case 'AwaitingApproval':
      case 'NoBoss':
        color = { color: '#FFDB45' };
        break;
      case 'Set':
      case 'Completed':
      case 'Evaluated':
        color = { color: '#74BD32' };
        break;
      case 'WorkingOn':
      case 'InProgress':
      case 'NoSelf':
        color = { color: '#004D7F' };
        break;
      case 'SuspendedDeferred':
        color = { color: '#7D7E7E' };
        break;
      case 'NotApproved':
        color = { color: '#BC4749' };
        break;
    }
    return color;
  }

  getFilterName(status) {
    let key: any;
    switch (status) {
      case 'Open':
        key = 'INDIVIDUAL_OBJECTIVES.DEFINITION_STATE.COL_0.Name';
        break;
      case 'ToDo':
        key = 'INDIVIDUAL_OBJECTIVES.EXECUTION_STATE.COL_0.Name';
        break;
      case 'NotEvaluated':
        key = 'INDIVIDUAL_OBJECTIVES.EVALUATION_STATE.COL_0.Name';
        break;
      case 'AwaitingApproval':
        key = 'INDIVIDUAL_OBJECTIVES.DEFINITION_STATE.COL_1.Name';
        break;
      case 'NoBoss':
        key = 'INDIVIDUAL_OBJECTIVES.EVALUATION_STATE.NOBOSS.Name';
        break;
      case 'Set':
        key = 'INDIVIDUAL_OBJECTIVES.DEFINITION_STATE.COL_2.Name';
        break;
      case 'Completed':
        key = 'INDIVIDUAL_OBJECTIVES.EXECUTION_STATE.COL_2.Name';
        break;
      case 'Evaluated':
        key = 'INDIVIDUAL_OBJECTIVES.EVALUATION_STATE.COL_1.Name';
        break;
      case 'WorkingOn':
        break;
      case 'InProgress':
        key = 'INDIVIDUAL_OBJECTIVES.EXECUTION_STATE.COL_1.Name';
        break;
      case 'NoSelf':
        key = 'INDIVIDUAL_OBJECTIVES.EVALUATION_STATE.NOSELF.Name';
        break;
      case 'SuspendedDeferred':
        key = 'INDIVIDUAL_OBJECTIVES.EVALUATION_STATE.COL_2.Name';
        break;
      case 'NotApproved':
        key = 'INDIVIDUAL_OBJECTIVES.EXECUTION_STATE.COL_3.Name';
        break;
    }
    const text = this.translate.instant(key);
    return text;
  }

  getCategoryPictureById(categoryId: string) {
    return this.cycle.Details.PerCategory.find(
      (category) => category.Id === categoryId
    ).Icon;
  }

  getCategoryName(id) {
    let c = this.cycle.Details.PerCategory.find((x) => x.Id === id);
    if (c) {
      return c.Name;
    }
    return '';
  }

  async triggerHoverEvent(idx: number) {
    if (idx >= 0) {
      this.updateChartColor(idx);
    }
  }

  getCategoryImg(cat: any) {
    if (
      cat.Visibility === 'Private' &&
      (!cat.Author || cat.Author.Id !== this.userInfo.linkedEmployeeId)
    ) {
      return 'assets/images/category.gif';
    } else {
      return this.coreService.getImageURL(
        cat.Icon,
        'assets/images/category.gif'
      );
    }
  }

  clearSelection(modelName: string, filterName: string, optionName: string) {
    this[optionName].Values.forEach((e) => {
      e.isSelected = true;
    });
    this[modelName] = [{ Id: 'ALL', Name: 'All' }];
    this.setOptionsAll(filterName, optionName);
    this.performFilterAdv();
  }

  changeSelection(
    event: any,
    modelName: string,
    filterName: string,
    optionName: string
  ) {
    if (event.selectedValues && event.selectedValues.length) {
      const isAll =
        event.selectedValues.length === this[optionName].Values.length;
      if (isAll) {
        this[modelName] = [{ Id: 'ALL', Name: 'All' }];
        this.setOptionsAll(filterName, optionName);
      } else {
        this[modelName] = event.selectedValues.filter(
          (item: any) => item.Id !== 'ALL'
        );
      }
    } else {
      this[optionName].Values.forEach((e) => {
        e.isSelected = true;
      });
      this[modelName] = [{ Id: 'ALL', Name: 'All' }];
      this.setOptionsAll(filterName, optionName);
    }

    this.performFilterAdv();
  }
  advAddFilter(event: any, modelName: string) {
    //this[modelName] = this[modelName].filter((item: any) => item.id !== 'Missing');
    if (event.id !== 'ALL') {
      this[modelName] = this[modelName].filter(
        (item: any) => item.id !== 'ALL'
      );
    } else {
      this[modelName] = [{ id: 'ALL', itemName: 'All' }];
    }

    console.log(this.advFilterWeights);

    this.performFilterAdv();
  }

  advRemoveFilter(event: any, modelName: string) {
    if (this[modelName].length === 0) {
      this[modelName] = [{ id: 'ALL', itemName: 'All' }];
    }
    this.performFilterAdv();
  }

  isActiveFilter(modelName) {
    return (
      this[modelName] &&
      this[modelName].filter((f) => f.id !== 'ALL').length > 0
    );
  }

  performFilterAdv() {
    this.cycle.Details.ActiveFilter['Weights'] = [];
    this.advFilterWeights.forEach((item: any) => {
      this.cycle.Details.ActiveFilter['Weights'].push(item.Id);
    });

    this.cycle.Details.ActiveFilter['ChallengePRE'] = [];
    this.advFilterChallengePRE.forEach((item: any) => {
      this.cycle.Details.ActiveFilter['ChallengePRE'].push(item.Id);
    });

    this.cycle.Details.ActiveFilter['InclinationPRE'] = [];
    this.advFilterInclinationPRE.forEach((item: any) => {
      this.cycle.Details.ActiveFilter['InclinationPRE'].push(item.Id);
    });

    this.cycle.Details.ActiveFilter['ChallengePOST'] = [];
    this.advFilterChallengePOST.forEach((item: any) => {
      this.cycle.Details.ActiveFilter['ChallengePOST'].push(item.Id);
    });

    this.cycle.Details.ActiveFilter['InclinationPOST'] = [];
    this.advFilterInclinationPOST.forEach((item: any) => {
      this.cycle.Details.ActiveFilter['InclinationPOST'].push(item.Id);
    });

    this.cycle.Details.ActiveFilter['BossEvaluation'] = [];
    this.advFilterBossEvaluation.forEach((item: any) => {
      this.cycle.Details.ActiveFilter['BossEvaluation'].push(item.Id);
    });

    this.cycle.Details.ActiveFilter['SelfEvaluation'] = [];
    this.advFilterSelfEvaluation.forEach((item: any) => {
      this.cycle.Details.ActiveFilter['SelfEvaluation'].push(item.Id);
    });

    //console.log('performFilterAdv', this.cycle.Details.ActiveFilter);

    //console.log('performFilterAdv', JSON.stringify(this.cycle.Details.ActiveFilter));

    this.performFilter();
  }

  clearAllAdvFilter(performFilter: boolean = false) {
    // console.log(this.cycle.Details.ActiveFilter);

    this.cycle.Details.ActiveFilter.BossEvaluation = ['ALL'];
    this.cycle.Details.ActiveFilter.ChallengePRE = ['ALL'];
    this.cycle.Details.ActiveFilter.ChallengePOST = ['ALL'];
    this.cycle.Details.ActiveFilter.InclinationPRE = ['ALL'];
    this.cycle.Details.ActiveFilter.InclinationPOST = ['ALL'];
    this.cycle.Details.ActiveFilter.Weights = ['ALL'];
    this.cycle.Details.ActiveFilter.SelfEvaluation = ['ALL'];

    const option = [{ Id: 'ALL', Name: 'All' }];
    this.advFilterWeights = option;
    this.advFilterChallengePRE = option;
    this.advFilterInclinationPRE = option;
    this.advFilterChallengePOST = option;
    this.advFilterInclinationPOST = option;
    this.advFilterBossEvaluation = option;
    this.advFilterSelfEvaluation = option;

    this.setOptionsAll('defaultWeightFilterVal', 'weightOptions');
    this.setOptionsAll('defaultChallengePREFilterVal', 'challengePRE');
    this.setOptionsAll('defaultChallengePostFilterVal', 'challengePOST');
    this.setOptionsAll('defaultInclinationPREFilterVal', 'inclinationPRE');
    this.setOptionsAll('defaultInclinationPOSTFilterVal', 'inclinationPOST');
    this.setOptionsAll('defaultBossEvaluationFilterVal', 'bossEvaluation');
    this.setOptionsAll('defaultSelfEvaluationFilterVal', 'selfEvaluation');
    if (performFilter) this.performFilter();
  }

  onOpen(event: any) {
    //$('.list-area').css('min-width', '150px');
  }

  getMidNight(d) {
    let endDate = new Date(d);
    endDate = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate(),
      23,
      59
    );
    return endDate.getTime();
  }

  greaterThanCreationPeriod() {
    const now = new Date();
    const todayTime = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    ).getTime();
    let periodStart = new Date(
      this.cycle.CycleInfo.CreationPeriod.Start
    ).getTime();
    //let periodEnd=this.getMidNight(this.cycle.EndDate);
    return todayTime >= periodStart; //&& todayTime <= periodEnd;
  }

  greaterThanExecutionPeriod() {
    const now = new Date();
    const todayTime = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    ).getTime();
    let periodStart = new Date(this.cycle.CycleInfo.Period.Start).getTime();
    //let periodEnd=this.getMidNight(this.cycle.EndDate);
    return todayTime >= periodStart; //&& todayTime <= periodEnd;
  }

  greaterThanEvaluationPeriod() {
    const now = new Date();
    const todayTime = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    ).getTime();
    let periodStart = new Date(
      this.cycle.CycleInfo.EvaluationPeriod.Start
    ).getTime();
    //let periodEnd=this.getMidNight(this.cycle.EndDate);
    return todayTime >= periodStart; //&& todayTime <= periodEnd;
  }

  getObjNumber(v) {
    if (v) {
      if (v.Value === v.Total) return v.Total;
      else return v.Value + '/' + v.Total;
    } else return 0;
  }

  isAdvanceFilterVisible() {
    const isDefinition = this.currentStatus === 'Definition';
    const isAllCheck =
      this.cycle.EnableWheigthing ||
      this.cycle.EnableChallengeMeasure ||
      this.cycle.EnableLikenesses;
    return (isDefinition && isAllCheck) || !isDefinition;
  }

  isCheckAllFeature() {
    const isAllCheck =
      this.cycle.EnableWheigthing &&
      this.cycle.EnableChallengeMeasure &&
      this.cycle.EnableLikenesses;
    return isAllCheck;
  }
}
