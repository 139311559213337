import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  EventEmitter,
  Output,
} from '@angular/core';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { CoreService } from 'app/shared/services/core.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

import { DeviceDetectorService } from 'ngx-device-detector';
import { Keyboard } from '@ionic-native/keyboard/ngx';

@Component({
  selector: 'app-ios-autocomplete',
  templateUrl: './ios-autocomplete.component.html',
  styleUrls: [
    './ios-autocomplete.component.scss',
    '../../../common_styles.scss',
    '../common_popup_styles.scss',
  ],
  providers: [Keyboard],
})
export class IosAutocompleteComponent implements OnInit {
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
  @Output() getResponse = new EventEmitter();

  allDataList: any[] = [];
  allFilteredDataList: any[] = [];

  selectionMultiple: boolean = false;
  selectionViewType: string = 'EMPLOYEES_TEAMS';
  title: string;
  searchText: string;
  serachTextChanged: Subject<string> = new Subject<string>();

  throttle = 50;
  scrollDistance = 2;
  scrollUpDistance = 2;
  pageStart = 1;
  pageSize = 50;
  curentList = [];
  isMobile: boolean;

  constructor(
    public bsModalRef: BsModalRef,
    public coreService: CoreService,
    private ref: ChangeDetectorRef,
    private deviceService: DeviceDetectorService,
    private keyboard: Keyboard
  ) {
    this.serachTextChanged
      .pipe(
        debounceTime(500), // wait 500ms after the last event before emitting last event
        distinctUntilChanged()
      ) // only emit if value is different from previous value
      .subscribe((model) => {
        this.searchText = model;
        this.filter();
      });
  }

  ngOnInit() {
    this.getEmployees();
  }

  ngAfterViewChecked() {
    this.isMobile = this.deviceService.isMobile();
    if (!this.isMobile) {
      this.searchInput.nativeElement.focus();
      // } else {
      // this.keyboard.hideFormAccessoryBar(false);
    }
  }

  // toggleKeyboard() {
  //   if ( this.keyboard.isVisible ) {
  //     this.keyboard.hide();
  //   } else {
  //     this.keyboard.show();
  //   }
  // }

  onScrollDown() {
    this.pageStart++;
    this.getEmployees();
  }

  getEmployees() {
    let start = (this.pageStart - 1) * this.pageSize;
    let end = this.pageStart * this.pageSize;
    let pagedData = this.allFilteredDataList.slice(start, end);

    if (this.pageStart == 1) {
      this.curentList = pagedData;
    } else {
      this.curentList = this.curentList.concat(pagedData);
    }

    if (this.curentList.length > 0 && this.selectionMultiple) {
      this.curentList.sort((a: any, b: any) => {
        return a.selected ? -1 : 0;
      });
    }
  }

  searchChanged(text: string) {
    this.serachTextChanged.next(text);
  }

  setData(data: any) {
    console.log('setData', data);
    this.allDataList = JSON.parse(JSON.stringify(data));
    this.allFilteredDataList = JSON.parse(JSON.stringify(data));
    this.getEmployees();
  }

  setSelected(selectedTeams_Employees: any) {
    selectedTeams_Employees.forEach((element: any) => {
      let index = this.curentList.findIndex(
        (item: any) => item.Id === element.Id
      );
      if (index >= 0) {
        this.curentList[index]['selected'] = true;
      }
    });

    for (let key of Object.keys(selectedTeams_Employees)) {
      console.log(key);
    }

    if (this.curentList.length > 0 && this.selectionMultiple) {
      this.curentList.sort((a: any, b: any) => {
        return a.selected ? -1 : 0;
      });
    }
  }

  getCombinations(chars) {
    let combinations = [];
    chars.forEach((char, i) => {
      let word = '';
      this.buildWord(word + ' ' + char, [i], chars, combinations);
    });
    return combinations;
  }

  buildWord(word, usedIndexes, chars, combinations) {
    // if (word.trim().split(' ').length > 1) {
    combinations.push(word.trim());
    // }
    chars.forEach((char, i) => {
      if (usedIndexes.indexOf(i) === -1) {
        let newUsedIndexesArray = Array.from(usedIndexes);
        newUsedIndexesArray.push(i);
        this.buildWord(
          word + ' ' + char,
          newUsedIndexesArray,
          chars,
          combinations
        );
      }
    });
  }

  filter() {
    this.pageStart = 1;
    let search = this.searchText.toLowerCase();

    var combinations =
      search.split(' ').length > 1
        ? this.getCombinations(search.split(' '))
        : [search];
    console.log('combinations', combinations);

    this.allFilteredDataList = this.allDataList.filter((item: any) => {
      let matched: boolean = false;
      combinations.forEach((element: string) => {
        // Se inserisco 2 parole devo cercare le combinazioni con almeno 2 parole
        const lengthSearch = search.split(' ').length;
        const lengthCombination = element.split(' ').length;

        if (lengthCombination >= lengthSearch) {
          if (
            item.Name.toLowerCase().includes(element.toLowerCase()) ||
            item.Team.toLowerCase().includes(element.toLowerCase())
          ) {
            matched = true;
          }
        }
      });
      return matched;
    });
    this.ref.detectChanges();
    this.getEmployees();
  }

  SendToBack() {
    if (!this.almoustOneSelected()) return;
    this.getResponse.emit({
      selected: this.allFilteredDataList.filter((item: any) => item.selected),
      originalList: this.allDataList, //this.curentList
    });
    this.bsModalRef.hide();
  }

  almoustOneSelected() {
    return (
      this.allFilteredDataList.filter((item: any) => item.selected).length > 0
    );
  }

  deselectItem(item: any) {
    item['selected'] = false;
    this.curentList.forEach((employee: any) => {
      if (employee.Id === item.Id) {
        employee['selected'] = false;
      }
    });
    this.keyboard.hide();
  }

  onCancel() {
    this.bsModalRef.hide();
  }

  selectItem(item: any) {
    item['selected'] == true
      ? (item['selected'] = false)
      : (item['selected'] = true);
    this.keyboard.hide();
    if (!this.selectionMultiple) {
      this.unselectOthers(item.Id);
      this.SendToBack();
    } else {
      // salvo nella lista generale la selezione che ho fatto, così dopo il filter mi rimane selezionato.
      this.allDataList.forEach((rec: any) => {
        if (rec.Id === item.Id) {
          rec['selected'] = item.selected;
        }
      });

      // Riordino la lista in base al selezionato
      if (this.selectionMultiple) {
        this.allDataList.sort((a: any, b: any) => {
          return a.selected ? -1 : 0;
        });
      }

      this.searchText = '';
      this.searchChanged('');
    }
  }

  getSelected() {
    return this.allDataList.filter((item: any) => item.selected);
  }

  unselectOthers(id) {
    this.allDataList.forEach((e) => {
      if (e.selected && e.Id != id) e.selected = false;
    });
  }
}
