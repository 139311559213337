import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Route,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { CoreService } from '../services/core.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import 'moment/min/locales';
import { AuthService } from '../../auth/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    public service: AuthService,
    private router: Router,
    private coreService: CoreService,
    public translate: TranslateService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let role = this.coreService.getUserRole();
    let localUser = this.coreService.getUserInfo();
    let localToken = this.coreService.getUserToken();
    if (localUser && localToken) {
      var lang = localUser['language'];
      if (!lang) lang = 'en';
      let x = lang.match(/en|it|pl|nl|es|pt|de|ru|zh|fr/) ? lang : 'en';
      moment.locale(x);
      if (x === 'zh') {
        moment.locale(x + '-cn');
      }
      if (this.translate.currentLang != x) {
        this.translate.use(x).toPromise<void>();
      }
      this.service.redirectUser(localUser.PreferredRole);
    } else return true;
  }
}
