import { Injectable } from '@angular/core';
import { RestfulService } from '../../shared/services/restful.service';
import { CoreService } from '../../shared/services/core.service';
import { of } from 'rxjs';

@Injectable()
export class PatprizesService {
  constructor(private restfulWebService: RestfulService) {}

  getPatRounds(companyId) {
    return this.restfulWebService.get(
      `PatPrizeExt/GetRounds?companyId=${companyId}`
    );
  }

  getSingleRound(roundId: string) {
    return this.restfulWebService.get(
      `PatPrizeExt/GetSingleRound?roundId=${roundId}`
    );
  }

  sendRound(id: any) {
    return this.restfulWebService.post(
      `PatPrizeExt/SendRound?roundId=${id}`,
      null
    );
  }

  saveDraft(pp: any) {
    return this.restfulWebService.post(`PatPrizeExt/SaveDraft`, pp);
  }

  deleteDraft(roundId: string) {
    return this.restfulWebService.delete(
      `PatPrizeExt/deleteDraft?roundId=${roundId}`
    );
  }

  calculateRanking(roundId: string) {
    return this.restfulWebService.get(
      `PatPrizeExt/CalculateAllRanking?roundId=${roundId}`
    );
  }

  setClaim(winnerId: any, isSet: boolean) {
    return this.restfulWebService.put(
      `PatPrizes/setClaim/?winnerId=${winnerId}`,
      isSet
    );
  }

  //---------------------------------------------------------------------------------------------//
  getPatPrizesLibrary(companyId) {
    return this.restfulWebService.get(
      `PatPrizeExt/PrizeLib?companyId=${companyId}`
    );
  }

  savePrize(pp: any) {
    return this.restfulWebService.post(`PatPrizeExt/SavePrize`, pp);
  }

  updatePatPrizes(pp: any) {
    return this.restfulWebService.put(`PatPrizeExt/UpdatePrize`, pp);
  }

  deletePatPrizes(pp: any) {
    return this.restfulWebService.delete(
      `PatPrizeExt/DeletePrize?prizeId=${pp.Id}`
    );
  }
}
