import { Component, OnInit } from '@angular/core';
import { WallService } from '../wall.service';
import { CoreService } from 'app/shared/services/core.service';
import { SharedService } from 'app/shared/services/shared.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'mobile-event',
  templateUrl: './mobile-event.component.html',
  styleUrls: ['./mobile-event.component.scss'],
})
export class MobileEventComponent implements OnInit {
  public onClose: Subject<any>;
  event: any;
  item: any;

  constructor(
    public bsModalRef: BsModalRef,
    private modalRef: BsModalRef,
    public coreService: CoreService,
    private modalService: BsModalService,
    public sharedService: SharedService,
    private service: WallService
  ) {}

  ngOnInit(): void {
    this.onClose = new Subject();
    this.item = this.event;
  }
  onClosed() {
    document.getElementById('mobileContent').style.display = 'none';
    this.onClose.next(true);
    this.bsModalRef.hide();
    this.modalRef.hide();
  }
}
