/*
 * Inspinia js helpers:
 *
 * correctHeight() - fix the height of main wrapper
 * detectBody() - detect windows size
 * smoothlyMenu() - add smooth fade in/out on navigation show/ide
 *
 */
import { Pipe, PipeTransform } from '@angular/core';
//import { APPCONFIG } from './app.config';
import { ConfirmDialogComponent } from './shared/confirm-dialog/confirm-dialog.component';
declare var jQuery: any;

/*
export function replaceHeader(showIcons, showTitle,icon, title) {
  var config=APPCONFIG;
  config.showIcons=showIcons;
  config.showTitle=showTitle;
  config.pageTitle=title;
  config.pageIcon=icon;
}
*/

export function correctHeight() {
  var pageWrapper = jQuery('#page-wrapper');
  var navbarHeight = jQuery('nav.navbar-default').height();
  var wrapperHeight = pageWrapper.height();

  if (navbarHeight > wrapperHeight) {
    pageWrapper.css('min-height', navbarHeight + 'px');
  }

  if (navbarHeight <= wrapperHeight) {
    if (navbarHeight < jQuery(window).height()) {
      pageWrapper.css('min-height', jQuery(window).height() + 'px');
    } else {
      pageWrapper.css('min-height', navbarHeight + 'px');
    }
  }

  if (jQuery('body').hasClass('fixed-nav')) {
    if (navbarHeight > wrapperHeight) {
      pageWrapper.css('min-height', navbarHeight + 'px');
    } else {
      pageWrapper.css('min-height', jQuery(window).height() - 60 + 'px');
    }
  }
}

export function detectBody() {
  if (jQuery(document).width() < 769) {
    jQuery('body').addClass('body-small');
  } else {
    jQuery('body').removeClass('body-small');
  }
}

export function initScreen() {
  //console.log('initScreen', jQuery(document).width());
  if (jQuery(document).width() > 1000) {
    // jQuery("body").addClass("mini-navbar");
    // var moveableCnt= jQuery('#moveableContainer');
    // moveableCnt.addClass('moveRight')
  } else {
    jQuery('body').removeClass('mini-navbar');
  }
}

export function smoothlyMenu() {
  if (
    !jQuery('body').hasClass('mini-navbar') ||
    jQuery('body').hasClass('body-small')
  ) {
    // Hide menu in order to smoothly turn on when maximize menu
    jQuery('#side-menu').hide();
    // For smoothly turn on menu
    setTimeout(function () {
      jQuery('#side-menu').fadeIn(400);
    }, 200);
    jQuery('#moveableContainer').removeClass('moveRight');
  } else if (jQuery('body').hasClass('fixed-sidebar')) {
    jQuery('#side-menu').hide();
    setTimeout(function () {
      jQuery('#side-menu').fadeIn(400);
    }, 100);
    jQuery('#moveableContainer').removeClass('moveRight');
  } else {
    // Remove all inline style from jquery fadeIn function to reset menu state
    jQuery('#moveableContainer').addClass('moveRight');

    jQuery('#side-menu').removeAttr('style');
  }
}
