import { Injectable } from '@angular/core';
import { RestfulService } from '../../shared/services/restful.service';
import { CoreService } from '../../shared/services/core.service';

@Injectable()
export class UserService {
  constructor(
    private restfulWebService: RestfulService,
    private coreService: CoreService
  ) {}

  getUsers(pageStart, pageSize) {
    return this.restfulWebService.get(
      `Admin/Users?pageStart=${pageStart}&pageSize=${pageSize}`
    );
  }

  getUser(userId) {
    return this.restfulWebService.get(`Admin/User/${userId}`);
  }

  getLoggedInUserInfo(email) {
    return this.restfulWebService.get(`EmployeeExt/User?email=${email}`);
  }

  getLoggedInUserSettingsInfo() {
    return this.restfulWebService.get(`EmployeeExt/Settings`);
  }

  getAvailableThemes() {
    return this.restfulWebService.get(`Resource/Themes`);
  }

  setUserTheme(reqBody) {
    return this.restfulWebService.post(`EmployeeExt/Theme`, reqBody);
  }

  addUpdateUser(user) {
    if (!user.Id) return this.restfulWebService.post(`Admin/User`, user);
    else return this.restfulWebService.put(`Admin/User/${user.Id}`, user);
  }
  deleteUser(ID) {
    return this.restfulWebService.delete(`Admin/User/${ID}`);
  }

  invitionMail(reqBody) {
    return this.restfulWebService.post(`admin/SendVerificationMail`, reqBody);
  }

  ResetPassword(reqBody) {
    return this.restfulWebService.post(`admin/ResetPassword`, reqBody);
  }

  getLanguageJSON() {
    return this.restfulWebService.getJSON(
      'assets/json/ISO-639-1-language.json'
    );
  }

  userUploadLogo(userId, picture) {
    return this.restfulWebService.fileUpload(`Admin/User/${userId}`, picture);
  }

  loggedinUserUpdate(reqBody) {
    return this.restfulWebService.fileUpload(`EmployeeExt`, reqBody);
  }

  updateNotification(reqBody) {
    return this.restfulWebService.post(
      `EmployeeExt/NotificationConfig`,
      reqBody
    );
  }

  employeeExtUploadPicture(userId, picture) {
    return this.restfulWebService.fileUpload(
      `/EmployeeExt/Picture/${userId}`,
      picture
    );
  }

  deleteUploadPicture() {
    return this.restfulWebService.delete(`EmployeeExt/RemoveImage`);
  }

  getSystemLanguages() {
    return this.restfulWebService.get(`language`);
  }

  getAllCompanies() {
    return this.restfulWebService.get(`company`);
  }

  changePassword(reqBody) {
    return this.restfulWebService.post(`account/ChangePassword`, reqBody);
  }
}
