import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Pp360CheckboxComponent } from './pp360-checkbox.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [Pp360CheckboxComponent],
  imports: [CommonModule, FormsModule],
  exports: [Pp360CheckboxComponent],
})
export class Pp360CheckboxModule {}
