import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CoreService } from '../../services/core.service';

@Component({
  selector: 'app-table-foot',
  templateUrl: './table-foot.component.html',
  styleUrls: ['./table-foot.component.css'],
})
export class TableFootComponent implements OnInit, OnChanges {
  selectedValueRowsPerPage: number = 5;
  @Input() totalItems: number = 0;
  @Input() itemsPerPage: number = 0;
  @Input() offset: number;
  @Input() isProcessing: boolean = false;
  @Output() pageChanged = new EventEmitter<any>();
  @Output() rowsPerPageChanged = new EventEmitter<any>();

  rowsPerPageOptions = [5, 10, 15, 50, 100];
  currentPage = 1;

  firstText = 'First';
  previousText = 'Previuos';
  nextText = 'Next';
  lastText = 'lastText';

  constructor(public coreSevice: CoreService) {
    this.firstText = coreSevice.getTranslation('Generic.First');
    this.previousText = coreSevice.getTranslation('Generic.Previous');
    this.nextText = coreSevice.getTranslation('Generic.Next');
    this.lastText = coreSevice.getTranslation('Generic.Last');
  }

  ngOnInit() {
    this.selectedValueRowsPerPage = this.coreSevice.getRowsPerPage();
  }

  onPageChanged(event) {
    this.pageChanged.emit(event);
  }

  rowsPerPageOptionsChange(opt) {
    this.rowsPerPageChanged.emit(opt);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.offset) {
      this.offset = changes.offset.currentValue;
      if (changes.offset.currentValue === 0) {
        this.currentPage = 1;
      }
    }
  }
}
