import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Pp360AttachmentComponent } from './pp360-attachment.component';
import { LanguageSelectorModule } from 'app/components/common/language-selector/language-selector.module';
import { TranslateModule } from '@ngx-translate/core';
import { Pp360EditorModule } from '../pp360-editor/pp360-editor.module';
import { Pp360CheckboxModule } from '../pp360-checkbox/pp360-checkbox.module';

@NgModule({
  declarations: [Pp360AttachmentComponent],
  imports: [
    LanguageSelectorModule,
    CommonModule,
    TranslateModule,
    Pp360EditorModule,
    Pp360CheckboxModule,
  ],
  exports: [Pp360AttachmentComponent, Pp360EditorModule],
})
export class Pp360AttachmentModule {}
