import { Component, OnInit, AfterContentChecked } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UserService } from '../../../super-admin/user/user.service';
import { CoreService } from 'app/shared/services/core.service';
import { AppConfigService } from 'app/app-config.service';

@Component({
  selector: 'app-admin-profile-change',
  templateUrl: './admin-profile-change.component.html',
  styleUrls: ['./admin-profile-change.component.scss'],
})
export class AdminProfileChangeComponent implements OnInit {
  isAfterContentChecked = false;
  refUser: any;
  isProcessing: boolean = false;
  userInfo: any;
  imageProcessing: boolean = false;
  isActionProcessing: boolean = false;
  CurrentUser: any;
  constructor(
    public bsModalRef: BsModalRef,
    public service: UserService,
    public coreService: CoreService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.CurrentUser = this.coreService.getUserInfo();
  }

  ngAfterContentChecked() {
    if (this.bsModalRef['content'] && !this.isAfterContentChecked) {
      if (this.bsModalRef['content'].userInfo) {
        this.refUser = this.bsModalRef['content'].userInfo;
        console.log(
          'this.userInfo==',
          this.userInfo,
          'picture---',
          this.userInfo.Picture
        );
        this.userInfo.Picture = this.refUser.imageUrl;
        this.getUserDetails();
      }
      this.isAfterContentChecked = true;
    }
  }

  getUserDetails() {
    this.isProcessing = true;
    this.service.getLoggedInUserInfo(this.refUser.email).subscribe(
      (data: any) => {
        this.userInfo = data || {};
        this.isProcessing = false;
      },
      (err: any) => {
        this.isProcessing = false;
        this.coreService.toasterMessage('error', 'user', err.Message);
      }
    );
  }

  onFileUplaod(event) {
    this.imageProcessing = true;
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      let formData: FormData = new FormData();
      formData.append('uploadFile', file, file.name);
      this.service
        .employeeExtUploadPicture(this.userInfo.Id, formData)
        .subscribe(
          (data: any) => {
            this.userInfo.Picture = data.Picture + '?random+=' + Math.random();
            this.refUser.imageUrl = data.Picture + '?random+=' + Math.random();
            this.imageProcessing = false;
          },
          (err: any) => {
            this.imageProcessing = false;
            this.coreService.toasterMessage('error', 'company', err.Message);
          }
        );
    }
  }

  onSubmit(form) {
    if (form.form.valid) {
      this.isActionProcessing = true;
      this.service.loggedinUserUpdate(this.userInfo).subscribe(
        (data: any) => {
          this.isActionProcessing = false;
          this.refUser.fullName = this.coreService.getFullName(
            data.FirstName,
            data.SecondName
          );
          this.coreService.toasterMessage(
            'success',
            'company',
            this.coreService.getTranslation('Messages.ProfileUpdate')
          );
          this.bsModalRef.hide();
        },
        (err: any) => {
          this.isActionProcessing = false;
          this.coreService.toasterMessage('error', 'company', err.message);
        }
      );
    }
  }
}
