import { Injectable } from '@angular/core';
import { RestfulService } from '../shared/services/restful.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { CoreService } from '../shared/services/core.service';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { navigationService } from 'app/shared/services/navigation.service';

import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ScreenSizeService } from 'app/shared/services/screen-size.service';
import { protectedResources } from './auth.config';

export interface GoogleUserRequest {
  idToken: string;
}
export interface UserToken {
  userId: string;
  expires: Date;
  email: string;
  access_token: string;
}
@Injectable()
export class AuthService {
  public availableToken = 0;
  public isMobile: boolean = false;
  url = protectedResources.apiPatPatADAuthenticate.endpoint;
  constructor(
    private restfulWebService: RestfulService,
    private coreService: CoreService,
    private httpClient: HttpClient,
    private translate: TranslateService,
    private navService: navigationService,
    public screenSizeService: ScreenSizeService,
    private router: Router
  ) {
    this.screenSizeService.ScreenSize.subscribe((res: number) => {
      this.isMobile = res <= 991;
    });
  }

  getData(lang?: string) {
    let browserLanguage = this.translate.getBrowserLang();
    browserLanguage = browserLanguage.match(/en|it|pl|nl|es|pt|de|ru|fr|zh/)
      ? browserLanguage
      : 'en';
    let url = `Resource/LoginSlides?themeId=1&language=${
      lang ? lang : browserLanguage
    }`;
    return this.restfulWebService.get(url);
  }

  login(user) {
    return this.restfulWebService.post(`Account/GetToken`, user);
  }

  firtLogin(reqBody) {
    return this.restfulWebService.post(`admin/setpassword`, reqBody);
  }

  forgot(user) {
    return this.restfulWebService.post(`Admin/Forgot`, user);
  }

  getLanguages() {
    return this.restfulWebService.get(`language`);
  }

  setUserToken(data) {
    return this.coreService.SetAuthToken(data);
  }

  googleLogin(googleUser: GoogleUserRequest): Observable<UserToken> {
    let email = googleUser['email'];
    return this.httpClient
      .post<UserToken>(
        `${environment.serverURI}/api/Account/GoogleAuthenticate`,
        googleUser
      )
      .pipe(
        map((profile: any) => {
          this.coreService.SetAuthToken(profile);
          this.router.navigate(['User/wall/feed']);
          if (profile.roles != 'User') this.getLanguages();
          var userRoles = [`${this.coreService.getUserRole()}`];
          const lang = profile.language;
          let x = lang.match(/en|it|pl|nl|es|pt|de|ru|zh|fr/) ? lang : 'en';
          moment.locale(x);
          if (this.translate.currentLang != x) {
            this.translate.use(x).toPromise<void>();
          }

          this.navService.setupMenus();
          return profile;
        }),
        catchError((err: HttpErrorResponse) => {
          this.coreService.toasterMessage(
            'error',
            'auth',
            `Google user ${email} does not exist in PatPat360`
          );
          throw err;
        })
      );
  }

  getAvailableToken() {
    let user: any;
    user = this.coreService.getUserInfo();
    this.availableToken = user.availableTokens;
    return this.availableToken;
  }

  setLanguages(data) {
    this.coreService.setLanguages(data);
  }

  redirectUser(role: string) {
    let userInfo = this.coreService.getUserInfo();
    if (this.isMobile) {
      this.savePrefferedRole('User').subscribe(() => {
        this.coreService.setPreferredRole('User');
        this.router.navigate(['User/wall/feed']);
      });
    } else {
      if (role === 'User') {
        this.router.navigate(['User/wall/feed']);
      } else if (role === 'CompanyAdmin') {
        this.router.navigate(['/TileMenu/menu/company-admin']);
      } else if (role === 'SuperAdmin') {
        this.router.navigate(['/TileMenu/menu/global']);
      } else if (role === 'LocalAdmin') {
        if (
          userInfo.belongingCompanyId === '6ce7fc9b-c438-4d15-aabb-1b3565435bd6'
        ) {
          this.router.navigate(['/Analytics/localadmin']);
        } else {
          this.router.navigate(['/TileMenu/menu/local-admin']);
        }
      } else if (role === 'Manager') {
        this.router.navigate(['/Manager/dashboard']);
      } else {
        this.router.navigate(['choose-login']);
      }
    }
  }

  getNextURL(role: string) {
    let userInfo = this.coreService.getUserInfo();
    if (role === 'User') {
      return '/#/User/wall/feed';
    } else if (role === 'CompanyAdmin') {
      return '/#/TileMenu/menu/company-admin';
    } else if (role === 'SuperAdmin') {
      return '/#/TileMenu/menu/global';
    } else if (role === 'LocalAdmin') {
      if (
        userInfo.belongingCompanyId === '6ce7fc9b-c438-4d15-aabb-1b3565435bd6'
      ) {
        return '/#/Analytics/localadmin';
      } else {
        return '/#/TileMenu/menu/local-admin';
      }
    } else if (role === 'Manager') {
      return '/#/TileMenu/menu/team-administration';
    } else {
      return 'choose-login';
    }
  }

  getCompanyLogo(companyId: string) {
    return this.restfulWebService.get(
      `Account/CompanyLogo?companyId=${companyId}`
    );
  }
  getCompanyStyle(companyId: string) {
    return this.restfulWebService.get(
      `Account/CompanyStyle?companyId=${companyId}`
    );
  }
  savePrefferedRole(role: string) {
    return this.restfulWebService.put(
      `Account/SaveRolePreference?role=${role}`,
      {}
    );
  }

  getToken() {
    return this.restfulWebService.get(this.url);
  }
}
