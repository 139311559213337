//import { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RecipientComponent } from './recipient/recipient.component';

const routes: Routes = [
  {
    path: 'recipient',
    children: [{ path: ':id', component: RecipientComponent }],
  },
];

//export const PartecipantLTIRouting: ModuleWithProviders = RouterModule.forChild(routes);
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class PartecipantLTIRouting {}
