import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { SharedService } from '../services/shared.service';
import * as moment from 'moment';
import { CoreService } from '../../shared/services/core.service';
//import { EventEmitter } from 'protractor';

@Component({
  selector: 'app-employee-dialog',
  templateUrl: './employee-dialog.component.html',
  styleUrls: [
    './employee-dialog.component.scss',
    './../../magic-checkbox.scss',
    './../../common_styles.scss',
  ],
})
export class EmployeeDialogComponent implements OnInit {
  @Input() public selectedEmployees: any[];
  @Output() selectionChanged = new EventEmitter();
  @Output() onSelectAll = new EventEmitter();

  @Input() public hideLastDate: boolean;
  @Input() public hideManager: boolean;

  public onClose: Subject<any>;
  public content: any = {};
  public sAllList: any;

  public viewMode: string = 'list';

  public tree: TeamsModel[] = [];
  public treeFiltered: TeamsModel[] = [];
  public list: ListModel[] = [];
  public listFiltered: ListModel[] = [];

  //public selected: string[] = [];
  public selected: any[] = [];
  public readonly: boolean = false;
  public showCheckBoxOnlySelected: boolean = false;

  private currentUserInfo: any;

  teams: any[] = [];
  teams2: any[] = [];
  filterTeamDropdownSettings: any = {};

  // Filters
  filterName: string;
  filterRole: string;
  filterTeam: any[];
  filterManager: string;
  filterEvaluator: string;
  filterLastCycle: string;
  filterInCompanySince: string;
  filterOnlySelected: boolean = false;
  validIds: any = undefined;

  dpConfig: any = {
    dateInputFormat: 'DD/MM/YYYY',
    selectFromOtherMonth: true,
    containerClass: 'magda_datepicker_theme',
    //customTodayClass: 'dpToday',
    //dateCustomClasses: 'dpDate'
  };

  public constructor(
    public _bsModalRef: BsModalRef,
    private srv: SharedService,
    public coreService: CoreService
  ) {}

  public ngOnInit(): void {
    this.onClose = new Subject();

    this.currentUserInfo = this.coreService.getUserInfo();

    if (
      this.currentUserInfo.roles.indexOf('SuperAdmin') >= 0 ||
      this.currentUserInfo.roles.indexOf('CompanyAdmin') >= 0 ||
      this.currentUserInfo.isManager === true ||
      this.currentUserInfo.isEvaluator === true
    ) {
      this.showCheckBoxOnlySelected = true;
    }

    this.srv.getEmployeesTree().subscribe((result: any) => {
      this.tree = this.filterTreeNodes(result.HeadTeams);

      this.treeFiltered = JSON.parse(JSON.stringify(this.tree));

      this.treeFiltered.forEach((element: any) => {
        this.toggleExpansionRecursively(element);
        this.togglePrechecked(element);
      });
    });

    this.srv.getEmployees().subscribe((result: any) => {
      if (result) {
        result.forEach((element) => {
          if (element.Role === '' || element.Role === null) {
            element.Role = this.coreService.getTranslation(
              'COMPANY_PERFORMANCE_REVIEW.FILTER_COLUMN.NONE'
            );
          }
          if (element.Team === '' || element.Team === null) {
            element.Team = this.coreService.getTranslation(
              'COMPANY_PERFORMANCE_REVIEW.FILTER_COLUMN.NONE'
            );
          }
        });
      }
      if (!this.validIds) {
        this.list = result;
      } else {
        this.list = this.filterNodes(result);
      }
      this.listFiltered = this.list;
      this.listFiltered.sort((a, b) => a.Name.localeCompare(b.Name));
      let teamMap = {};
      this.list.forEach((element: any) => {
        if (
          this.selectedEmployees &&
          this.selectedEmployees.find((x) => x.Id == element.Id)
        ) {
          this.checkNode(element);
        }
        if (!teamMap[element.TeamId]) {
          this.teams.push({
            id: element.TeamId,
            itemName: element.Team,
          });
          teamMap[element.TeamId] = true;
        }
      });

      this.teams?.forEach((element) => {
        if (element.id === '' || element.id === null) {
          element.itemName = this.coreService.getTranslation(
            'COMPANY_PERFORMANCE_REVIEW.FILTER_COLUMN.NONE'
          );
        }
      });

      this.teams.sort((a, b) =>
        a.itemName > b.itemName ? 1 : b.itemName > a.itemName ? -1 : 0
      );

      this.teams.splice(0, 0, {
        id: 'ALL',
        itemName: this.coreService.getTranslation('OKR_NETWORKS.FILTERS.All'),
      });

      this.teams2 = [...this.teams];

      this.filterTeam = [
        {
          id: 'ALL',
          itemName: this.coreService.getTranslation('OKR_NETWORKS.FILTERS.All'),
        },
      ];
    });

    this.filterTeamDropdownSettings = {
      singleSelection: false,
      text: 'Team name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: false,
      classes: 'multiselectDropdown',
      showCheckbox: true,
      enableCheckAll: false,
      autoPosition: false,
    };
  }

  onOpen() {
    $('.list-area').css('min-width', '300px');
    $('.list-area').css('background-color', 'var(--background2)');
    $('.list-area').css('color', 'var(--background2-fontcolor)');
  }

  isValidDate(dt) {
    if (dt) {
      let date: Date = new Date(dt);
      return date.getFullYear() > 2010;
    }
    return false;
  }

  isModal: boolean = false;
  public showModal(content: any): void {
    this.isModal = true;
    this.content = content;
    this.selected = JSON.parse(JSON.stringify(content.selected));

    this.readonly = content.readonly;
    if (content.externalFilter) {
      this.validIds = {};
      content.externalFilter.forEach((e: any) => {
        this.validIds[e.Id] = 'OK';
      });
    }
  }

  public onConfirm(): void {
    this.onClose.next({
      success: true,
      selected: this.selected,
    });
    this.confirmSelected();
    this._bsModalRef.hide();
  }

  public onCancel(): void {
    this.onClose.next({
      success: false,
    });
    this._bsModalRef.hide();
  }

  private togglePrechecked(node: any) {
    if (node.Employees) {
      node.Employees.forEach((element: any) => {
        this.togglePrechecked(element);
      });
    }

    if (node.Subteams) {
      node.Subteams.forEach((element: any) => {
        this.togglePrechecked(element);
      });
    }

    if (node.IsEvaluator !== undefined || node.IsManager !== undefined) {
      //is not a team

      if (this.selected.find((selected: any) => selected.Id === node.Id)) {
        this.checkNode(node);
      }
      //if (this.selected.indexOf(node.Id) < 0 ) this.selected.push(node.Id);
    }
  }

  public selectAll() {
    if (this.viewMode == 'tree') {
      this.tree.forEach((element: any) => {
        this.checkNode(element);
      });
    } else {
      this.list.forEach((element: any) => {
        this.checkNode(element);
      });
    }
    this.onSelectAll.emit(true);
  }

  public selectAllFiltered() {
    if (this.viewMode == 'tree') {
      this.treeFiltered.forEach((element: any) => {
        this.checkNode(element);
      });
    } else {
      this.listFiltered.forEach((element: any) => {
        this.checkNode(element);
      });
    }
    this.onSelectAll.emit(true);
  }

  public deselectAllFiltered() {
    if (this.viewMode == 'tree') {
      this.treeFiltered.forEach((element: any) => {
        this.uncheckNode(element);
      });
    } else {
      this.listFiltered.forEach((element: any) => {
        this.uncheckNode(element);
      });
    }
    this.onSelectAll.emit(false);
  }

  public getNumSelected(node) {
    let n = 0;
    n = node.Employees.filter((e: any) =>
      this.selected.find((selected: any) => selected.Id === e.Id)
    ).length;

    node.Subteams.forEach((element: any) => {
      n += this.getNumSelected(element);
    });
    return n;
  }

  public count(node) {
    let n = 0;
    n = node.Employees.length;

    node.Subteams.forEach((element: any) => {
      n += this.count(element);
    });
    return n;
  }

  public isSelectedAll() {
    let checked = true;

    if (this.viewMode == 'tree') {
      let i = 0;
      while (i < this.tree.length && this.isChecked(this.tree[i])) i++;

      checked = i >= this.tree.length;
    } else {
      let i = 0;
      while (i < this.list.length && this.isChecked(this.list[i])) i++;

      checked = i >= this.list.length;
    }

    return checked;
  }

  public isSelectedAllFiltered() {
    let checked = true;

    if (this.viewMode == 'tree') {
      let i = 0;
      while (
        i < this.treeFiltered.length &&
        this.isChecked(this.treeFiltered[i])
      )
        i++;

      checked = i >= this.treeFiltered.length;
    } else {
      let i = 0;
      while (
        i < this.listFiltered.length &&
        this.isChecked(this.listFiltered[i])
      )
        i++;

      checked = i >= this.listFiltered.length;
    }

    return checked;
  }

  private toggleExpansionNode(node: any) {
    node.expanded = !node.expanded;
  }

  filterTreeNodes(headTeams) {
    let n = 0;
    headTeams.forEach((ht) => {
      n = this.filterTeams(ht);
    });
    return headTeams;
  }

  filterTeams(ht) {
    let n = 0;
    let tot = 0;
    if (ht.Employees && ht.Employees.length > 0) {
      let employeesArray: any[] = [];
      ht.Employees.forEach((e: any) => {
        if (!this.validIds || this.validIds[e.Id]) {
          employeesArray.push(e);
        }
      });
      ht.Employees = employeesArray;
      tot += employeesArray.length;
    }

    if (ht.Subteams && ht.Subteams.length > 0) {
      let teamsArray: any[] = [];
      ht.Subteams.forEach((st) => {
        n = this.filterTeams(st);
        if (n > 0) {
          teamsArray.push(st);
        }
        tot += n;
      });
      ht.Subteams = teamsArray;
    }

    return tot;
  }

  private toggleExpansionRecursively(node: any) {
    node.expanded = !node.expanded;

    if (node.Subteams) {
      node.Subteams.forEach((element: any) => {
        this.toggleExpansionRecursively(element);
      });
    }
  }

  private toggleCheckNode(node: any) {
    if (this.isChecked(node)) {
      this.uncheckNode(node);

      if (this.filterOnlySelected) {
        this.filterEmployees();
      }
    } else {
      this.checkNode(node);
    }
  }

  private checkNode(node: any) {
    if (node.Employees) {
      node.Employees.forEach((element: any) => {
        this.checkNode(element);
      });
    }

    if (node.Subteams) {
      node.Subteams.forEach((element: any) => {
        this.checkNode(element);
      });
    }

    if (node.IsEvaluator !== undefined || node.IsManager !== undefined) {
      //is not a team

      const alreadyExists = this.selected.find(
        (selected: any) => selected.Id === node.Id
      );
      if (!alreadyExists) {
        this.selected.push(node);
        node.IsSelected = true;
      }
    }

    if (!this.isModal) {
      this.selectionChanged.emit({
        selectedItems: this.selected,
      });
    }
  }

  confirmSelected() {
    this.selectionChanged.emit({
      selectedItems: this.selected,
    });
  }

  private uncheckNode(node: any) {
    if (node.Employees) {
      node.Employees.forEach((element: any) => {
        this.uncheckNode(element);
      });
    }

    if (node.Subteams) {
      node.Subteams.forEach((element: any) => {
        this.uncheckNode(element);
      });
    }

    if (node.IsEvaluator !== undefined || node.IsManager !== undefined) {
      //is not a team
      this.selected = this.selected.filter(
        (selected: any) => selected.Id !== node.Id
      );
      node.IsSelected = false;
    }
    if (!this.isModal) {
      this.selectionChanged.emit({
        selectedItems: this.selected,
      });
    }
  }

  private hasChildren(node: any) {
    return (
      (node.Employees && node.Employees.length > 0) ||
      (node.Subteams && node.Subteams.length > 0)
    );
  }

  private isChecked(element: any): boolean {
    let node =
      this.viewMode == 'list'
        ? element
        : this.searchTreeNode(this.treeFiltered, element.$id);

    let checked =
      node.Employees || node.Subteams
        ? true
        : this.selected.find((selected: any) => selected.Id === node.Id);

    if (checked && node.Employees && node.Employees.length > 0) {
      let i = 0;
      while (i < node.Employees.length && this.isChecked(node.Employees[i]))
        i++;

      checked = i >= node.Employees.length;
    }

    if (checked && node.Subteams && node.Subteams.length > 0) {
      let i = 0;
      while (i < node.Subteams.length && this.isChecked(node.Subteams[i])) i++;

      checked = i >= node.Subteams.length;
    }

    return checked;
  }

  private filterNodes(allList) {
    let filteredList = [];

    allList.forEach((e: any) => {
      if (this.validIds[e.Id]) {
        filteredList.push(e);
      }
    });
    return filteredList;
  }

  private isPartiallyChecked(node: any) {
    let atLeastOneChecked = false;
    let allChecked = true;

    if (node.Employees) {
      let atLeastOneIndeterminate = false;
      node.Employees.forEach((element: any) => {
        allChecked = allChecked && this.isChecked(element);
        atLeastOneChecked = atLeastOneChecked || this.isChecked(element);
      });

      node.indeterminate = !allChecked && atLeastOneChecked;
    }

    if (!node.indeterminate && node.Subteams) {
      let atLeastOneIndeterminate = false;
      node.Subteams.forEach((element: any) => {
        allChecked = allChecked && this.isChecked(element);
        atLeastOneChecked = atLeastOneChecked || this.isChecked(element);
        atLeastOneIndeterminate =
          atLeastOneIndeterminate || element.indeterminate;
      });

      node.indeterminate =
        (!allChecked && atLeastOneChecked) || atLeastOneIndeterminate;
    }

    return node.indeterminate;
  }

  private searchTreeNode(parent: any, id: any) {
    let node = undefined;

    for (const item of parent) {
      if (item.$id == id) {
        node = item;
      }

      if (!node && item.Employees) {
        node = this.searchTreeNode(item.Employees, id);
      }

      if (!node && item.Subteams) {
        node = this.searchTreeNode(item.Subteams, id);
      }
    }
    return node;
  }

  public filterEmployees(value: any = undefined, ngModel: string = undefined) {
    if (ngModel && ngModel !== 'filterTeam') {
      this[ngModel] = value;
    } else if (ngModel === 'filterTeam') {
      if (value.id !== 'ALL') {
        this[ngModel] = this[ngModel].filter((item: any) => item.id !== 'ALL');
      } else {
        this[ngModel] = [
          {
            id: 'ALL',
            itemName: this.coreService.getTranslation(
              'OKR_NETWORKS.FILTERS.All'
            ),
          },
        ];
      }
    }

    if (this.viewMode == 'list') {
      const noFiltering =
        !this.filterName &&
        !this.filterRole &&
        !this.filterTeam &&
        !this.filterManager &&
        !this.filterEvaluator &&
        !this.filterLastCycle &&
        !this.filterInCompanySince &&
        !this.filterOnlySelected;

      this.listFiltered = JSON.parse(JSON.stringify(this.list));

      if (noFiltering) return;

      if (this.filterOnlySelected)
        this.listFiltered = this.listFiltered.filter((e: any) =>
          this.selected.find((selected: any) => selected.Id === e.Id)
        );

      if (this.filterName && this.filterName.length > 0)
        this.listFiltered = this.listFiltered.filter((employee: any) =>
          employee.Name
            ? employee.Name.toLowerCase().includes(
                this.filterName.toLowerCase()
              )
            : false
        );
      if (this.filterTeam && this.filterTeam.length > 0)
        this.listFiltered = this.listFiltered.filter((employee: any) => {
          return this.filterTeam.find(
            (element: any) =>
              element.itemName === employee.Team || element.id == 'ALL'
          )
            ? true
            : false;
        });
      if (this.filterRole && this.filterRole.length > 0)
        this.listFiltered = this.listFiltered.filter((employee: any) =>
          employee.Role
            ? employee.Role.toLowerCase().includes(
                this.filterRole.toLowerCase()
              )
            : false
        );
      if (this.filterManager && this.filterManager.length > 0)
        this.listFiltered = this.listFiltered.filter((employee: any) =>
          employee.Manager
            ? employee.Manager.toLowerCase().includes(
                this.filterManager.toLowerCase()
              )
            : false
        );
      if (this.filterEvaluator && this.filterEvaluator.length > 0)
        this.listFiltered = this.listFiltered.filter((employee: any) =>
          employee.Evaluator
            ? employee.Evaluator.toLowerCase().includes(
                this.filterEvaluator.toLowerCase()
              )
            : false
        );
      if (this.filterLastCycle && moment(this.filterLastCycle).isValid()) {
        this.listFiltered = this.listFiltered.filter((employee: any) =>
          employee.LastCycle
            ? moment(employee.LastCycle).isBefore(
                moment(this.filterLastCycle).format('DD/MM/YYYY')
              )
            : false
        );
      }
      if (
        this.filterInCompanySince &&
        moment(this.filterInCompanySince).isValid()
      ) {
        this.listFiltered = this.listFiltered.filter((employee: any) =>
          employee.InCompanySince
            ? moment(employee.InCompanySince)
                .format('DD/MM/YYYY')
                .includes(
                  moment(this.filterInCompanySince).format('DD/MM/YYYY')
                )
            : false
        );
      }
    } else {
      const noFiltering = !this.filterName && !this.filterOnlySelected;

      this.treeFiltered = JSON.parse(JSON.stringify(this.tree));

      if (noFiltering) return;

      if (this.filterOnlySelected)
        this.treeFiltered = this.filterTreeBySelected(this.treeFiltered);

      if (this.filterName)
        this.treeFiltered = this.filterTreeByName(
          this.treeFiltered,
          this.filterName.toLowerCase()
        );
    }
  }

  private filterTreeBySelected(node: any) {
    var r = node.filter((o: any) => {
      if (o.Employees) o.Employees = this.filterTreeBySelected(o.Employees);
      if (o.Subteams) o.Subteams = this.filterTreeBySelected(o.Subteams);

      var ret = this.selected.find((selected: any) => selected.Id === o.Id);
      ret = ret || (o.Employees && o.Employees.length > 0);
      ret = ret || (o.Subteams && o.Subteams.length > 0);

      return ret;
    });
    return r;
  }

  private filterTreeByName(node: any, name: string) {
    var r = node.filter((o: any) => {
      if (o.Employees) o.Employees = this.filterTreeByName(o.Employees, name);
      if (o.Subteams) o.Subteams = this.filterTreeByName(o.Subteams, name);

      var ret = o.Name.toLowerCase().includes(name);
      ret = ret || (o.Employees && o.Employees.length > 0);
      ret = ret || (o.Subteams && o.Subteams.length > 0);
      return ret;
    });
    return r;
  }

  public clearFilters() {
    this.filterName = undefined;
    this.filterTeam = [
      {
        id: 'ALL',
        itemName: this.coreService.getTranslation('OKR_NETWORKS.FILTERS.All'),
      },
    ];
    this.filterRole = undefined;
    this.filterManager = undefined;
    this.filterEvaluator = undefined;
    this.filterLastCycle = undefined;
    this.filterInCompanySince = undefined;

    this.filterEmployees();
  }

  checkSelectAllFiltered() {
    this.isSelectedAllFiltered()
      ? !this.readonly && this.deselectAllFiltered()
      : !this.readonly && this.selectAllFiltered();
  }
}

class TeamsModel {
  public Id: string;
  public Employees: EmployeeModel[];
  public Name: string;
  public Picture: number;
  public Subteams?: TeamsModel[];
}

class EmployeeModel {
  public Id: string;
  public IsEvaluator: boolean;
  public IsManager: boolean;
  public Name: string;
  public Picture: number;
}

class ListModel {
  public Evaluator: string;
  public Id: string;
  public InCompanySince: string;
  public IsEvaluator: boolean;
  public IsManager: boolean;
  public LastCycle: string;
  public Manager: string;
  public Name: string;
  public Picture: number;
  public Role: string;
  public Team: string;
  public TeamId: string;
}
