import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { initScreen } from 'app/app.helpers';
import { ScreenSizeService } from '../../shared/services/screen-size.service';
import { CoreService } from 'app/shared/services/core.service';
import { companyService } from 'app/super-admin/company/company.service';

@Component({
  selector: 'manager-preview',
  templateUrl: './manager-preview.component.html',
  styleUrls: ['./manager-preview.component.scss'],
})
export class ManagerPreviewComponent implements OnInit {
  companyId: any;
  languages: any;
  selectedLang: any;
  langMap = {};
  langCodeMap = {};

  isLocalAdmin: boolean = false;
  isLocal: boolean = false;
  isGlobal: boolean = false;
  isSuperAdmin: boolean = false;
  isCompanyAdmin: boolean = false;
  canEdit: boolean = false;
  defaultLanguage: any;

  perormanceData: any = [];
  userInfo: any = [];
  currentView: string = 'entrance';
  currentReview: any = [];
  theWall: boolean = false;
  toRefresh: boolean = false;

  @Output() onSelectReview = new EventEmitter();

  reviewList: any = [
    {
      Id: 1,
      title: 'Performance review 2021',
      Period_under_Review: 'dd/mm/yyyy-dd/mm/yyyy',
      Upcomind_date: 'dd/mm/yyyy',
      Last_change: 'A new 360 has started',
      isReviewed: true,
      secondReviewer: 'notStarted',
      reviewer: 'notStarted',
    },
    {
      Id: 2,
      title: 'Performance review 2021',
      Period_under_Review: 'dd/mm/yyyy-dd/mm/yyyy',
      Upcomind_date: 'dd/mm/yyyy',
      Last_change: 'A new 360 has started',
      isReviewed: false,
      secondReviewer: 'started',
      reviewer: 'started',
    },
    {
      Id: 3,
      title: 'Performance review 2021',
      Period_under_Review: 'dd/mm/yyyy-dd/mm/yyyy',
      Upcomind_date: 'dd/mm/yyyy',
      Last_change: 'A new 360 has started',
      isReviewed: false,
      secondReviewer: 'submitted',
      reviewer: 'shared',
    },
    {
      Id: 4,
      title: 'Performance review 2021',
      Period_under_Review: 'dd/mm/yyyy-dd/mm/yyyy',
      Upcomind_date: 'dd/mm/yyyy',
      Last_change: 'A new 360 has started',
      isReviewed: false,
      secondReviewer: false,
      reviewer: 'submitted',
    },
    {
      Id: 5,
      title: 'Performance review 2021',
      Period_under_Review: 'dd/mm/yyyy-dd/mm/yyyy',
      Upcomind_date: 'dd/mm/yyyy',
      Last_change: 'A new 360 has started',
      isReviewed: false,
      secondReviewer: false,
      reviewer: 'notStarted',
    },
    {
      Id: 6,
      title: 'Performance review 2021',
      Period_under_Review: 'dd/mm/yyyy-dd/mm/yyyy',
      Upcomind_date: 'dd/mm/yyyy',
      // Last_change:"A new 360 has started"
      isReviewed: false,
      secondReviewer: false,
      reviewer: 'notStarted',
    },
  ];
  constructor(
    public screenSizeService: ScreenSizeService,
    public coreService: CoreService,
    private srv: companyService,
    public route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.userInfo = this.coreService.getUserInfo();
    // console.log(this.userInfo);
    this.isSuperAdmin = this.userInfo.roles.indexOf('SuperAdmin') >= 0;
    this.isCompanyAdmin = this.userInfo.roles.indexOf('CompanyAdmin') >= 0;
    this.isLocalAdmin = this.userInfo.roles.indexOf('LocalAdmin') >= 0;
    this.canEdit = this.isSuperAdmin || this.isCompanyAdmin;
    this.defaultLanguage = this.userInfo.defaultlanguage;
    this.companyId = this.userInfo.belongingCompanyId;
    this.srv.getCompanyLanguages(this.companyId).subscribe((result: any) => {
      this.languages = result;

      result.forEach((element: any) => {
        this.langCodeMap[element.Code] = element;
        this.langMap[element.Id] = element;
      });
      this.selectedLang = this.userInfo.language;
    });
  }

  selectReview(review) {
    this.currentView = 'preview';
    this.currentReview = this.reviewList.filter((x: any) => x.Id === review.Id);
  }
  restoreEntrance() {
    this.currentView = 'entrance';
    setTimeout(() => initScreen(), 100);
  }
}
