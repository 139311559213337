import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Pp360SpinnerService } from 'app/pp360-components/pp360-spinner/pp360-spinner.service';
import { CoreService } from 'app/shared/services/core.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'engagement-popup-plus',
  templateUrl: './engagement-popup-plus.component.html',
  styleUrls: ['./engagement-popup-plus.component.scss'],
})
export class EngagementPopupPlusComponent implements OnInit {
  response: any = [];
  data: any;
  type: any = '';
  bsModalRef: BsModalRef;
  plusButton;
  onClose: Subject<any>;

  @Output() refreshRequired = new EventEmitter();

  constructor(
    public coreService: CoreService,
    private modalRef: BsModalService,
    public pp360SpinnerService: Pp360SpinnerService
  ) {}

  ngOnInit(): void {
    this.onClose = new Subject();
    console.log(this.response);
    for (let item of this.response) {
      item['titleName'] = this.coreService.getTranslation(
        'MANAGER_ENTRYPOINT.BOX.' + item.TitleKey
      );
      item['titleName'] = item['titleName'].replace('{{value}}', item.Value);
    }
  }
  async getManagerData() {
    this.pp360SpinnerService.showSpinner(true);
    this.coreService.getManagerEnteryPointDetail().subscribe(
      async (data: any) => {
        this.pp360SpinnerService.showSpinner(false);
      },
      (err: any) => {
        this.coreService.toasterMessage('error', 'manager', err.Message);
        this.pp360SpinnerService.showSpinner(false);
      }
    );
  }
  addItem(item) {
    let body = [item.Key, item.Value];
    if (item) {
      this.pp360SpinnerService.showSpinner(true);
      this.coreService.addIncludeBox(body).subscribe(
        (data: any) => {
          this.onClose.next('added');
          this.modalRef.hide();
          this.pp360SpinnerService.showSpinner(false);
        },
        (err: any) => {
          this.coreService.toasterMessage('error', 'manager', err.Message);
          this.pp360SpinnerService.showSpinner(false);
        }
      );
    }
  }
}
