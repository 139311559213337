import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CoreService } from 'app/shared/services/core.service';
import { UserService } from 'app/super-admin/user/user.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import {
  base64ToFile,
  Dimensions,
  ImageCroppedEvent,
  ImageTransform,
} from 'ngx-image-cropper';
import { WebcamImage } from 'ngx-webcam';
import { Subject } from 'rxjs';
import { CameraComponent } from '../camera/camera.component';
import { CustomImageCropperComponent } from '../custom-image-cropper/custom-image-cropper.component';
import { FileHandle } from './dragDrop.directive';
import { Pp360SpinnerService } from 'app/pp360-components/pp360-spinner/pp360-spinner.service';

@Component({
  selector: 'image-upload-dialog',
  templateUrl: './image-upload-dialog.component.html',
  styleUrls: ['./image-upload-dialog.component.scss'],
})
export class ImageUploadDialogComponent implements OnInit {
  onClose: Subject<any>;
  file: FileHandle;
  showError: boolean = false;

  ///crop- image ///
  imageChangedEvent: any = '';
  croppedImage: any = '';
  finalCroppedImage: SafeUrl;
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  transform: ImageTransform = {};
  loading = false;
  showCamera = false;

  constructor(
    private sanitizer: DomSanitizer,
    private _bsModalRef: BsModalRef,
    public service: UserService,
    public coreService: CoreService,
    public options: ModalOptions,
    public pp360SpinnerService: Pp360SpinnerService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.onClose = new Subject();
  }

  filesDropped(files: FileHandle[]): void {
    const file = files[0];
    if (file) {
      const nonImage = file.file.type.indexOf('image') === -1;
      this.showError = !!nonImage;
      if (!this.showError) {
        this.file = file;
        this.cropImage(file.file);
      }
    }
  }

  handleImage(webcamImage: WebcamImage) {
    this.showCamera = false;
    const file = this.getFile(webcamImage);
    const url = this.sanitizer.bypassSecurityTrustUrl(
      window.URL.createObjectURL(file)
    );
    this.file = { file, url };
    this.cropImage(file);
  }

  getFile(webcamImage): File {
    const arr = webcamImage.imageAsDataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const file: File = new File([u8arr], 'cam-image.jpg', {
      type: 'image/jpeg',
    });
    return file;
  }

  onFileUplaod(event: any) {
    if (event.target.files.length) {
      const file = event.target.files[0];
      const url = this.sanitizer.bypassSecurityTrustUrl(
        window.URL.createObjectURL(file)
      );
      this.file = { file, url };
      this.cropImage(file);
    }
  }

  cropImage(file) {
    const cropModalRef = this.modalService.show(CustomImageCropperComponent, {
      class: 'image-change',
    });
    const content = {
      file,
      isRounded: true,
    };
    (<CustomImageCropperComponent>cropModalRef.content).showModal(content);
    (<CustomImageCropperComponent>cropModalRef.content).onClose.subscribe(
      (result) => {
        if (result) {
          this.croppedImage = result;
          this.isDone();
        } else {
          this.file = null;
          this.croppedImage = null;
          this.finalCroppedImage = null;
        }
      }
    );
  }

  openCamera() {
    const cropModalRef = this.modalService.show(CameraComponent, {
      class: 'camera-image-change',
    });
    (<CameraComponent>cropModalRef.content).onClose.subscribe((result) => {
      if (result) {
        this.handleImage(result);
      } else {
        this.showCamera = false;
      }
    });
  }

  updateProfileImage() {
    const userInfo = this.coreService.getUserInfo();
    if (!this.croppedImage) {
      this.pp360SpinnerService.showSpinner(true);
      this.service.deleteUploadPicture().subscribe(
        (data: any) => {
          this.pp360SpinnerService.showSpinner(false);
          this.onClose.next('removed');
          this._bsModalRef.hide();
        },
        (err: any) => {
          this.pp360SpinnerService.showSpinner(false);
          this.coreService.toasterMessage('error', 'company', err.Message);
        }
      );
    } else {
      this.loading = true;
      const imageFile = base64ToFile(this.croppedImage);
      let formData: FormData = new FormData();
      formData.append('uploadFile', imageFile, this.file.file.name);
      this.service
        .employeeExtUploadPicture(userInfo.linkedEmployeeId, formData)
        .subscribe(
          (data: any) => {
            this.loading = false;
            const url = this.sanitizer.bypassSecurityTrustUrl(
              window.URL.createObjectURL(base64ToFile(this.croppedImage))
            );
            this.coreService.setUserProfile(data.Picture);
            this.onClose.next(url);
            this._bsModalRef.hide();
          },
          (err: any) => {
            this.loading = false;
            this.coreService.toasterMessage('error', 'company', err.Message);
          }
        );
    }
  }

  onCancel(): void {
    this._bsModalRef.hide();
  }

  getImageSize(file) {
    return file && file.file ? (file.file.size / (1024 * 1024)).toFixed(2) : 0;
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  isDone() {
    const url = this.sanitizer.bypassSecurityTrustUrl(
      window.URL.createObjectURL(base64ToFile(this.croppedImage))
    );
    this.finalCroppedImage = url;
  }
  onDelete() {
    this.file = null;
    this.croppedImage = null;
    this.finalCroppedImage = null;
  }

  async onEdit(item: any) {
    const response = await fetch(item);
    const blob = await response.blob();
    const file = new File([blob], 'image.jpg', { type: blob.type });
    const url = this.sanitizer.bypassSecurityTrustUrl(
      window.URL.createObjectURL(file)
    );
    this.file = { file, url };
    this.cropImage(file);
  }
}
