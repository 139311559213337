import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CoreService } from 'app/shared/services/core.service';

@Component({
  selector: 'app-bonus-bank',
  templateUrl: './bonus-bank.component.html',
  styleUrls: ['./bonus-bank.component.scss', '../../common-styles.scss'],
})
export class BonusBankComponent implements OnInit {
  @Input() plan: any;
  @Input() userId: any;
  currentYear: number;
  accrued: number;

  constructor(
    private translateService: TranslateService,
    private coreService: CoreService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.currentYear = this.plan.YearStart;

    console.log('plan', this.plan);
  }

  formatNumber(x: any, decimal: number = 2) {
    if ((!x && x !== 0) || String(x).includes('NaN')) return '';
    return x.toLocaleString(this.translateService.currentLang, {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    });
  }

  getYears() {
    return new Array(this.plan.YearEnd - this.plan.YearStart)
      .fill(0)
      .map((val, idx) => this.plan.YearStart + idx);
  }

  getYearsUntilCurrentYear(currentYear) {
    return new Array(currentYear + 1 - this.plan.YearStart)
      .fill(0)
      .map((val, idx) => this.plan.YearStart + idx);
  }

  getAmount(type: string) {
    let v = 0;
    if (this.plan) {
      let pby = this.plan.PartecipantsByYear.find(
        (x) => x.Year === this.currentYear
      );
      if (pby && pby.Partecipants.length > 0) {
        let p = pby.Partecipants[0];
        let pa = p.Values.PaybleDeferredArray.find(
          (x) => x.PayYear === this.currentYear
        );
        switch (type) {
          case 'accrued':
            v = p.Values.Accrued.value;
            break;
          case 'accrued_max':
            v = p.Values.Accrued.max;
            break;
          case 'accrued_payable':
            v = pa ? pa.Cash.value + pa.Equity.value : 0;
            break;
          case 'accrued_deferred':
            v = pa ? pa.DeferredCash.value + pa.DeferredEquity.value : 0;
            break;
          case 'payable':
            v = pa ? pa.Cash.value + pa.Equity.value : 0;
            break;
          case 'payable_cash':
            v = pa ? pa.Cash.value : 0;
            break;
          case 'payable_equity':
            v = pa ? pa.Equity.value : 0;
            break;
          case 'bonusbank':
            break;
        }
      }
    }
    return v;
  }

  expandAccrued: boolean = false;
  expandPayable: boolean = false;
  expandBonusBank: boolean = false;
  expand(type: string) {
    switch (type) {
      case 'accrued':
        this.expandAccrued = true;
        this.expandPayable = false;
        this.expandBonusBank = false;
        break;
      case 'payable':
        this.expandAccrued = false;
        this.expandPayable = true;
        this.expandBonusBank = false;
        break;
      case 'bonusbank':
        this.expandAccrued = false;
        this.expandPayable = false;
        this.expandBonusBank = true;
        break;
    }
    this.ref.detectChanges();
  }

  /*******************************************************************************
    ____   ____  _   _ _    _  _____   ____          _   _ _  __
   |  _ \ / __ \| \ | | |  | |/ ____| |  _ \   /\   | \ | | |/ /
   | |_) | |  | |  \| | |  | | (___   | |_) | /  \  |  \| | ' /
   |  _ <| |  | | . ` | |  | |\___ \  |  _ < / /\ \ | . ` |  <
   | |_) | |__| | |\  | |__| |____) | | |_) / ____ \| |\  | . \
   |____/ \____/|_| \_|\____/|_____/  |____/_/    \_\_| \_|_|\_\
  *******************************************************************************/
  getBonusDetailPastYearsPayableValue(year: number, partecipant: any) {
    let n = 0.0;
    if (this.plan && this.plan.PartecipantsByYear) {
      let items = this.plan.PartecipantsByYear.filter((x) => x.Year < year);
      items.forEach((element: any) => {
        let foundEmployee = element.Partecipants.find(
          (x) => x.EmployeeId == partecipant.EmployeeId
        );
        let payments = foundEmployee.Values.PaybleDeferredArray.filter(
          (x) => x.PayYear < year
        );
        if (payments) {
          payments.forEach((e: any) => {
            n += e.Cash.value + e.Equity.value;
          });
        }
      });
    }

    return n;
  }

  getTotMultiplierValue(type: string, year: number) {
    if (this.plan && this.plan.Multipliers) {
      let item = this.plan.Multipliers.ByYear.find((x) => x.year == year);
      if (item) {
        switch (type) {
          case 'gateway':
            return item.multipliers.gateways
              ? item.multipliers.gateways.value
              : 1;
            break;
          case 'objective':
            return item.multipliers.objectives
              ? item.multipliers.objectives.value
              : 1;
            break;
          case 'corrective':
            return item.multipliers.correctives
              ? item.multipliers.correctives.value
              : 1;
            break;
        }
      }
    }

    return 0;
  }

  getBonusDeferredPartecipantValue(year: number, partecipant: any) {
    let n = 0;
    if (partecipant.Values) {
      let item = partecipant.Values.PaybleDeferredArray.find(
        (x) => x.PayYear == year
      );
      if (item) n = item.DeferredCash.value + item.DeferredEquity.value;
    }
    return n;
  }

  getPartecipantsByYear(year: number) {
    const partecipants = this.plan.PartecipantsByYear.filter(
      (planYear) => planYear.Year == year
    );
    return partecipants.length > 0 && partecipants[0].Partecipants.length > 0
      ? partecipants[0].Partecipants
      : [];
  }
  getPartecipant(year) {
    let partecipants = this.getPartecipantsByYear(year);
    let p = partecipants.find((x) => x.EmployeeId == this.userId);
    return p;
  }
  getBonusBankOpening(year: number) {
    if (this.plan && year > this.plan.YearStart) {
      return this.getBonusBankClosing(year - 1);
    } else {
      return 0;
    }
  }

  getBonusBankPastPayable() {
    let partecipant = this.getPartecipant(this.currentYear);
    return this.getBonusDetailPastYearsPayableValue(
      this.currentYear,
      partecipant
    );
  }
  getBonusBankDeferred() {
    let partecipant = this.getPartecipant(this.currentYear);
    return this.getBonusDeferredPartecipantValue(this.currentYear, partecipant);
  }

  getBonusBankClosing(year: number) {
    let n = this.getBonusBankRolling(year);

    return n * this.getTotMultiplierValue('corrective', year);
  }

  getBonusBankRolling(year: number) {
    let y = this.currentYear;
    if (year < this.currentYear) {
      y = year;
    }
    let partecipant = this.getPartecipant(y);

    let n = 0;
    let base = 0;
    if (this.plan) {
      if (this.plan.CorrectiveType == 'Rolling') {
        base =
          this.getBonusBankOpening(year) -
          this.getBonusDetailPastYearsPayableValue(year, partecipant) +
          this.getBonusDeferredPartecipantValue(year, partecipant);
      } else {
        if (year == this.plan.PayoutStructure.PayoutYearEnd) {
          base =
            this.getBonusBankOpening(year) -
            this.getBonusDetailPastYearsPayableValue(year, partecipant) +
            this.getBonusDeferredPartecipantValue(year, partecipant);
        } else {
          base = this.getBonusBankOpening(year);
        }
      }
    }
    return base;
  }

  getMax(year: number) {
    let v = 0;
    if (this.plan) {
      let pby = this.plan.PartecipantsByYear.find((x) => x.Year === year);
      if (pby && pby.Partecipants.length > 0) {
        let p = pby.Partecipants[0];
        let pa = p.Values.PaybleDeferredArray.find((x) => x.PayYear === year);
        v = p.Values.Accrued.max;
      }
    }
    return v;
  }

  getBonusBankOpeningProgressMax(year: number) {
    return 12;
  }
  getBonusBankPastPayableProgressMax() {
    return 15;
  }
  getBonusBankDeferredProgressMax() {
    return 55;
  }
  getBonusBankRollingProgressMax(year: number) {
    return 30;
  }
}
