import { BrowserModule } from '@angular/platform-browser';
import {
  BrowserAnimationsModule,
  NoopAnimationsModule,
} from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  LocationStrategy,
  HashLocationStrategy,
  Location,
  PathLocationStrategy,
} from '@angular/common';
import { RestfulService } from './shared/services/restful.service';
import { CoreService } from './shared/services/core.service';
import { SharedService } from './shared/services/shared.service';
import { ScreenSizeService } from './shared/services/screen-size.service';
import { navigationService } from './shared/services/navigation.service';
import { ROUTES } from './app.routes';
import { AppComponent } from './app.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { LinkyModule } from 'ngx-linky';
import { PickerModule } from '@ctrl/ngx-emoji-mart';

import { CompanyReportService } from 'app/company-report/company-report.service';

//chart.js
import { ChartsModule } from 'ng2-charts';

// App views

import { AppviewsModule } from './views/appviews/appviews.module';

// App modules/components
import { LayoutsModule } from './components/common/layouts/layouts.module';
import { ToasterModule } from 'angular2-toaster';
import { SharedModule } from './shared/shared.module';
import { PageNotFoundComponent } from './components/common/page-not-found/page-not-found.component';
//import { ManagerReportModule } from './manager-report/manager-report.module';
//import { MyWalletModule } from './my-wallet/my-wallet.module';

//GUARDS
import { AuthGuard } from './shared/guards/auth.guard';
import { AdminGuard } from './shared/guards/admin.guard';
import { CompanyAdminGuard } from './shared/guards/company-admin.guard';
import { EmployeeGuard } from './shared/guards/employee.guard';

// import { SideMenuNavigationComponent } from './components/common/side-menu-navigation/side-menu-navigation.component';
// import {NgCytoscapeModule} from "ng2-cytoscape";

import { HttpClient, HttpClientModule } from '@angular/common/http';
import {
  TranslateModule,
  TranslateLoader,
  TranslateParser,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MomentModule } from 'angular2-moment';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
//import { DragDropModule } from '@angular/cdk/drag-drop'

import { OAuthComponent } from './components/common/oauth.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  //return new TranslateHttpLoader(httpClient);
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

import { AdminLtiModule } from './admin-lti/admin-lti.module';
import { PartecipantLTIModule } from './partecipant-lti/partecipant-lti.module';

import { DndModule } from 'ngx-drag-drop';
import { ObjSheetPopupComponent } from './company-report/objectives-individual/objective/obj-sheet-popup/obj-sheet-popup.component';
import { PatPrizeLibraryItemDetailsComponent } from 'app/super-admin/patprizes/patprizelibraryitemdetails/patprizelibraryitemdetails.component';

import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AccordionModule } from 'ngx-bootstrap/accordion';

import { CycleManagerModule } from 'app/components/common/cycle-manager/cycle-manager.module';
import { DndItemModule } from 'app/components/common/dnd-item/dnd-item.module';

import { LanguageSelectorModule } from 'app/components/common/language-selector/language-selector.module';

import { ThemeModule } from 'app/shared/theme/theme.module';
import { NgxPrintModule } from 'ngx-print';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';

import localeEs from '@angular/common/locales/es';
import localePt from '@angular/common/locales/pt';
import localeDe from '@angular/common/locales/de';
import localePl from '@angular/common/locales/pl';
import localeRu from '@angular/common/locales/ru';
import localeZh from '@angular/common/locales/zh';
import localeJa from '@angular/common/locales/ja';
import localeNL from '@angular/common/locales/nl';

registerLocaleData(localeFr);
registerLocaleData(localeIt);
registerLocaleData(localeEs);
registerLocaleData(localePt);
registerLocaleData(localeDe);
registerLocaleData(localePl);
registerLocaleData(localeRu);
registerLocaleData(localeZh);
registerLocaleData(localeJa);
registerLocaleData(localeNL);

import { companyService } from 'app/super-admin/company/company.service';

import { AutosizeModule } from 'ngx-autosize';
import { NgSelectModule } from '@ng-select/ng-select';

// IONIC
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { StatusBar } from '@ionic-native/status-bar';
import { BiometricService } from 'app/shared/services/cordova/biometric.service';
import { NouisliderModule } from 'ng2-nouislider';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { SearchEmployeeComponent } from './components/common/topnavbar/search-employee/search-employee.component';

/*google social*/
import {
  SocialLoginModule,
  SocialAuthServiceConfig,
} from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';
import { environment } from '../environments/environment';

import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { Pp360ComponentsModule } from './pp360-components/pp360-components.module';

//ngx-quill
import { QuillModule } from 'ngx-quill';
import { ManagerGuard } from './shared/guards/manager.guard';
import { AuthModule } from './auth/auth.module';
import { ManagerPreviewComponent } from './manager/manager-preview/manager-preview.component';
import { AnalyticsService } from './analytics/analytics.service';
@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    OAuthComponent,
    ObjSheetPopupComponent,
    PatPrizeLibraryItemDetailsComponent,
    SearchEmployeeComponent,
    ManagerPreviewComponent,
  ],
  entryComponents: [
    ObjSheetPopupComponent,
    PatPrizeLibraryItemDetailsComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    CycleManagerModule,
    DndItemModule,
    LanguageSelectorModule,
    SharedModule,
    BrowserModule,
    NoopAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    // NgCytoscapeModule,
    //HttpModule,
    HttpClientModule,
    MomentModule,
    LayoutsModule,
    AppviewsModule,
    RouterModule.forRoot(ROUTES),
    ToasterModule,
    //DragDropModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    [BsDropdownModule.forRoot()],
    AdminLtiModule,
    PartecipantLTIModule,
    AngularMultiSelectModule,
    DndModule,
    SlickCarouselModule,
    AccordionModule.forRoot(),
    AutosizeModule,
    ThemeModule,
    NgxPrintModule,
    AuthModule,
    LinkyModule,
    NouisliderModule,
    ProgressbarModule.forRoot(),
    SocialLoginModule,
    NgSelectModule,
    Pp360ComponentsModule,
    PickerModule,
    QuillModule.forRoot(),
  ],
  exports: [
    TranslateModule,
    SlickCarouselModule,
    AccordionModule,
    CycleManagerModule,
    DndItemModule,
    LanguageSelectorModule,
    NgSelectModule,
    PickerModule,
    ChartsModule,
  ],
  providers: [
    FingerprintAIO,
    SplashScreen,

    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: LOCALE_ID, useValue: 'fr' },
    { provide: LOCALE_ID, useValue: 'it' },
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: LOCALE_ID, useValue: 'fr' },
    { provide: LOCALE_ID, useValue: 'de' },
    { provide: LOCALE_ID, useValue: 'nl' },
    { provide: LOCALE_ID, useValue: 'pl' },
    { provide: LOCALE_ID, useValue: 'ru' },
    { provide: LOCALE_ID, useValue: 'zh' },
    { provide: LOCALE_ID, useValue: 'ja' },

    RestfulService,
    CoreService,
    SharedService,
    ScreenSizeService,
    navigationService,
    AuthGuard,
    AdminGuard,
    ManagerGuard,
    CompanyAdminGuard,
    EmployeeGuard,
    companyService,
    AnalyticsService,
    Diagnostic,
    BiometricService,
    CompanyReportService,
    // Keyboard,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleClientId),
          },
          // {
          //   id: FacebookLoginProvider.PROVIDER_ID,
          //   provider: new FacebookLoginProvider('clientId')
          // }
        ],
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

//

// { useHash: false }
