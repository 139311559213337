import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WallService } from 'app/employee/wall/wall.service';
import { Pp360SpinnerService } from 'app/pp360-components/pp360-spinner/pp360-spinner.service';
import { CoreService } from 'app/shared/services/core.service';
import { SharedService } from 'app/shared/services/shared.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { ScreenSizeService } from 'app/shared/services/screen-size.service';

@Component({
  selector: 'new-received-cf',
  templateUrl: './new-received-cf.component.html',
  styleUrls: ['./new-received-cf.component.scss'],
})
export class NewReceivedCfComponent implements OnInit {
  selectedFeedback: any = [];
  type: any;
  @Input() patFeedData = [];
  selectedPat: any = [];
  selectedToken: any = [];

  public onClose: Subject<any>;

  employeeId: string;
  pageStart = 1;
  pageStartAboutMe = 1;
  pageSize = 10;
  userInfo: any;
  numberOfAsks = 0;
  feedbackRequests = [];
  wallFilters = {
    IsAboutMe: true,
    Items: null,
    Employees: null,
    Teams: null,
    Roles: null,
    Locations: null,
  };
  isMobile: boolean;

  constructor(
    public service: WallService,
    public coreService: CoreService,
    public router: Router,
    public sharedService: SharedService,
    public pp360SpinnerService: Pp360SpinnerService,
    public screenSizeService: ScreenSizeService,
    private _bsModalRef: BsModalRef
  ) {}

  ngOnInit(): void {
    this.onClose = new Subject();
    this.userInfo = this.coreService.getUserInfo();
    this.employeeId = this.userInfo.linkedEmployeeId;
    this.getPatFeedForWall();
    this.screenSizeService.ScreenSize.subscribe((res: number) => {
      this.isMobile = res <= 991;
    });
  }
  getPatFeedForWall() {
    this.pp360SpinnerService.showSpinner(true);
    let request;
    if (this.wallFilters) {
      request = JSON.parse(JSON.stringify(this.wallFilters));
    }
    let newPat, newFeedback, newToken;
    if (!newPat && !newFeedback && !newToken) {
      this.service
        .getPagedPatfeedForAboutWall(
          this.employeeId,
          this.pageStart,
          this.pageSize,
          request
        )
        .toPromise()
        .then((data: any) => {
          this.coreService.setEvents(data.Events);
          this.userInfo['wallDate'] = data.Date;
          this.coreService.setUserInfo(this.userInfo);
          this.pp360SpinnerService.showSpinner(false);
          this.selectedPat = data.WallFeeds.Rows;

          newPat = JSON.parse(
            JSON.stringify(this.selectedPat.filter((x) => x.Type === 'Pat'))
          );
          newFeedback = JSON.parse(
            JSON.stringify(
              this.selectedPat.filter((x) => x.Type === 'ReceivedEvaluation')
            )
          );
          newToken = JSON.parse(
            JSON.stringify(
              this.selectedPat.filter((x) => x.Type === 'ReceivedToken')
            )
          );
          this.selectedPat = newPat[0];
          this.selectedFeedback = newFeedback[0];
          this.selectedToken = newToken[0];
        })
        .catch((err: any) => {
          this.pp360SpinnerService.showSpinner(false);
          this.coreService.toasterMessage('error', 'wall', err.Message);
        });
    }
  }

  onCancel(): void {
    this.onClose.next(false);
    this._bsModalRef.hide();
  }
}
