import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CoreService } from 'app/shared/services/core.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EmployeeTeamPopUpComponent } from 'app/shared/components/employee-team-pop-up/employee-team-pop-up.component';
import { WallService } from '../wall.service';
import { InappropriateDialogComponent } from './../inappropriate-dialog/inappropriate-dialog.component';
import { SharedService } from 'app/shared/services/shared.service';
import { Router } from '@angular/router';
import { ListOfEmployeesComponent } from 'app/shared/components/list-of-employees/list-of-employees.component';
import { GiveTokenComponent } from 'app/shared/components/give-token/give-token.component';
@Component({
  selector: 'feed-token',
  templateUrl: './feed-token.component.html',
  styleUrls: ['./feed-token.component.scss', '../common-feed-style.scss'],
})
export class FeedTokenComponent implements OnInit {
  @Input() showReported: boolean;
  @Input() readOnly: boolean;
  @Input() item: any;
  @Output() reloadRequired = new EventEmitter();
  @Output() reportedAsInappropriate = new EventEmitter<any>();
  @Output() feedDeleted = new EventEmitter();
  @Output() feedUpdated = new EventEmitter<any>();
  @Input() selectedTab: any;
  empInfo: any;

  bsModalRef: BsModalRef;
  userInfo: any;
  sendingComment = false;
  empInfos: any;
  profileName: any;

  constructor(
    public coreService: CoreService,
    private modalService: BsModalService,
    private service: WallService,
    private route: Router,
    private sharedService: SharedService
  ) {
    this.userInfo = this.coreService.getUserInfo();
  }
  text1 = `Ai giovani dico che la cosa più importante è cercareiù importante è cercare di capire cosa uno vuole fare, cosa si diverte a fare. La cosa estremamente importante è che uno non deve non fare le cose per la paurare cosa uno vuole fare, cosa si diverte a fare. La cosa estremamente importante è che uno non deve non fare le cose per la paura di non riuscircimmmmmmmm `;

  ngOnInit() {
    this.item.totalComments = 0;
    this.item.Date = this.sharedService.getLocalDate(this.item.Date);
    if (this.service.profiles.length) {
      const employee = this.service.profiles.find(
        (x) => x.Id === this.item.Target.Id
      );
      if (employee) {
        this.empInfo = employee;
      } else {
        this.getProfileInfo();
      }
      const employees = this.service.profiles.find(
        (x) => x.Id === this.item.Author.Id
      );
      if (employees) {
        this.empInfos = employees;
      } else {
        this.getProfileInfos();
      }
    } else {
      this.getProfileInfo();
      this.getProfileInfos();
    }
    this.item['isComment'] = false;
    this.item['commentArr'] = [];
    this.manageCountComment();
    this.profileName = this.sortName(this.item.Author.FullName);
  }
  sortName(Datas) {
    if (Datas?.split(' ').length > 2) {
      let fname = Datas?.split(' ')[0];
      let lname = Datas?.split(' ')[2];
      return fname + ' ' + lname;
    } else {
      return Datas;
    }
  }
  updateCount(comments) {
    for (let i of comments) {
      if (i.SubComments.length > 0) {
        this.item.totalComments += i.SubComments.length;
        this.updateCount(i.SubComments);
      }
    }
  }

  manageCountComment() {
    if (this.item.Comments.length > 0) {
      this.item.totalComments =
        this.item.Comments.length + this.item.totalComments;
      this.updateCount(this.item.Comments);
    }
  }

  getProfileInfo() {
    this.service.getEmployeeInfo(this.item.Target.Id).subscribe((data: any) => {
      if (data) {
        this.empInfo = data;
        const employee = this.service.profiles.find(
          (x) => x.Id === this.item.Id
        );
        if (!employee) {
          this.service.profiles.push(data);
        }
      }
    });
  }
  getProfileInfos() {
    this.service.getEmployeeInfo(this.item.Author.Id).subscribe((data: any) => {
      if (data) {
        this.empInfos = data;
        const employees = this.service.profiles.find(
          (x) => x.Id === this.item.Author.Id
        );
        if (!employees) {
          this.service.profiles.push(data);
        }
      }
    });
  }
  goToProfile(idofemployee) {
    this.route.navigate(['/User/story'], {
      queryParams: { idVisitor: idofemployee },
      replaceUrl: true,
    });
    // this.bsModalRef.hide();
  }
  showHideTranslated(item, property) {
    if (item.ShowTranslated) {
      item.ShowTranslated = !item.ShowTranslated;
    } else {
      let selectedLang = this.userInfo.language;
      const text = item[property];
      this.coreService
        .translateText(text, null, selectedLang)
        .subscribe((result: any) => {
          item['TranslatedText'] = result.TranslatedText;
          item.ShowTranslated = true;
        });
    }
  }

  shortcutForPat(team) {
    if (team.FirstName === 'Anonymous') return;
    team.isPreselect = true;

    this.bsModalRef = this.modalService.show(EmployeeTeamPopUpComponent, {
      class: 'width232',
    });
    this.bsModalRef.content.team = team;

    this.bsModalRef.content.addNewPat.subscribe((data: any) => {
      this.reloadRequired.emit();
    });
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  expandMenu(item) {
    item.expandMenu = !item.expandMenu;
  }

  reportInnapropriate(item) {
    var msg = this.coreService.getTranslation('Messages.InappropriateMessage');

    msg = msg.replace('{{item.Author.Name}}', item.Author.Name);
    const content: any = {
      title: msg,
      type: 'Token',
    };
    const modal = this.modalService.show(InappropriateDialogComponent, {
      class: 'self-evaluation',
    });
    (<InappropriateDialogComponent>modal.content).showModal(content);
    (<InappropriateDialogComponent>modal.content).onClose.subscribe(
      (result: boolean) => {
        if (result) {
          var req = {
            FeedId: item.Id,
            Reason: modal.content.selectedResonId,
            Text: modal.content.text,
            FeedType: 0,
          };

          switch (item.Type) {
            case 'ReceivedAsk':
              req.FeedType = 3;
              break;
            case 'ReceivedEvaluation':
              req.FeedType = 2;
              break;
            case 'ReceivedToken':
              req.FeedType = 1;
              break;
            case 'Pat':
              req.FeedType = 0;
              break;
            default:
              req.FeedType = 4;
              break;
          }

          this.service.postInappropriateFeedback(req).subscribe(
            (data: any) => {
              this.coreService.toasterMessage(
                'success',
                'auth',
                this.coreService.getTranslation(
                  'Messages.InnapropriateReported'
                )
              );
              this.reportedAsInappropriate.emit(item);
            },
            (err: any) => {
              this.coreService.toasterMessage('error', 'wall', err.Message);
            }
          );
        }
      }
    );
  }

  getShortEmployeeName(employee) {
    var name =
      (employee.SecondName ? employee.SecondName : '') +
      ' ' +
      employee.FirstName;
    var len = 35;
    if (window.innerWidth < 350) {
      len = 21;
    }
    if (name.length > len) {
      name = employee.SecondName.charAt(0) + '.' + ' ' + employee.FirstName;
    }

    return name;
  }

  getConfigMenu(item: any) {
    return {
      editAction: item.Author.Id === this.userInfo.linkedEmployeeId,
      deleteAction: item.Author.Id === this.userInfo.linkedEmployeeId,
      inappropriateAction:
        !item.IsReported && item.Author.Id !== this.userInfo.linkedEmployeeId,
    };
  }

  async edit(item: any, comment: any) {
    if (comment) {
      console.log('888', comment);
    } else {
      //recupero la lista degli impiegati
      let all_Employees_Teams: any = await this.sharedService
        .getFilterEmployees(this.userInfo.linkedEmployeeId, 'Token', 'List')
        .toPromise();
      if (all_Employees_Teams.length > 0) {
        all_Employees_Teams.forEach((element: any) => {
          if (element.Id && element.Id.indexOf(':') < 0) {
            if (element.Type == 'Team') {
              element['Name'] = element.Name + ' Team';
              element['Id'] = 't:' + element['Id'];
            } else {
              element['Name'] = element.Name;
              element['Id'] = 'e:' + element['Id'];
            }
          }
          element.isPreselect =
            element.Type == 'Employee' && element.Id === 'e:' + item.Target.Id;
        });

        if (this.item.Goal && this.item.PostSubject == 2) {
          this.item.patType = 'goal';
        } else if (this.item.Skill && this.item.PostSubject == 1) {
          this.item.patType = 'behavior';
        } else {
          this.item.patType = 'skill';
        }
        const modal = this.modalService.show(GiveTokenComponent, {
          class: 'wallPopup',
          ignoreBackdropClick: true,
        });
        (<GiveTokenComponent>modal.content).showModal({
          pat: this.item,
          all_Employees_Teams: all_Employees_Teams,
        });

        (<GiveTokenComponent>modal.content).onClose.subscribe(
          (newPatData: boolean) => {
            this.feedUpdated.emit(newPatData);
          }
        );
      }
    }
  }

  delete(item: any, comment: any) {}

  onComment(item) {
    item.isComment = !item.isComment;
  }
  name() {
    return 'Token';
  }
  sendMsg(comment) {
    this.item.totalComments++;
    this.sendingComment = true;
    let reqBody = {
      TokenId: this.item.Id,
      AuthorId: this.userInfo.linkedEmployeeId,
      Text: comment?.comment ? comment?.comment : ' ',
      Picture: comment?.image ? comment?.image : null,
    };
    if (reqBody.Text) {
      this.service.commentOnWall(reqBody, 'Token').subscribe(
        (data: any) => {
          console.log('data', data);
          this.item.Comments.unshift(data);
          this.sendingComment = false;
          // this.toRefresh=false;
        },
        (err: any) => {
          console.log('errrr', err);
          this.sendingComment = false;
        }
      );
    }
  }
}
