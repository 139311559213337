import {
  Component,
  OnInit,
  Input,
  Output,
  ChangeDetectorRef,
  EventEmitter,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CoreService } from 'app/shared/services/core.service';
import { PdfDialogComponent } from 'app/shared/pdf-dialog/pdf-dialog.component';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: [
    './documents.component.scss',
    '../common-styles.scss',
    '../../../magic-checkbox.scss',
  ],
})
export class DocumentsComponent implements OnInit {
  @Input() plan: any;
  @Input() userId: any;
  userInfo: any;
  @Output() acceptPlan = new EventEmitter();

  constructor(
    private translateService: TranslateService,
    private coreService: CoreService,
    private ref: ChangeDetectorRef,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.userInfo = this.coreService.getUserInfo();
    console.log(this.userInfo);
  }

  openPdf(pdfUrl: string) {
    if (pdfUrl) {
      //pdfUrl=this.coreService.getImageURL(pdfUrl,undefined);
      const content: any = {
        pdfUrl: pdfUrl,
      };
      const modal = this.modalService.show(PdfDialogComponent, {
        class: 'pdw-view',
      });
      (<PdfDialogComponent>modal.content).showModal(content);
    } else {
      //Nothing to show
    }
  }

  accept() {
    if (
      this.plan.termsConditions &&
      this.plan.myLtiForm &&
      this.plan.vexatiousClauses
    ) {
      this.acceptPlan.emit();
    }
  }
}
