export function calculateLTIPlanParametes(plan, useCurrentValues) {
  if (plan) {
    calculateAllMultipliers(plan, useCurrentValues);
    calculateBonuses(plan);
    calculatePreviousYearsPayables(plan);
    //console.log(JSON.stringify(plan));
  }
}
/**************************************************************************/
/**
 * Gets completion status
 * @param plan
 */
export function getCompletionStatus(plan) {
  if (plan) {
    let status = {
      partecipants: getPartecipantsStatus(plan),
      gateways: getGatewaysStatus(plan),
      objectives: getObjectivesStatus(plan),
      payout: getPayoutStatus(plan),
      gatewaysOrobjectives: function () {
        let g = getGatewaysStatus(plan);
        let o = getObjectivesStatus(plan);
        if (o === 'none' && g === 'none') return 'error';
        if (o === 'none') return g;
        if (g === 'none') return o;
        if (o != 'complete' || g != 'complete') return 'error';
        return 'complete';
      },
      correctives: getCorrectivessStatus(plan),
      documents: getDocumentsStatus(plan),
      isComplete: function () {
        return (
          this.partecipants == 'complete' &&
          this.gatewaysOrobjectives() == 'complete' &&
          this.correctives != 'error' &&
          this.payout == 'complete' &&
          this.documents == 'complete'
        );
      },
    };

    plan.Status = status;
  }
}

/**************************************************************************/
/**
 * Gets status of Payout structure section
 * @param plan
 */
function getPayoutStatus(plan) {
  let status = 'complete';
  if (plan && plan.PayoutStructure) {
    plan.PayoutStructure.Rows.forEach((r) => {
      let sum = 0;
      r.PayoutValues.forEach((c) => {
        sum += c.Cash / 1 + c.Equity / 1;
      });
      if (sum != 100) {
        status = 'error';
        return;
      }
    });
  }
  return status;
}
/**************************************************************************/
/**
 * Gets the status od Gateways section
 * @param plan
 */
function getGatewaysStatus(plan) {
  let status = 'none';
  if (plan && plan.Gateways.length > 0) {
    status = getGenericObjectStatus(plan.Gateways);
  }
  return status;
}
/**************************************************************************/
/**
 * Gets the status od Obiectives section
 * @param plan
 */
function getObjectivesStatus(plan) {
  let status = 'none';
  if (plan && plan.Objectives.length > 0) {
    status = getGenericObjectStatus(plan.Objectives);
  }
  return status;
}
/**************************************************************************/
/**
 * Gets the status od Obiectives section
 * @param plan
 */
function getCorrectivessStatus(plan) {
  let status = 'none';
  if (plan && plan.Correctives.length > 0) {
    status = getGenericObjectStatus(plan.Correctives);
  }
  return status;
}
/**************************************************************************/
/**
 * Gets the statu of generic list of objects (Gateways, Correctives, Objectives)
 * @param objectList an arry of Gateways or Correctives or Objectives
 */
function getGenericObjectStatus(objectList) {
  let status = 'complete';
  let sum = 0;
  objectList.forEach((itm) => {
    if (!itm.GoalId || !itm.MeasureId) {
      status = 'error';
      return;
    } else {
      sum += itm.Weight / 1;
      let hasValue = false;
      itm.YearlyValues.forEach((yv) => {
        if (yv.TargetValue && yv.TargetValue > 0) {
          hasValue = true;
          return;
        }
      });
      if (!hasValue) {
        status = 'error';
        return;
      }
      if (itm.Measure) {
        if (
          itm.Measure.MeasureType === 'money' ||
          itm.Measure.MeasureType === 'numeric'
        ) {
          if (!itm.FormatId) {
            status = 'error';
            return;
          }

          if (itm.Measure.MeasureType === 'money') {
            if (!itm.Currency) {
              status = 'error';
              return;
            }
          }

          if (itm.IsCorridor) {
            if (
              itm.Min / 1 <= 0 ||
              itm.Max / 1 <= 0 ||
              itm.Floor / 1 <= 0 ||
              itm.Cap / 1 <= 0 ||
              itm.Min / 1 >= itm.Max / 1 ||
              itm.Floor / 1 >= itm.Cap / 1
            ) {
              status = 'error';
              return;
            }
          }
        } else if (itm.Measure.MeasureType === 'scale') {
          if (itm.Scale) {
            let isOutOfRange = false;
            itm.YearlyValues.forEach((yv) => {
              if (
                yv.TargetValue &&
                (yv.TargetValue / 1 > itm.Scale.Maximum ||
                  yv.TargetValue / 1 < itm.Scale.Minimum)
              ) {
                isOutOfRange = true;
                return;
              }
              if (itm.IsCorridor) {
                if (
                  yv.TargetValue + itm.Min / 1 < itm.Scale.Minimum ||
                  yv.TargetValue + itm.Max / 1 > itm.Scale.Minimum
                ) {
                  status = 'error';
                  return;
                }
              }
            });
            if (isOutOfRange) {
              status = 'error';
              return;
            }
            if (itm.IsCorridor) {
              if (
                itm.Min / 1 > 0 ||
                itm.Max / 1 < 0 ||
                itm.Floor / 1 <= 0 ||
                itm.Cap / 1 <= 0 ||
                itm.Floor / 1 >= itm.Cap / 1
              ) {
                status = 'error';
                return;
              }
            }
          }
        }
      } //if(itm.Measure)
      else {
        status = 'error';
        return;
      }
    }
  });

  if (sum != 100) status = 'error';
  return status;
}
/**************************************************************************/
/**
 * Gets the status od Documents section
 * @param plan
 */
function getDocumentsStatus(plan) {
  let status = 'uncomplete';
  if (!plan.Attachment) return 'error';
  if (!plan.Clauses) return 'error';
  if (plan.Note && plan.Document) return 'complete';
  return status;
}
/**************************************************************************/
/**
 * Gets the status od Partecipants section
 * @param plan
 */
function getPartecipantsStatus(plan) {
  let status = 'complete';
  if (plan && plan.PartecipantsByYear.length > 0) {
    plan.PartecipantsByYear.forEach((pby) => {
      if (pby.Partecipants && pby.Partecipants.length > 0) {
        pby.Partecipants.forEach((p) => {
          if (p.GrossAnnualSalary <= 0 || p.TargetBonus <= 0) {
            status = 'error';
            return;
          }
        });
      } else {
        status = 'uncomplete';
        return;
      }
    });
  } else {
    status = 'uncomplete';
  }
  return status;
}
/****************************************************************************************************** */
export function getPlanToSave(plan, isNew): any {
  let savingPlan = {
    Id: plan.Id,
    IsActive: plan.IsActive,
    IsDisabled: plan.IsDisabled,
    IsReviewing: plan.IsReviewing,
    ReferencePlanId: plan.ReferencePlanId,
    ActivationDate: plan.ActivationDate,
    Attachment: plan.Attachment,
    Clauses: plan.Clauses,
    CompanyId: plan.CompanyId,
    CorrectiveType: plan.CorrectiveType,
    Date: plan.Date,
    Document: plan.Document,
    Name: plan.Name,
    Note: plan.Note,
    RoundBonusFlag: plan.RoundBonusFlag,
    RoundBonusValue: plan.RoundBonusValue,
    YearEnd: plan.YearEnd,
    YearStart: plan.YearStart,
    Gateways: [],
    Objectives: [],
    Correctives: [],
    PayoutStructure: plan.PayoutStructure,
    PartecipantsByYear: [],
  };

  addObjectsToPlan(savingPlan.Gateways, plan.Gateways);
  addObjectsToPlan(savingPlan.Objectives, plan.Objectives);
  addObjectsToPlan(savingPlan.Correctives, plan.Correctives);

  //ONLY FIRST YEAR
  if (isNew) {
    let pp = plan.PartecipantsByYear[0];
    if (pp) {
      addPartecipantByYear(savingPlan, pp);
    }
  } else {
    plan.PartecipantsByYear.forEach((element: any) => {
      addPartecipantByYear(savingPlan, element);
    });
  }

  return savingPlan;
}
/****************************************************************************************************** */
function addPartecipantByYear(savingPlan, pp) {
  var py = {
    Year: pp.Year,
    Partecipants: [],
  };
  pp.Partecipants.forEach((e: any) => {
    var p = {
      EmployeeId: e.Id,
      TargetBonusPercentage: e.TargetBonusPercentage,
      GrossAnnualSalary: e.GrossAnnualSalary,
    };
    py.Partecipants.push(p);
  });

  savingPlan.PartecipantsByYear.push(py);
}
/****************************************************************************************************** */
function addObjectsToPlan(array, sourceArray) {
  let idx = 0;
  sourceArray.forEach((e: any) => {
    var obj = {
      Index: idx++,
      Name: e.Name,
      GoalId: e.GoalId,
      MeasureId: e.MeasureId,
      IsCorridor: e.IsCorridor,
      Min: e.Min,
      Max: e.Max,
      ConsultationType: e.ConsultationType,
      Currency: e.Currency,
      FormatId: e.FormatId,
      ScaleId: e.ScaleId,
      Weight: e.Weight,
      Floor: e.Floor,
      Cap: e.Cap,
      YearlyValues: [],
    };

    e.YearlyValues.forEach((y) => {
      var year = {
        Year: y.Year,
        TargetValue: y.TargetValue ? y.TargetValue : -100000,
        CurrentValue: y.CurrentValue,
      };
      obj.YearlyValues.push(year);
    });

    array.push(obj);
  });
}
/****************************************************************************************************** */
class RangeValue {
  constructor(value, min, max) {
    this.value = value;
    this.min = min;
    this.max = max;
  }

  value: number;
  min: number;
  max: number;

  Add(v: RangeValue) {
    this.value += v.value;
    this.min += v.min;
    this.max += v.max;
  }
}

class ResultRangeValue extends RangeValue {
  constructor(value, min, max, result) {
    super(value, min, max);
    this.result = result;
  }
  result: number;
}

class YearPayableDeferred {
  constructor(payYear) {
    this.PayYear = payYear;
    this.Cash = new RangeValue(0, 0, 0);
    this.Equity = new RangeValue(0, 0, 0);
    this.DeferredCash = new RangeValue(0, 0, 0);
    this.DeferredEquity = new RangeValue(0, 0, 0);
  }

  PayYear: number;
  Cash: RangeValue;
  Equity: RangeValue;
  DeferredCash: RangeValue;
  DeferredEquity: RangeValue;
}

class PayableDeferred extends YearPayableDeferred {
  constructor(accrueYear, accrued: ResultRangeValue, payYear: any) {
    super(payYear.PayoutYear);
    this.AccrueYear = accrueYear;
    if (accrued) {
      this.Cash.value = (accrued.value * payYear.Cash) / 100;
      this.Cash.min = (accrued.min * payYear.Cash) / 100;
      this.Cash.max = (accrued.max * payYear.Cash) / 100;

      this.Equity.value = (accrued.value * payYear.Equity) / 100;
      this.Equity.min = (accrued.min * payYear.Equity) / 100;
      this.Equity.max = (accrued.max * payYear.Equity) / 100;

      let deffered: RangeValue = new RangeValue(0, 0, 0);
      deffered.value = accrued.value - this.Equity.value - this.Cash.value;
      deffered.min = accrued.min - this.Equity.min - this.Cash.min;
      deffered.max = accrued.max - this.Equity.max - this.Cash.max;

      if (payYear.Cash + payYear.Equity > 0) {
        this.DeferredCash.value =
          (deffered.value * payYear.Cash) / (payYear.Cash + payYear.Equity);
        this.DeferredCash.min =
          (deffered.min * payYear.Cash) / (payYear.Cash + payYear.Equity);
        this.DeferredCash.max =
          (deffered.max * payYear.Cash) / (payYear.Cash + payYear.Equity);

        this.DeferredEquity.value =
          (deffered.value * payYear.Equity) / (payYear.Cash + payYear.Equity);
        this.DeferredEquity.min =
          (deffered.min * payYear.Equity) / (payYear.Cash + payYear.Equity);
        this.DeferredEquity.max =
          (deffered.max * payYear.Equity) / (payYear.Cash + payYear.Equity);
      }
    }
  }
  AccrueYear: number;
}

class PartecipantValueContainer {
  constructor() {
    this.TargetBonus = 0.0;
    this.Admitted = new ResultRangeValue(0, 0, 0, 0);
    this.Accrued = new ResultRangeValue(0, 0, 0, 0);

    this.BonusBankOpening = new ResultRangeValue(0, 0, 0, 0);
    this.BonusBankBase = new ResultRangeValue(0, 0, 0, 0);
    this.BonusBankClosing = new ResultRangeValue(0, 0, 0, 0);
    this.PaybleDeferredArray = [];
  }
  TargetBonus: number;
  Admitted: ResultRangeValue;
  Accrued: ResultRangeValue;
  Deferred: ResultRangeValue;
  PaybleDeferredArray: PayableDeferred[];
  BonusBankOpening: ResultRangeValue;
  BonusBankBase: ResultRangeValue;
  BonusBankClosing: ResultRangeValue;
}

class KeyTotalMap {
  Map(item: PayableDeferred) {
    let key = item.PayYear + '_' + item.AccrueYear;

    if (!this[item.PayYear]) {
      this[item.PayYear] = new YearPayableDeferred(item.PayYear);
    }
    if (!this[key]) {
      this[key] = new YearPayableDeferred(item.PayYear);
    }

    this[item.PayYear].Cash.Add(item.Cash);
    this[item.PayYear].Equity.Add(item.Equity);
    this[item.PayYear].DeferredCash.Add(item.DeferredCash);
    this[item.PayYear].DeferredEquity.Add(item.DeferredEquity);

    this[key].Cash.Add(item.Cash);
    this[key].Equity.Add(item.Equity);
    this[key].DeferredCash.Add(item.DeferredCash);
    this[key].DeferredEquity.Add(item.DeferredEquity);
  }
}

/****************************************************************************************************** 
* Calculate all bonuses:
* The funcion:
* 1) calculates for each Partecipant and for each accrued year
*     - TargetBonus
*------------------------------------------------------------------------
* 2) attaches to Partecipants for each Partecipant and for each accrued year
*     - Values: i.e. an entity containing these infos
          TargetBonus:number;
          Admitted:  ResultRangeValue;
          Accrued: ResultRangeValue;
          Deferred: ResultRangeValue;
          PaybleDeferredArray:PayableDeferred[];  
      where ResultRangeValue contains value, min and max
            PaybleDeferredArray is an array where each element has payout year, Cash, Equity payed and deferred
*------------------------------------------------------------------------
* 3) adds some fields to Plan datamodel:
*------------------------------------------------------------------------
*  - ByPayoutYearTotals
*     It is a Map containing the sum of Cash, Equity, DefferedCash, DeferredEquity.
*     This map has these sums grouped by two criteria
*      - payout year
*      - couple accruedyear,payoutyear
*------------------------------------------------------------------------
*  - Totalbonus is the sum for all years and partecipants. It has these details: Target, Admitted, Accrued
*------------------------------------------------------------------------
*  - Bonuses:
*     an array where each element is calculate for an accrued year and contains:
*     Year, Target, Admitted, Accrued, Payable
*     note: Payable is a map by paybale year of Cash and Equity
*------------------------------------------------------------------------
* 
* @param p the plan
*/
function calculateBonuses(p) {
  let bonuses = [];
  let totalBonus = {
    Target: 0.0,
    Admitted: new RangeValue(0, 0, 0),
    Accrued: new RangeValue(0, 0, 0),
  };
  let lastYear = 0;
  let lastYearBonus: any;

  if (!p.PartecipantsByYear) return;

  let byYearMap: KeyTotalMap = new KeyTotalMap();
  p.ByPayoutYearTotals = byYearMap; //=> Attached to Plan

  p.PartecipantsByYear.forEach((pby) => {
    let yearlyBonus = {
      Year: pby.Year,
      Target: 0.0,
      Admitted: new RangeValue(0, 0, 0),
      Accrued: new RangeValue(0, 0, 0),
      Payable: {},
    };

    let multiplierByYear = undefined;

    if (p.Multipliers)
      multiplierByYear = p.Multipliers.ByYear.find((x) => x.year == pby.Year);

    let payByAccrueYear = p.PayoutStructure.Rows.find(
      (z) => z.AccrueYear == pby.Year
    );

    pby.Partecipants.forEach((part) => {
      part.Values = new PartecipantValueContainer(); //=> Attached to Partecipant

      part.TargetBonus =
        (part.TargetBonusPercentage / 100) * part.GrossAnnualSalary; //=> Calculated for Partecipant

      if (!isNaN(part.TargetBonus) && p.RoundBonusValue > 0) {
        let d = p.RoundBonusValue;
        let n = Math.round((part.TargetBonus * 1000) / d) / 1000;
        let floor = Math.floor(n);
        switch (p.RoundBonusFlag) {
          case 'Up':
            if (floor < n) {
              part.TargetBonus = (floor + 1) * d;
            }
            break;
          case 'Down':
            part.TargetBonus = floor * d;
            break;
        }
      }
      part.Values.TargetBonus = part.TargetBonus;

      if (multiplierByYear && multiplierByYear.multipliers.gateways) {
        part.Values.Admitted.value =
          part.TargetBonus * multiplierByYear.multipliers.gateways.value;
        part.Values.Admitted.min =
          part.TargetBonus * multiplierByYear.multipliers.gateways.min;
        part.Values.Admitted.max =
          part.TargetBonus * multiplierByYear.multipliers.gateways.max;
      } else {
        part.Values.Admitted.value = part.TargetBonus;
        part.Values.Admitted.min = part.TargetBonus;
        part.Values.Admitted.max = part.TargetBonus;
      }
      if (multiplierByYear && multiplierByYear.multipliers.objectives) {
        part.Values.Accrued.value =
          part.Values.Admitted.value *
          multiplierByYear.multipliers.objectives.value;
        part.Values.Accrued.min =
          part.Values.Admitted.min *
          multiplierByYear.multipliers.objectives.min;
        part.Values.Accrued.max =
          part.Values.Admitted.max *
          multiplierByYear.multipliers.objectives.max;
      } else {
        part.Values.Accrued.value = part.Values.Admitted.value;
        part.Values.Accrued.min = part.Values.Admitted.min;
        part.Values.Accrued.max = part.Values.Admitted.max;
      }

      if (payByAccrueYear) {
        payByAccrueYear.PayoutValues.forEach((payyear) => {
          let payableDeferredItem: PayableDeferred = new PayableDeferred(
            pby.Year,
            part.Values.Accrued,
            payyear
          );
          byYearMap.Map(payableDeferredItem);
          part.Values.PaybleDeferredArray.push(payableDeferredItem);
        }); // forEach PayoutValues
      }

      yearlyBonus.Target += part.TargetBonus;
      if (part.Values.Admitted) {
        yearlyBonus.Admitted.Add(part.Values.Admitted);
      }
      if (part.Values.Accrued) {
        yearlyBonus.Accrued.Add(part.Values.Accrued);
      }
      if (part.Values.PaybleDeferredArray) {
        part.Values.PaybleDeferredArray.forEach((x) => {
          if (!yearlyBonus.Payable[x.PayYear])
            yearlyBonus.Payable[x.PayYear] = new YearPayableDeferred(x.PayYear);
          yearlyBonus.Payable[x.PayYear].Cash.value += x.Cash.value;
          yearlyBonus.Payable[x.PayYear].Equity.value += x.Equity.value;
        });
      }

      totalBonus.Target += part.TargetBonus;
      if (part.Values.Admitted) totalBonus.Admitted.Add(part.Values.Admitted);
      if (part.Values.Accrued) totalBonus.Accrued.Add(part.Values.Accrued);
    }); // forEach Partecipants

    if (pby.Year > lastYear) {
      lastYear = pby.Year;
      lastYearBonus = yearlyBonus;
    }
    bonuses.push(yearlyBonus);
  });

  let missingYear = p.YearEnd - p.YearStart + 1 - p.PartecipantsByYear.length;
  if (missingYear > 0) {
    //assumes the last year valid for missing years
    totalBonus.Target += lastYearBonus.Target * missingYear;
    for (var n = 0; n < missingYear; n++) {
      totalBonus.Admitted.Add(lastYearBonus.Admitted);
      totalBonus.Accrued.Add(lastYearBonus.Accrued);
    }
  }

  p.TotalBonus = totalBonus; //=> Attached to Plan

  p.Bonuses = bonuses; //=> Attached to Plan

  calculateMinMax(p);
}
/******************************************************************************************************
 * Calculates minimum and maximum
 * This function attaches to Plan TargetMinMax
 *
 *
 * @param p the plan
 */
function calculateMinMax(p: any) {
  if (!p) return;
  let Total: RangeValue = new RangeValue(0, 0, 0);
  p.TargetMinMax = {
    Total: Total,
  };

  const correctiveMultiplier = p.Multipliers.multipliers.correctives;

  const numPayYears =
    p.PayoutStructure.PayoutYearEnd - p.PayoutStructure.PayoutYearStart + 1;
  const numPlanYears = p.YearEnd - p.YearStart + 1;
  let pn = new RangeValue(0, 0, 0);
  let past = new RangeValue(0, 0, 0);

  for (var ay = p.YearStart; ay <= p.YearEnd; ay++) {
    let valByYear = new RangeValue(0, 0, 0);
    let valByYearPast = new RangeValue(0, 0, 0);
    let accrued: any;
    let accruedByYear = p.Bonuses.find((x) => x.Year === ay);
    if (!accruedByYear) {
      accrued = p.Bonuses[0].Accrued;
    } else {
      accrued = accruedByYear.Accrued;
    }
    for (
      var py = p.PayoutStructure.PayoutYearStart;
      py <= p.PayoutStructure.PayoutYearEnd;
      py++
    ) {
      if (py >= ay) {
        let row = p.PayoutStructure.Rows.find((x) => x.AccrueYear === ay);
        if (row) {
          let perc = row.PayoutValues.find((x) => x.PayoutYear === py);
          if (perc) {
            let cash = parseFloat(perc.Cash);
            let equity = parseFloat(perc.Equity);
            let pc = (cash + equity) / 100;
            if (p.CorrectiveType === 'Rolling') {
              //Rolling
              if (py === ay) {
                valByYear.value += accrued.value * pc;
                valByYear.min += accrued.min * pc;
                valByYear.max += accrued.max * pc;
              } else {
                const deltaY = py - ay;
                const power = Math.min(deltaY, numPlanYears - 1);
                let cmin = Math.pow(correctiveMultiplier.min, power);
                let cmax = Math.pow(correctiveMultiplier.max, power);
                if (isNaN(cmin)) cmin = 1;
                if (isNaN(cmax)) cmax = 1;
                valByYearPast.value += accrued.value * pc;
                valByYearPast.min += accrued.min * pc * cmin;
                valByYearPast.max += accrued.max * pc * cmax;
              }
            } else {
              //final

              if (py < p.YearEnd || (ay == p.YearEnd && py == p.YearEnd)) {
                //before
                valByYear.value += accrued.value * pc;
                valByYear.min += accrued.min * pc;
                valByYear.max += accrued.max * pc;
              } else {
                //after
                valByYearPast.value += accrued.value * pc;
                let cmin = correctiveMultiplier.min;
                let cmax = correctiveMultiplier.max;
                if (isNaN(cmin)) cmin = 1;
                if (isNaN(cmax)) cmax = 1;
                if (!isNaN(correctiveMultiplier.min))
                  valByYearPast.min += accrued.min * pc * cmin;
                if (!isNaN(correctiveMultiplier.max))
                  valByYearPast.max += accrued.max * pc * cmax;
              }
            }
          } // if(perc)
        } //if(row)
      } // if(py>=ay)
    } //for(var py

    let n: RangeValue = new RangeValue(0, 0, 0);
    n.Add(valByYear);
    n.Add(valByYearPast);
    Total.Add(n);
    p.TargetMinMax[ay] = n;
  } // for(var ay
}
/******************************************************************************************************
 * Calculates the previous year Cash and Equity
 * This function attaches to Plan
 * - PastYears:
 *    it is a Map having as key the payout year
 *
 *
 * @param p the plan
 */
function calculatePreviousYearsPayables(p: any) {
  if (p) {
    p.PastYears = {}; //=> Attached to Plan

    for (let y = p.YearStart; y <= p.PayoutStructure.PayoutYearEnd; y++) {
      let sumCash = 0;
      let sumEquity = 0;
      let pMulti = 1;
      for (let payY = p.YearStart; payY < y; payY++) {
        let bonus = p.Bonuses.find((x) => x.Year == payY);
        if (bonus) {
          let admitted = bonus.Admitted;
          let accrueYearItem = p.PayoutStructure.Rows.find(
            (x) => x.AccrueYear == payY
          );
          if (accrueYearItem) {
            var multi = p.Multipliers.ByYear.find((x) => x.year == payY);
            let m = 1;
            if (multi) {
              m = multi.multipliers.correctives
                ? multi.multipliers.correctives.value
                : 1;
              pMulti *= m;
            }

            var item = accrueYearItem.PayoutValues.find(
              (x) => x.PayoutYear == y
            );
            var c = item.Cash / 100;
            var e = item.Equity / 100;
            sumCash += admitted * c;
            sumEquity += admitted * e;
          }
        }
      }

      p.PastYears[y] = { Cash: sumCash * pMulti, Equity: sumEquity * pMulti };
    }
  }
}
/******************************************************************************************************
 * Calculates all multipliers
 * This function attaches to Plan the object Multipliers.
 * This object contains
 * - the average values (min and max) for gateways, objectives and correctives
 * - a List year by year of multiplier for gateways, objectives and correctives
 *
 * @param p LTI plan
 */
function calculateAllMultipliers(p, useCurrentValues) {
  let grandMultipliers = {
    Gateways: {},
    Objectives: {},
    Correctives: {},
  };
  addMultiplier(p.Gateways, grandMultipliers.Gateways, p, useCurrentValues);
  addMultiplier(p.Objectives, grandMultipliers.Objectives, p, useCurrentValues);
  addMultiplier(
    p.Correctives,
    grandMultipliers.Correctives,
    p,
    useCurrentValues
  );

  let returnObject = {
    multipliers: {
      gateways: grandMultipliers.Gateways['Average'],
      objectives: grandMultipliers.Objectives['Average'],
      correctives: grandMultipliers.Correctives['Average'],
    },
    ByYear: [],
  };
  let byYaerMultiplierList = [];
  for (var y = p.YearStart; y <= p.YearEnd; y++) {
    let m = {
      year: y,
      multipliers: {
        gateways: { value: 0.0, min: 0.0, max: 0.0 },
        objectives: { value: 0.0, min: 0.0, max: 0.0 },
        correctives: { value: 0.0, min: 0.0, max: 0.0 },
      },
    };

    m.multipliers.gateways = grandMultipliers.Gateways[y];
    m.multipliers.objectives = grandMultipliers.Objectives[y];
    m.multipliers.correctives = grandMultipliers.Correctives[y];

    byYaerMultiplierList.push(m);
  }

  returnObject.ByYear = byYaerMultiplierList;
  p.Multipliers = returnObject;
}
/****************************************************************************************************** */
/**
 * Adds multiplier infos to GrandMultiplier object
 *  the GrandMultiplier object contains
 *   - a Map for each accrued Year having value, min e max of multiplier
 *   - the average (value, min, max) of multipleirs over all objects
 * moreover the function attaches to object/yearlyvalue
 *   - mutiplier/wightedmultiplier
 *
 * @param list list of object to scan: can be Gateway, Objective or Corrective
 * @param m  GrandMultiplier object
 * @param p  LTI plan
 */
function addMultiplier(list, m, p, useCurrentValues) {
  let avgMultiplier = { value: 0.0, min: 0.0, max: 0.0, count: 0 };

  list.forEach((o) => {
    calcValue(o);
    if (o.YearlyValues) {
      o.YearlyValues.forEach((y) => {
        if (!m[y.Year]) {
          m[y.Year] = {
            value: 0.0,
            min: 0.0,
            max: 0.0,
          };
        }

        if (useCurrentValues) {
          //In this case the Results are set
          y.multiplier = getMultiplier(o, y); //=> Attached to YearlyValue
        } else {
          //In this case the Results are undefined
          y.multiplier = getPartialMultiplier(o, y); //=> Attached to YearlyValue
        }

        y.weightedmultiplier = getWeightedMultiplier(
          y.multiplier,
          o.Weight / 100
        ); //=> Attached to YearlyValue

        m[y.Year].value += y.weightedmultiplier.value;
        m[y.Year].min += y.weightedmultiplier.min;
        m[y.Year].max += y.weightedmultiplier.max;
        avgMultiplier.value += y.weightedmultiplier.value;
        avgMultiplier.min += y.weightedmultiplier.min;
        avgMultiplier.max += y.weightedmultiplier.max;
        avgMultiplier.count += o.Weight / 100;
      }); //o.YearlyValues.forEach
    }
  });

  avgMultiplier.value /= avgMultiplier.count;
  avgMultiplier.min /= avgMultiplier.count;
  avgMultiplier.max /= avgMultiplier.count;
  m['Average'] = avgMultiplier;
}
/****************************************************************************************************** */
/**
 * Calculates Target and Current values
 * @param o
 */
function calcValue(o) {
  let rollingsum = 0.0;
  let counter = 0;
  let rollingsum_current = 0.0;
  let counter_current = 0;
  if (o.YearlyValues) {
    o.YearlyValues.forEach((year) => {
      rollingsum += year.TargetValue;
      counter++;
      if (year.CurrentValue) {
        rollingsum_current += year.CurrentValue / 1;
      }
      switch (o.ConsultationType) {
        case 'Average': //Avegarage
          year.calcTargetValue = rollingsum / counter;
          year.calcCurrentValue = rollingsum_current / counter;
          break;
        case 'Punctual': //Punctual
          year.calcTargetValue = year.TargetValue;
          year.calcCurrentValue = year.CurrentValue / 1;
          break;
        case 'Cumulative': //Cumulative
          year.calcTargetValue = rollingsum;
          year.calcCurrentValue = rollingsum_current;
          break;
      }
    });
  }
}
/****************************************************************************************************** */
/**
 * Get multipliers without current values
 * @param o
 * @param year
 */
function getPartialMultiplier(o, year) {
  let multiplier = {
    value: 1.0,
    min: 0.0,
    max: 0.0,
  };

  if (o.IsCorridor === false) {
    multiplier.min = 1;
  } else {
    multiplier.min = o.Floor / 100;
  }

  if (o.IsCorridor === false) {
    multiplier.max = 1;
  } else {
    multiplier.max = o.Cap / 100;
  }
  return multiplier;
}

/****************************************************************************************************** */
/**
 * Calculates a multiplier with current value
 * @param o
 * @param year
 */
function getMultiplier(o, year) {
  let multiplier = {
    value: 0.0,
    min: 0.0,
    max: 0.0,
  };

  if (year.CurrentValue != null && year.TargetValue != null) {
    if (o.Measure && o.Measure.MeasureType === 'scale') {
      if (year.calcCurrentValue < year.calcTargetValue + o.Min) {
        multiplier.value = 0;
      } else {
        if (o.IsCorridor === false) {
          multiplier.value = 1;
        } else {
          if (
            year.calcCurrentValue >= year.calcTargetValue - o.Min &&
            year.calcCurrentValue <= year.calcTargetValue
          ) {
            multiplier.value =
              o.Floor / 100 +
              ((year.calcCurrentValue - (year.calcTargetValue - o.Min)) /
                (year.calcTargetValue - (year.calcTargetValue - o.Min))) *
                (1 - o.Floor / 100);
          } else {
            let m =
              1 +
              ((year.calcCurrentValue - year.calcTargetValue) /
                (o.Max + year.calcTargetValue - year.calcTargetValue)) *
                (o.Cap / 100 - 1);
            multiplier.value = Math.min(m, o.Cap / 100);
          }
        }
      }
    } else {
      // not "scale"
      let min = o.Min / 100;
      let max = o.Max / 100;
      let floor = o.Floor / 100;
      let cap = o.Cap / 100;
      if (o.IsCorridor == false) {
        min = 1;
        max = 1;
      }

      if (year.calcCurrentValue < year.calcTargetValue * min) {
        multiplier.value = 0;
      } else {
        if (o.IsCorridor == false) {
          multiplier.value = 1;
        } else {
          if (
            year.calcCurrentValue >= year.calcTargetValue * min &&
            year.calcCurrentValue <= year.calcTargetValue
          ) {
            multiplier.value =
              floor +
              ((year.calcCurrentValue - year.calcTargetValue * min) /
                (year.calcTargetValue - year.calcTargetValue * min)) *
                (1 - floor);
          } else {
            let m =
              1 +
              ((year.calcCurrentValue - year.calcTargetValue) /
                (max * year.calcTargetValue - year.calcTargetValue)) *
                (cap - 1);
            multiplier.value = Math.min(m, cap);
          }
        }
      }
    }
  }
  let pm = getPartialMultiplier(o, year);
  multiplier.min = pm.min;
  multiplier.max = pm.max;
  return multiplier;
}
/****************************************************************************************************** */
/**
 * Calculates a weigthed multiplier
 * @param o
 * @param year
 */
function getWeightedMultiplier(multiplier, weigth) {
  let wmultiplier = {
    value: multiplier.value * weigth,
    min: multiplier.min * weigth,
    max: multiplier.max * weigth,
  };

  return wmultiplier;
}
