import {
  Component,
  OnInit,
  Input,
  HostListener,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ScreenSizeService } from 'app/shared/services/screen-size.service';
import { CoreService } from 'app/shared/services/core.service';
import { Subject } from 'rxjs';
@Component({
  selector: 'ask-filter-dropdown',
  templateUrl: './ask-filter-dropdown.component.html',
  styleUrls: ['./ask-filter-dropdown.component.scss'],
})
export class AskFilterDropdownComponent implements OnInit {
  @Input() skillList: any;
  @Input() checkType: any;
  @Input() selectedEmp: any;
  @Input() type: string;
  @Input() searchManually: boolean = false;

  //searchBox
  searchable = false;
  searchSkillText: string;
  serachTextChanged: Subject<string> = new Subject<string>();
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
  filteredSkills: any[] = [];

  @Output() valueChange = new EventEmitter();
  @Output() valueChang = new EventEmitter();

  counter = 0;
  check: any;
  show = false;
  eventMendatory: number = 0;
  showMandatory: boolean = false;
  filteredList: any[] = [];
  subArray: any[] = [];
  mainArray = [];
  subArrayId = [];
  isMobile: boolean;

  constructor(
    public coreService: CoreService,
    public screenSizeService: ScreenSizeService,
    private _renderer: Renderer2
  ) {
    this.check = this.skillList;
    this.screenSizeService.ScreenSize.subscribe((res: number) => {
      this.isMobile = res <= 991;
    });
  }

  ngOnInit(): void {}

  clickit($event) {
    this.filteredList = this.skillList;
    $event.stopPropagation();
    this.show = !this.show;
    this.searchable = false;
  }

  shows(candidate, mainArra) {
    this.skillList = this.filteredList;
    if (this.subArray) {
      for (let i of this.subArray) {
        if (i.Id == candidate.Id) {
          return this.hide(candidate.Id);
        }
      }
    }
    this.mainArray = mainArra;
    this.subArray.push(candidate);

    this.subArray = [...new Set(this.subArray)];

    for (let items of this.subArray) {
      this.subArrayId.push(items.Id);
    }
    this.subArrayId = [...new Set(this.subArrayId)];

    if (this.subArray.length > 1) {
      // this.searchSkillText = `${this.subArray.length} skills evaluted`;
      this.checkEvaluted();
    } else if (this.subArray.length == 1) {
      this.searchSkillText = this.subArray[0]['Name'];
    } else if (this.subArray.length == 0) {
      this.searchSkillText = '';
    }

    if (this.subArray.length == 0) {
      this.eventMendatory = 1;
    } else {
      this.eventMendatory = 0;
    }
    // this.searchable = true;
    // this.searchManually = true;
    if (this.searchable == true) {
      this.searchSkillText = '';
      this.searchInput.nativeElement.focus();
    }
    this.valueChange.emit(this.subArray);
  }

  hide(removeEle) {
    for (let i in this.subArray) {
      if (this.subArray[i]['Id'] == removeEle) {
        this.subArray.splice(Number(i), 1);
      }
    }
    this.subArrayId.forEach((element, index) => {
      if (element == removeEle) this.subArrayId.splice(index, 1);
    });

    if (this.subArray.length > 1) {
      // this.searchSkillText = `${this.subArray.length} skills evaluted`;
      this.checkEvaluted();
    } else if (this.subArray.length == 1) {
      this.searchSkillText = this.subArray[0]['Name'];
    } else if (this.subArray.length == 0) {
      this.searchSkillText = '';
    }
    if (this.subArray.length == 0) {
      this.eventMendatory = 1;
    } else {
      this.eventMendatory = 0;
    }
    this.valueChang.emit(this.subArray);
    this.searchable = false;
  }
  checkEvaluted() {
    if (this.checkType == 'goal') {
      this.searchSkillText =
        this.coreService.getTranslation('objectivesEvaluated_Pre') +
        `${this.subArray.length} ` +
        this.coreService.getTranslation('objectivesEvaluated_Post_s');
    } else if (this.checkType == 'behavior') {
      this.searchSkillText =
        this.coreService.getTranslation('behaviorsEvaluated_Pre') +
        `${this.subArray.length} ` +
        this.coreService.getTranslation('behaviorsEvaluated_Post_s');
    } else {
      this.searchSkillText =
        this.coreService.getTranslation('skillsEvaluted_Pre') +
        `${this.subArray.length} ` +
        this.coreService.getTranslation('skillsEvaluted_Post_s');
    }
  }
  onClickTest($event) {
    $event.stopPropagation();
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  canSearch(event) {
    event.stopPropagation();
    this.searchable = true;
    this.searchManually = true;
    this.searchSkillText = '';
    this.searchInput.nativeElement.focus();
    this._renderer.removeAttribute(this.searchInput.nativeElement, 'readonly');
  }

  filterSkills($event) {
    let value = $event.target.value;
    let filterItem = [];
    if (!value && value == '') {
      this.skillList = this.filteredList;
    }
    if (value && value.length > 0) {
      this.skillList.forEach((item: any) => {
        if (item.Name.toUpperCase().startsWith(value.toUpperCase())) {
          filterItem.push(item);
        }
      });
      this.skillList = filterItem;
    }
  }

  backClose() {
    this.show = false;
    this.checkEvaluted();
  }
}
