import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ManagerOkrPopupComponent } from '../manager-okr-popup/manager-okr-popup.component';
import { EngagementPopupPlusComponent } from '../engagement-popup-plus/engagement-popup-plus.component';
import { CoreService } from 'app/shared/services/core.service';
import { Pp360SpinnerService } from 'app/pp360-components/pp360-spinner/pp360-spinner.service';
import {
  CdkDragEnter,
  CdkDropList,
  DragRef,
  moveItemInArray,
} from '@angular/cdk/drag-drop';

@Component({
  selector: 'okrs',
  templateUrl: './okrs.component.html',
  styleUrls: ['./okrs.component.scss'],
})
export class Okrs implements OnInit, AfterViewInit {
  bsModalRef: BsModalRef;
  @Output() refreshRequired = new EventEmitter();
  showHide: boolean = false;
  deletedItem: any = [];
  private target: CdkDropList = null;
  private targetIndex: number;
  private source: CdkDropList = null;
  @ViewChild(CdkDropList) placeholder: CdkDropList;
  private sourceIndex: number;
  private dragRef: DragRef = null;
  okrInfo: any = [];
  boxWidth = '200px';
  boxHeight = '200px';
  initialLoad = false;
  constructor(
    private modalService: BsModalService,
    public coreService: CoreService,
    public pp360SpinnerService: Pp360SpinnerService
  ) {}

  ngOnInit(): void {
    this.getOkrData();
  }

  ngAfterViewInit(): void {}
  getOkrData() {
    this.pp360SpinnerService.showSpinner(true);
    this.coreService.getManagerEnteryPointDetail().subscribe(
      (data: any) => {
        this.okrInfo = data.OKRs;
        this.deletedItem = data.HiddenBoxes.OKRs;
        // console.log(this.okrInfo);
        // for(let item of this.okrInfo){
        //   if(item.Key === "O3"){
        //     item.Value=0;
        //   }
        // }
        for (let item of this.okrInfo) {
          if (
            item.Key === 'O2' ||
            item.Key === 'O3' ||
            item.Key === 'O5' ||
            item.Key === 'O9'
          ) {
            if (item.Value > 0) {
              item.HasDetails = true;
            }
            if (item.Value == 0) {
              item.HasDetails = false;
              item.Status = 'Positive';
            }
          }
        }
        this.pp360SpinnerService.showSpinner(false);
        setTimeout(() => {
          const placeholderElement = this.placeholder?.element.nativeElement;
          if (placeholderElement) {
            placeholderElement.style.display = 'none';
            placeholderElement.parentNode?.removeChild(placeholderElement);
          }
        }, 10);
      },
      (err: any) => {
        this.coreService.toasterMessage('error', 'manager', err.Message);
        this.pp360SpinnerService.showSpinner(false);
      }
    );
  }
  openPopup(item: any) {
    this.coreService
      .getManagerEnteryPointBoxDetail(item.Key)
      .subscribe((data: any) => {
        if (
          item.Key === 'O1' ||
          item.Key === 'O2' ||
          item.Key === 'O3' ||
          item.Key === 'O6' ||
          item.Key === 'O9' ||
          item.Key === 'O7' ||
          item.Key === 'O8' ||
          item.Key === 'O4' ||
          item.Key === 'O5'
        ) {
          this.bsModalRef = this.modalService.show(ManagerOkrPopupComponent, {
            class: 'okr-top-align-pop',
            ignoreBackdropClick: false,
            initialState: {
              response: data,
            },
          });
          this.bsModalRef.content.closeBtnName = 'Close';
          this.showHide = false;
        }
      });
  }
  setBoxPriority() {
    const body = this.okrInfo.map((eachOkr) => {
      return eachOkr.Key;
    });
    this.coreService.setPriorityBox(body).subscribe((data: any) => {
      console.log(data);
    });
  }

  refreshWall(data) {
    this.refreshRequired.emit(data);
  }

  openPopupPlus() {
    this.bsModalRef = this.modalService.show(EngagementPopupPlusComponent, {
      class: 'top-align',
      ignoreBackdropClick: false,
      initialState: {
        response: this.deletedItem,
        type: 'okrs',
      },
    });
    this.bsModalRef.content.onClose.subscribe((data: any) => {
      if (data == 'added') {
        this.refreshWall(data);
        this.getOkrData();
        this.bsModalRef.hide();
      }
    });
    this.bsModalRef.content.closeBtnName = 'Close';
    this.showHide = false;
  }
  removeItem(item) {
    let body = [item.Value, item.Key];
    if (item) {
      this.pp360SpinnerService.showSpinner(true);
      this.coreService.deleteExcludeBox(body).subscribe(
        (data: any) => {
          this.getOkrData();
          this.pp360SpinnerService.showSpinner(false);
        },
        (err: any) => {
          this.coreService.toasterMessage('error', 'manager', err.Message);
          this.pp360SpinnerService.showSpinner(false);
        }
      );
    }
  }

  onDropListDropped() {
    if (!this.target) {
      return;
    }

    const placeholderElement: HTMLElement =
      this.placeholder.element.nativeElement;
    const placeholderParentElement: HTMLElement =
      placeholderElement.parentElement;

    placeholderElement.style.display = 'none';

    placeholderParentElement.removeChild(placeholderElement);
    placeholderParentElement.appendChild(placeholderElement);
    placeholderParentElement.insertBefore(
      this.source.element.nativeElement,
      placeholderParentElement.children[this.sourceIndex]
    );

    if (this.placeholder._dropListRef.isDragging()) {
      this.placeholder._dropListRef.exit(this.dragRef);
    }

    this.target = null;
    this.source = null;
    this.dragRef = null;

    if (this.sourceIndex !== this.targetIndex) {
      moveItemInArray(this.okrInfo, this.sourceIndex, this.targetIndex);
      this.setBoxPriority();
    }
  }

  onDropListEntered({ item, container }: CdkDragEnter) {
    if (container == this.placeholder) {
      return;
    }

    const placeholderElement: HTMLElement =
      this.placeholder.element.nativeElement;
    const sourceElement: HTMLElement = item.dropContainer.element.nativeElement;
    const dropElement: HTMLElement = container.element.nativeElement;
    const dragIndex: number = Array.prototype.indexOf.call(
      dropElement.parentElement.children,
      this.source ? placeholderElement : sourceElement
    );
    const dropIndex: number = Array.prototype.indexOf.call(
      dropElement.parentElement.children,
      dropElement
    );

    if (!this.source) {
      this.sourceIndex = dragIndex;

      this.source = item.dropContainer;

      placeholderElement.style.width = this.boxWidth + 'px';
      placeholderElement.style.height = this.boxHeight + 40 + 'px';

      sourceElement.parentElement.removeChild(sourceElement);
    }

    this.targetIndex = dropIndex;

    this.target = container;
    this.dragRef = item._dragRef;

    placeholderElement.style.display = '';

    dropElement.parentElement.insertBefore(
      placeholderElement,
      dropIndex > dragIndex ? dropElement.nextSibling : dropElement
    );

    this.placeholder._dropListRef.enter(
      item._dragRef,
      item.element.nativeElement.offsetLeft,
      item.element.nativeElement.offsetTop
    );
  }
}
