import { Injectable, TemplateRef } from '@angular/core';
import { RestfulService } from '../shared/services/restful.service';

import * as moment from 'moment';

@Injectable()
export class CompanyReportService {
  openObj: any[] = [];
  constructor(public restfulWebService: RestfulService) {}

  checkObjective(data) {
    if (this.openObj.length == 0) {
      this.openObj.push(data);
      return 'done';
    } else {
      const item = this.openObj.filter((element) => element == data);
      if (item.length > 0) {
        return 'match';
      } else {
        this.openObj.push(data);
        return 'done';
      }
    }
  }

  getObjective() {
    return this.openObj;
  }

  removeEle(data) {
    let a = this.openObj.filter((item) => item !== data);
    this.openObj = a;
  }

  // Common
  getFilters() {
    return this.restfulWebService.get(`AnalyticsManager/Filter`);
  }
  getTeamFilters() {
    return this.restfulWebService.get(`AnalyticsTeamManager/Filter`);
  }

  /* Performance */
  postSummary(data) {
    return this.restfulWebService.post(`AnalyticsManager/Summary`, data);
  }
  postTeamSummary(data) {
    return this.restfulWebService.post(`AnalyticsTeamManager/Summary`, data);
  }
  postSummaryBySkill(skillId, data) {
    return this.restfulWebService.post(
      `AnalyticsManager/SummarySkill?SkillId=${skillId}`,
      data
    );
  }
  postTeamSummaryBySkill(skillId, data) {
    return this.restfulWebService.post(
      `AnalyticsTeamManager/SummarySkill?SkillId=${skillId}`,
      data
    );
  }
  postSummaryByTeam(skillId, data) {
    return this.restfulWebService.post(
      `AnalyticsManager/SummarySkillByTeam?SkillId=${skillId}`,
      data
    );
  }
  postTeamSummaryByTeam(skillId, data) {
    return this.restfulWebService.post(
      `AnalyticsTeamManager/SummarySkillByTeam?SkillId=${skillId}`,
      data
    );
  }

  /* Compare */
  getCompareFilter() {
    return this.restfulWebService.get(`AnalyticsManager/CompareFilter`);
  }
  getManagerCompareFilter() {
    return this.restfulWebService.get(`AnalyticsTeamManager/CompareFilter`);
  }
  getCurrentEmployeeCompareFilter() {
    return this.restfulWebService.get(`AnalyticsEmployeeManager/CompareFilter`);
  }
  postCompare(comparison) {
    return this.restfulWebService.post(`AnalyticsManager/Compare`, comparison);
  }
  postTeamCompare(comparison) {
    return this.restfulWebService.post(
      `AnalyticsTeamManager/Compare`,
      comparison
    );
  }

  postCompareToRole(comparison) {
    return this.restfulWebService.post(
      `AnalyticsManager/CompareToRole`,
      comparison
    );
  }
  postTeamCompareToRole(comparison) {
    return this.restfulWebService.post(
      `AnalyticsTeamManager/CompareToRole`,
      comparison
    );
  }
  postEmployeeCompareToRole(comparison) {
    return this.restfulWebService.post(
      `AnalyticsEmployeeManager/CompareToRole`,
      comparison
    );
  }

  /* Engagement */
  postEngagementByEmployees(data) {
    return this.restfulWebService.post(
      `AnalyticsManager/EngagementByEmployees`,
      data
    );
  }
  postEngagementByTeams(data) {
    return this.restfulWebService.post(
      `AnalyticsManager/EngagementByTeams`,
      data
    );
  }
  postEngagementByFeedback(data) {
    return this.restfulWebService.post(
      `AnalyticsManager/EngagementByFeedback`,
      data
    );
  }
  postEngagementByFeedbackByTeam(data) {
    return this.restfulWebService.post(
      `AnalyticsManager/EngagementByFeedbackByTeam`,
      data
    );
  }
  postEngagementByToken(data) {
    return this.restfulWebService.post(
      `AnalyticsManager/EngagementByToken`,
      data
    );
  }
  postEngagementByTokenByTeam(data) {
    return this.restfulWebService.post(
      `AnalyticsManager/EngagementByTokenByTeam`,
      data
    );
  }
  postEngagementByPat(data) {
    return this.restfulWebService.post(
      `AnalyticsManager/EngagementByPat`,
      data
    );
  }
  postEngagementByPatByTeam(data) {
    return this.restfulWebService.post(
      `AnalyticsManager/EngagementByPatByTeam`,
      data
    );
  }
  postEngagementBySelf(data) {
    return this.restfulWebService.post(
      `AnalyticsManager/EngagementBySelf`,
      data
    );
  }
  postEngagementByBoss(data) {
    return this.restfulWebService.post(
      `AnalyticsManager/EngagementByBoss`,
      data
    );
  }

  postTeamEngagementByEmployees(data) {
    return this.restfulWebService.post(
      `AnalyticsTeamManager/EngagementByEmployees`,
      data
    );
  }
  postTeamEngagementByTeams(data) {
    return this.restfulWebService.post(
      `AnalyticsTeamManager/EngagementByTeams`,
      data
    );
  }
  postTeamEngagementByFeedback(data) {
    return this.restfulWebService.post(
      `AnalyticsTeamManager/EngagementByFeedback`,
      data
    );
  }
  postTeamEngagementByFeedbackByTeam(data) {
    return this.restfulWebService.post(
      `AnalyticsTeamManager/EngagementByFeedbackByTeam`,
      data
    );
  }
  postTeamEngagementByToken(data) {
    return this.restfulWebService.post(
      `AnalyticsTeamManager/EngagementByToken`,
      data
    );
  }
  postTeamEngagementByTokenByTeam(data) {
    return this.restfulWebService.post(
      `AnalyticsTeamManager/EngagementByTokenByTeam`,
      data
    );
  }
  postTeamEngagementByPat(data) {
    return this.restfulWebService.post(
      `AnalyticsTeamManager/EngagementByPat`,
      data
    );
  }
  postTeamEngagementByPatByTeam(data) {
    return this.restfulWebService.post(
      `AnalyticsTeamManager/EngagementByPatByTeam`,
      data
    );
  }
  postTeamEngagementBySelf(data) {
    return this.restfulWebService.post(
      `AnalyticsTeamManager/EngagementBySelf`,
      data
    );
  }
  postTeamEngagementByBoss(data) {
    return this.restfulWebService.post(
      `AnalyticsTeamManager/EngagementByBoss`,
      data
    );
  }

  /* Zoom */
  postZoom(data) {
    return this.restfulWebService.post(`AnalyticsManager/Zoom`, data);
  }
  postTeamZoom(data) {
    return this.restfulWebService.post(`AnalyticsTeamManager/Zoom`, data);
  }
  postEmployeeZoom(data) {
    return this.restfulWebService.post(`AnalyticsEmployeeManager/Zoom`, data);
  }

  /* MyObjectives */
  getMyCycles() {
    return this.restfulWebService.get(`CycleObjective/MyCycles`);
  }
  getMyCycleDetails(cycleId) {
    return this.restfulWebService.get(
      `CycleObjective/IndividualCycle?id=${cycleId}`
    );
  }
  getMyCycleImages(page = 1, per_page = 100) {
    return this.restfulWebService.get(
      `Integration/Images?page=${page}&per_page=${per_page}`
    );
  }
  selectImage(image) {
    let url = null;
    if (image.links) {
      url = encodeURIComponent(image.links.download_location);
    }
    return this.restfulWebService.get(`Integration/SelectImage?url=${url}`);
  }
  getMyCycleImagesQuery(query, page = 1, per_page = 100) {
    return this.restfulWebService.get(
      `Integration/Images?query=${query}&page=${page}&per_page=${per_page}`
    );
  }
  saveCycleObjective(cycleId, data) {
    return this.restfulWebService.post(
      `CycleObjective/AddObjective?cycleId=${cycleId}`,
      data
    );
  }
  editCycleObjective(cycleId, data) {
    return this.restfulWebService.put(
      `CycleObjective/EditObjective?cycleId=${cycleId}`,
      data
    );
  }
  deleteCycleObjective(cycleId) {
    return this.restfulWebService.delete(
      `CycleObjective/DeleteObjective?objectiveId=${cycleId}`
    );
  }
  disableCycleObjective(cycleId) {
    return this.restfulWebService.delete(
      `CycleObjective/DisableObjective?objectiveId=${cycleId}`
    );
  }

  requestApproval(objectiveId) {
    return this.restfulWebService.get(
      `CycleObjective/RequireApproval?objectiveId=${objectiveId}`
    );
  }
  changeProgressStatus(objectiveId, index) {
    return this.restfulWebService.get(
      `CycleObjective/ChangeProgressStatus?objectiveId=${objectiveId}&status=${index}`
    );
  }
  assignObjective(cycleId, objective) {
    return this.restfulWebService.post(
      `Cycles/AssignObjective?cycleId=${cycleId}`,
      objective
    );
  }
  setCycleEditing(cycleId, isEditing) {
    return this.restfulWebService.put(
      `Cycles/SetEditable?cycleId=${cycleId}&isEditing=${isEditing}`,
      null
    );
  }
  // originalObjectiveId=ceecc6c2-be15-4ef8-a2db-e64fbd220ed9
  // targetCycleId=76fef197-16ca-48ba-80a3-73222985cd55&defer
  // Type=Extend
  // deferDate=2021-11-1

  deferrObjective(objective, cycleId) {
    return this.restfulWebService.get(
      `Cycles/DeferrObjective?originalObjectiveId=${
        objective.Id
      }&targetCycleId=${cycleId}&deferType=${
        objective.DeferrType
      }&deferDate=${moment(objective.DeferrDueDate).format('YYYY-MM-DD')}`
    );
  }

  getDeferredCycles(originalObjectiveId: string) {
    console.log(
      `Cycles/CyclesForDefer?originalObjectiveId=${originalObjectiveId}`
    );
    return this.restfulWebService.get(
      `Cycles/CyclesForDefer?originalObjectiveId=${originalObjectiveId}`
    );
  }

  cloneCycle(cycle) {
    return this.restfulWebService.get(`Cycles/CloneCycle?cycleId=${cycle.Id}`);
  }
  cloneObjective(objective, targetCycleId, view, viewMode, targetType) {
    return this.restfulWebService.get(
      `Cycles/CloneObjective?objectiveId=${objective.Id}&targetCycleId=${targetCycleId}&view=${view}&viewMode=${viewMode}&targetType=${targetType}`
    );
  }
  restoreObjective(objective) {
    return this.restfulWebService.get(
      `Cycles/RestoreObjective?objectiveId=${objective.Id}`
    );
  }
  suspendObjective(objective) {
    return this.restfulWebService.get(
      `Cycles/SuspendObjective?objectiveId=${objective.Id}`
    );
  }
  addCommentToObjective(objectiveId, comment) {
    return this.restfulWebService.post(
      `CycleObjective/AddComment?objectiveId=${objectiveId}`,
      comment
    );
  }
  deleteCommentInObjective(commentId) {
    return this.restfulWebService.delete(
      `CycleObjective/DeleteComment?objectiveId=${commentId}`
    );
  }
  approveObjective(obj) {
    return this.restfulWebService.get(`Cycles/Approve?objectiveId=${obj.Id}`);
  }

  AddCheckListItem(objectiveId, data) {
    return this.restfulWebService.post(
      `CycleObjective/AddCheckListItem?objectiveId=${objectiveId}`,
      data
    );
  }
  RemoveCheckListItem(objectiveId, newPosition = null, data) {
    let newPositionParam = '';
    if (newPosition) {
      newPositionParam = `&newPosition=${newPosition}`;
    }
    return this.restfulWebService.post(
      `CycleObjective/AddCheckListItem?objectiveId=${objectiveId}${newPositionParam}`,
      data
    );
  }
  SaveCheckList(objectiveId, data) {
    return this.restfulWebService.post(
      `CycleObjective/SaveCheckList?objectiveId=${objectiveId}`,
      data
    );
  }

  getUpLinks(
    objectiveId,
    cycleId,
    pageStart: number = 1,
    pageSize: number = 20,
    filter: string = undefined
  ) {
    let url = 'CycleObjective/AvailableLinkTargets';
    let params = `?cycleId=${cycleId}`;
    if (objectiveId) {
      params += `&objectiveId=${objectiveId}`;
    }
    params += `&pageStart=${pageStart}&pageSize=${pageSize}`;
    if (filter) {
      params += `&filter=${filter}`;
    }
    return this.restfulWebService.post(url + params, null);
  }

  linkTarget(objectiveId: string, targetId: string, targetIsObject: boolean) {
    return this.restfulWebService.post(
      `CycleObjective/Link?objectiveId=${objectiveId}&targetId=${targetId}&targetIsObject=${targetIsObject}`,
      null
    );
  }

  // OKR Networks

  networkCompany(data: any) {
    return this.restfulWebService.post(`Network/Company`, data);
  }

  myNetworkCompany(data: any) {
    return this.restfulWebService.post(`Network/Personal`, data);
  }

  followNetworkCompany(data: any) {
    return this.restfulWebService.post(`Network/Followed`, data);
  }
}
