import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Pp360EditorComponent } from './pp360-editor.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [Pp360EditorComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    QuillModule,
    BsDropdownModule,
    TranslateModule,
  ],
  exports: [Pp360EditorComponent],
})
export class Pp360EditorModule {}
