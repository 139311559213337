import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { calculateLTIPlanParametes } from '../../../admin-lti/functions';

@Component({
  selector: 'app-bonus-simulation',
  templateUrl: './bonus-simulation.component.html',
  styleUrls: ['./bonus-simulation.component.scss', '../common-styles.scss'],
})
export class BonusSimulationComponent implements OnInit, AfterViewInit {
  @Input() plan: any;
  @Input() userId: any;

  simulationPlan: any;
  currentYear: any;

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    this.onRestore();
    this.currentYear = this.simulationPlan.YearStart;
  }

  ngAfterViewInit() {}

  onRestore() {
    this.simulationPlan = JSON.parse(JSON.stringify(this.plan));

    this.initliazeSimulatedValues(this.simulationPlan.Gateways);
    this.initliazeSimulatedValues(this.simulationPlan.Objectives);
    this.initliazeSimulatedValues(this.simulationPlan.Correctives);
    calculateLTIPlanParametes(this.simulationPlan, true);
  }

  onChange(item) {
    this.simulate(item, this.currentYear);
  }

  simulate(item, year) {
    let yv = item.YearlyValues.find((x) => x.Year === year);
    if (yv) {
      yv.CurrentValue = item[year].SimulatedValue;
    }

    calculateLTIPlanParametes(this.simulationPlan, true);
  }

  initliazeSimulatedValues(objects) {
    objects.forEach((e: any) => {
      e.YearlyValues.forEach((yv) => {
        e[yv.Year] = {
          SimulatedValue: yv.CurrentValue | 0,
          CurrentValue: yv.CurrentValue | 0,
        };
      });
    });
  }

  getYears() {
    let years = new Array(this.plan.YearEnd - this.plan.YearStart + 1)
      .fill(0)
      .map((val, idx) => this.plan.YearStart + idx);

    return years;
  }

  GetSliderMin(item, year) {
    let n = 0.0;
    let yv = item.YearlyValues.find((x) => x.Year === year);
    if (yv) {
      n = yv.TargetValue * 0.5;
    }
    return n;
  }

  GetSliderMax(item, year) {
    let n = 0.0;
    let yv = item.YearlyValues.find((x) => x.Year === year);
    if (yv) {
      n = yv.TargetValue * 1.5;
    }
    return n;
  }

  getAmount(type: string) {
    let v = 0;
    if (this.simulationPlan) {
      let pby = this.simulationPlan.PartecipantsByYear.find(
        (x) => x.Year === this.currentYear
      );
      if (pby && pby.Partecipants.length > 0) {
        let p = pby.Partecipants[0];
        let pa = p.Values.PaybleDeferredArray.find(
          (x) => x.PayYear === this.currentYear
        );
        switch (type) {
          case 'accrued':
            v = p.Values.Accrued.value;
            break;
          case 'accrued_max':
            v = p.Values.Accrued.max;
            break;
          case 'accrued_payable':
            v = pa ? pa.Cash.value + pa.Equity.value : 0;
            break;
          case 'accrued_deferred':
            v = pa ? pa.DeferredCash.value + pa.DeferredEquity.value : 0;
            break;
          case 'payable':
            v = pa ? pa.Cash.value + pa.Equity.value : 0;
            break;
          case 'payable_cash':
            v = pa ? pa.Cash.value : 0;
            break;
          case 'payable_equity':
            v = pa ? pa.Equity.value : 0;
            break;
          case 'bonusbank':
            break;
        }
      }
    }
    return v;
  }

  formatCurrency(x: any, decimal: number = 2) {
    if ((!x && x !== 0) || String(x).includes('NaN')) return '';
    return x.toLocaleString(this.translateService.currentLang, {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    });
  }

  formatNumber(x: any, decimal: number = 2) {
    if ((!x && x !== 0) || String(x).includes('NaN')) return '';
    return x.toLocaleString(this.translateService.currentLang, {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    });
  }

  getMeasure(obj: any) {
    let m = '';
    if (obj.Measure.MeasureType == 'money') {
      if (obj.Currency) m += obj.Currency;
      if (obj.Format) m += ' ' + obj.Format.Name + '';
    } else if (
      obj.Measure.MeasureType == 'percentage' ||
      obj.Measure.MeasureType == 'percent'
    ) {
      m = '%';
    } else if (obj.Measure.MeasureType == 'numeric') {
      if (obj.Format) m += ' ' + obj.Format.Name + '';
    } else if (obj.Measure.MeasureType == 'scale') {
      if (obj.Scale) m += ' ' + obj.Scale.Name + '';
    }
    return m;
  }
}
