import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CoreService } from 'app/shared/services/core.service';
import { SharedService } from 'app/shared/services/shared.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { WallService } from '../wall.service';
import { SimpleDialogComponent } from 'app/shared/simple-dialog/simple-dialog.component';

@Component({
  selector: 'repat-dialog',
  templateUrl: './repat-dialog.component.html',
  styleUrls: ['./repat-dialog.component.scss'],
})
export class RepatDialogComponent implements OnInit {
  public onClose: Subject<any>;
  userInfo: any;
  pat: any;
  form: FormGroup;
  isLoading = false;
  curserPosition: number = 0;
  @Output() update = new EventEmitter();
  @Output() add = new EventEmitter();
  i18n: any = {
    search: this.coreService.getTranslation('Emoji.Search'),
    categories: {
      recent: this.coreService.getTranslation('Emoji.Frequently_Used_RE'),
      people: this.coreService.getTranslation('Emoji.Smileys_People'),
      nature: this.coreService.getTranslation('Emoji.Animals_Nature'),
      foods: this.coreService.getTranslation('Emoji.Food_Drink'),
      activity: this.coreService.getTranslation('Emoji.Activity'),
      places: this.coreService.getTranslation('Emoji.Travel_Places'),
      objects: this.coreService.getTranslation('Emoji.Objects'),
      symbols: this.coreService.getTranslation('Emoji.Symbols'),
      flags: this.coreService.getTranslation('Emoji.Flags'),
    },
  };

  constructor(
    public bsModalRef: BsModalRef,
    public coreService: CoreService,
    private modalService: BsModalService,
    public sharedService: SharedService,
    private service: WallService,
    private formBuilder: FormBuilder
  ) {
    this.userInfo = this.coreService.getUserInfo();
  }

  ngOnInit(): void {
    this.onClose = new Subject();
    this.form = this.formBuilder.group({
      comment: [null, Validators.required],
    });
    if (this.pat.InternalPat) {
      this.form.patchValue({ comment: this.pat?.Description || null });
    }
  }

  onConfirm() {
    if (!this.form.valid || !this.form.value.comment.trim()) {
      alert('please validate form');
      return;
    }
    this.rePat();
  }
  getCaretPos(evt) {
    if (evt.selectionStart || evt.selectionStart == '0') {
      this.curserPosition = evt.selectionStart;
    }
  }
  addEmoji(evt) {
    let comment;
    if (this.form.value.comment) {
      comment = [
        this.form.value.comment.slice(0, this.curserPosition),
        evt.emoji.native,
        this.form.value.comment.slice(this.curserPosition),
      ].join('');
    } else {
      comment = evt.emoji.native;
    }
    this.form.patchValue({ comment });
  }
  rePat() {
    if (this.pat.InternalPat) {
      this.isLoading = true;
      this.pat['isCheckRePat'] = true;
      var req = {
        Id: this.pat.Id,
        Description: this.form.value.comment,
      };
      this.sharedService.updatePatFeed(req).subscribe(
        (data: any) => {
          this.isLoading = false;
          this.update.emit(data);
          this.coreService.setShowPublished({ type: 'Pat', isEdit: true });
          this.onClose.next(data);
          this.bsModalRef.hide();
        },
        (err: any) => {
          this.isLoading = false;
          this.coreService.toasterMessage('error', 'wall', err.Message);
        }
      );
    } else {
      this.isLoading = true;
      this.pat['isCheckRePat'] = true;
      var reqBody = {
        TapFeedId: this.pat.Id,
        Text: this.form.value.comment,
        AuthorId: this.userInfo.linkedEmployeeId,
      };

      this.service.postARepat(reqBody).subscribe(
        (data: any) => {
          this.isLoading = false;
          this.add.emit(data);
          this.onClose.next(data);
          this.pat.RePats.push(data);
          this.coreService.setShowPublished({ type: 'Pat', isEdit: false });
          this.bsModalRef.hide();
        },
        (err: any) => {
          this.isLoading = false;
          this.coreService.toasterMessage('error', 'wall', err.Message);
        }
      );
    }
  }
  cancelChanges() {
    if (!this.form?.dirty) {
      this.bsModalRef.hide();
    } else {
      const content: any = {
        YesCaption: this.coreService.getTranslation('DISCARD'),
        NoCaption: this.coreService.getTranslation('CANCEL'),
        type: 'YesNo',
        title: this.coreService.getTranslation('WALL.DISCARD_CHANGES'),
        subtitle: this.coreService.getTranslation('WALL.DISCARD_CHANGES_TITLE'),
      };

      const modal = this.modalService.show(SimpleDialogComponent, {
        class: 'self-evaluation',
      });
      (<SimpleDialogComponent>modal.content).showModal(content);
      (<SimpleDialogComponent>modal.content).onClose.subscribe(
        (result: boolean) => {
          if (result) {
            this.bsModalRef.hide();
          }
        }
      );
    }
  }
}
