import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CoreService } from 'app/shared/services/core.service';
import * as Vibrant from 'node-vibrant/dist/vibrant.js';
import { CyclesUtilitiesService } from 'app/shared/services/cycles-utilities.service';

export enum OkrType {
  VIEW_ONLY = 'VIEW_ONLY',
  EDIT = 'EDIT',
  DRAG = 'DRAG',
  DISABLE = 'DISABLE',
}

export enum OkrSize {
  SMALL = 'SMALL',
  REGULAR = 'REGULAR',
  BIG = 'BIG',
}

@Component({
  selector: 'app-okr-progress-widget',
  templateUrl: './okr-progress-widget.component.html',
  styleUrls: [
    './okr-progress-widget.component.scss',
    '../../../../../common_styles.scss',
  ],
})
export class OkrProgressWidgetComponent implements OnInit {
  @Input() Value: number | boolean;
  @Input() Pace: number = undefined;
  @Input() Min: number = 0;
  @Input() Max: number = 100;
  @Input() Step: number = 1;
  @Input() name: string = '';
  @Input() img: string = '';
  @Input() okrType: OkrType;
  @Input() okrSize: OkrSize = OkrSize.REGULAR;
  @Input() noBorder: boolean = true;
  @Input() measureName: string = '%';
  @Input() measureType: number = 0;
  @Input() hideMainProgress: boolean = false;
  @Input() hideImage: boolean = false;

  @Output() ValueChange = new EventEmitter<number | boolean>();

  border: any;

  constructor(
    public coreService: CoreService,
    public cyclesUtilitiesService: CyclesUtilitiesService
  ) {}

  ngOnInit(): void {
    this.getBorderAsync();
  }

  getUrlImg(img: any) {
    return this.coreService.getImageURL(img, 'assets/images/category.gif');
  }

  progresChanged() {
    if (this.Value === false) {
      this.Value = 0;
    } else if (this.Value === true) {
      this.Value = 100;
    }
    this.ValueChange.emit(this.Value);
  }

  async getBorderAsync() {
    if (this.img === 'NOIMAGE') {
      this.border = {
        border: '',
      };
    } else {
      const urlImg = this.getUrlImg(this.img);
      let palette = await Vibrant.from(urlImg).getPalette();
      let hex =
        palette && palette.LightVibrant
          ? palette.LightVibrant.getHex()
          : '000000';
      this.border = {
        border: `2px solid ${hex}`,
      };
    }
  }

  isInputEditing: boolean = false;
  editKRObjectiveValue() {
    if (this.okrType !== 'DRAG') return;

    this.isInputEditing = true;
    setTimeout(() => {
      $(`.input_kr_objective`).focus();
      $(`.input_kr_objective`).select();
    }, 250);
  }
}
