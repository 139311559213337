import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pp360-scroll-container',
  templateUrl: './pp360-scroll-container.component.html',
  styleUrls: ['./pp360-scroll-container.component.scss'],
})
export class Pp360ScrollContainerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
